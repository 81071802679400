import { Chip } from '@elipssolution/harfang';
import { Stack, styled } from '@mui/material';

const StyledContainer = styled(Stack)({
	position: 'relative',
});

const StyledParentChip = styled(Chip)({
	width: 80,
});

const StyledChildChip = styled(Chip)(({ theme: { spacing } }) => ({
	borderTopLeftRadius: '0px !important',
	borderBottomLeftRadius: '0px !important',

	'& > span': {
		paddingLeft: spacing(1),
	},
}));

const StyledTriangle = styled('div')({
	position: 'absolute',
	left: '-16px',
	width: 0,
	height: 0,
	borderTop: '16px solid transparent',
	borderBottom: '16px solid transparent',
	borderRight: '16px solid #efe7f5',
});

type InheritChipProps = {
	/** The label of parent. */
	parentLabel: string;
	/** The child label. */
	childLabel: string;
	/** The value is inherited. */
	inherited: boolean;
	/** The value is an override. */
	override: boolean;
};

const InheritChip = ({ parentLabel, childLabel, inherited, override }: InheritChipProps) => {
	if (override) {
		return (
			<Stack direction="row">
				<StyledParentChip label={parentLabel} color="#b8d0d0" />
				<StyledContainer>
					<StyledTriangle />
					<StyledChildChip label={childLabel} color="#720e9e" />
				</StyledContainer>
			</Stack>
		);
	}

	if (inherited) {
		return <Chip label={parentLabel} color="#5cadab" style={{ width: 85 }} />;
	}

	return <Chip label={childLabel} color="#720e9e" style={{ width: 85 }} />;
};

export default InheritChip;
