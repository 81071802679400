import { SettingsGroup, SettingsItemCheckbox, SettingsItemTextField } from '@elipssolution/harfang';
import Image from 'next/image';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { WidgetExternalApplicationType } from '../../../../types/widget';
import ApplicationCard from '../ApplicationCard';
import { WidgetExternalActionButtons } from '../WidgetExternalActionButtons';

type FormType = {
	name: string;
	isExternal: boolean;
	isInternal: boolean;
	isAvailableOnDesktopFormat?: boolean;
	isAvailableOnMobileFormat?: boolean;
};

type MySilaeFormProps = {
	application: WidgetExternalApplicationType;
	isNew: boolean;
	onSubmit: (application: WidgetExternalApplicationType) => void;
	onRemove: (application: WidgetExternalApplicationType) => void;
};

export const MySilaeForm = ({ application, isNew, onSubmit, onRemove }: MySilaeFormProps) => {
	const {
		key,
		type,
		name: initialName,
		isInternal: isInternalInitial,
		isExternal: isExternalInitial,
		isAvailableOnDesktopFormat: isAvailableOnDesktopFormatInitial,
		isAvailableOnMobileFormat: isAvailableOnMobileFormatInitial,
	} = application;

	const {
		control,
		formState: { isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>();

	const handleRemove = () => onRemove(application);

	const handleSubmitInternal = ({
		name,
		isInternal,
		isExternal,
		isAvailableOnDesktopFormat,
		isAvailableOnMobileFormat,
	}: FormType) =>
		onSubmit({
			key,
			type,
			name,
			isInternal,
			isExternal,
			isAvailableOnDesktopFormat,
			isAvailableOnMobileFormat,
		});

	useEffect(
		() =>
			reset({
				name: initialName,
				isInternal: isInternalInitial,
				isExternal: isExternalInitial,
				isAvailableOnDesktopFormat: isAvailableOnDesktopFormatInitial,
				isAvailableOnMobileFormat: isAvailableOnMobileFormatInitial,
			}),
		[
			initialName,
			isAvailableOnDesktopFormatInitial,
			isAvailableOnMobileFormatInitial,
			isExternalInitial,
			isInternalInitial,
			reset,
		],
	);

	return (
		<>
			<SettingsGroup label="Configuration">
				<Controller
					name="name"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField {...field} description="Nom de l'application." label="Nom" required />
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="isInternal"
					control={control}
					defaultValue
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs cabinet auront accès à cette application."
							label="Visible cabinet"
						/>
					)}
				/>
				<Controller
					name="isExternal"
					control={control}
					defaultValue
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs entreprise auront accès à cette application."
							label="Visible entreprise"
						/>
					)}
				/>

				<Controller
					defaultValue={false}
					name="isAvailableOnDesktopFormat"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, l'application externe sera visible sur ordinateur."
							label="Visible version ordinateur"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
				<Controller
					defaultValue={false}
					name="isAvailableOnMobileFormat"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, l'application externe sera visible sur mobile."
							label="Visible version mobile"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<WidgetExternalActionButtons
				isValid={isValid}
				isNew={isNew}
				onRemove={handleRemove}
				onSubmit={handleSubmit(handleSubmitInternal)}
			/>
		</>
	);
};

type MySilaeApplicationProps = {
	name: string;
	readOnly: boolean;
};

const MySilaeApplication = ({ name, readOnly }: MySilaeApplicationProps) => {
	const handleClick = () => window.open(' https://my.silae.fr/sign-in ', '_blank');

	return (
		<ApplicationCard
			name={name}
			image={
				<Image src="/widget/images/mySilae-logo.png" alt="Logo MySilae" width={96} height={96} objectFit="contain" />
			}
			onClick={handleClick}
			readOnly={readOnly}
		/>
	);
};

export default MySilaeApplication;
