import { Button, Icon, useTable } from '@elipssolution/harfang';
import { mdiPlus } from '@mdi/js';
import { styled } from '@mui/material';
import { useCallback, useState } from 'react';

import ContactImport from './ContactImport';
import PermissionWall from '../../../../components/PermissionWall';
import { useSession } from '../../../../src/components/SessionProvider';
import { PermissionEnum } from '../../../../types/permission';
import ContactDialog from '../../components/ContactDialog';
import ContactsTable from '../../components/ContactsTable';
import { SignContactType } from '../../types/contact';

const ButtonWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	gap: spacing(3),
	marginBottom: spacing(5),
}));

const Contacts = () => {
	const { checkPermission } = useSession();

	const tableInstance = useTable();

	const [isContactCreateModifyDialogOpen, setIsContactCreateModifyDialogOpen] = useState(false);
	const [contactToModify, setContactToModify] = useState<SignContactType>();

	const handleContactDialogClose = useCallback(
		(params?: { refreshTable?: boolean }) => {
			if (params?.refreshTable) {
				tableInstance.reload();
			}
			setIsContactCreateModifyDialogOpen(false);
			// Avoids showing a content change while the dialog animation takes place
			setTimeout(() => setContactToModify(undefined), 100);
		},
		[tableInstance],
	);

	return (
		<>
			<PermissionWall permissionCodes={[PermissionEnum.SIGN_CONTACT_WRITE]}>
				<ButtonWrapper>
					<Button
						variant="contained"
						startIcon={<Icon path={mdiPlus} />}
						onClick={() => setIsContactCreateModifyDialogOpen(true)}
					>
						Créer un contact
					</Button>

					<ContactImport reloadContacts={() => tableInstance.reload()} />
				</ButtonWrapper>
			</PermissionWall>
			<ContactsTable
				onContactSelection={
					checkPermission(PermissionEnum.SIGN_CONTACT_WRITE)
						? (contact) => {
								setContactToModify(contact);
								setIsContactCreateModifyDialogOpen(true);
						  }
						: undefined
				}
				tableInstance={tableInstance}
			/>

			<ContactDialog
				isOpen={isContactCreateModifyDialogOpen}
				onClose={handleContactDialogClose}
				existingContact={contactToModify}
			/>
		</>
	);
};

export default Contacts;
