import {
	Button,
	Icon,
	SettingsGroup,
	SettingsItemAutocomplete,
	SettingsItemCheckbox,
	SettingsItemTextField,
} from '@elipssolution/harfang';
import { mdiPencil } from '@mdi/js';
import { Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import AccountRangesForm, { AccountRangeType } from './AccountRangesForm';
import SettingsItemFormulaField from './SettingsItemFormulaField';
import SettingsDialogPage from '../../../../../components/SettingsDialogPage';
import { ChartType, ChartTypeEnum, PeriodicityEnum, PeriodicityType } from '../../../../../src/types/revenue';
import { WidgetConfigurationComponentProps } from '../../../../../types/widget';

export type WidgetRevenueConfigurationType = {
	periodicity?: PeriodicityEnum;
	isBalanceCumulative?: boolean;
	accountRanges?: AccountRangeType[];
	chartType?: ChartTypeEnum;
	formula?: string;
};

export type FormType = {
	name: string;
	isBalanceCumulative: boolean;
	periodicity: PeriodicityType;
	formula: string;
	accountRanges: AccountRangeType[];
	chartType: ChartType;
	isAvailableOnDesktopFormat: boolean;
	isAvailableOnMobileFormat: boolean;
};

enum DataRenderTypeEnum {
	ACCOUNT_RANGES = 'accountRanges',
	FORMULA = 'formula',
}

type DataRenderType = {
	id: DataRenderTypeEnum;
	label: string;
};

const dataRenderTypes = [
	{ id: DataRenderTypeEnum.ACCOUNT_RANGES, label: 'Comptes commençant par' },
	{ id: DataRenderTypeEnum.FORMULA, label: 'Formule' },
];

const periodicities: PeriodicityType[] = [
	{ id: PeriodicityEnum.ANNUALLY, label: 'Annuelle' },
	{ id: PeriodicityEnum.MONTHLY, label: 'Mensuelle' },
];

const chartTypes: ChartType[] = [
	{ id: ChartTypeEnum.BAR_CHART, label: 'Graphique à barres' },
	{ id: ChartTypeEnum.LINE_CHART, label: 'Graphique linéaire' },
];

const WidgetRevenueConfiguration = ({
	name: initialName = 'Graphique',
	isAvailableOnDesktopFormat: initialIsAvailableOnDesktopFormat = true,
	isAvailableOnMobileFormat: initialIsAvailableOnMobileFormat = true,
	configuration,
	onBack,
	onSave,
}: WidgetConfigurationComponentProps) => {
	const {
		control,
		formState: { isValid },
		handleSubmit,
	} = useForm<FormType>({
		mode: 'onChange',
	});

	const {
		accountRanges,
		isBalanceCumulative = false,
		periodicity: periodicityKey,
		chartType: chartTypeKey,
		formula,
	} = (configuration as WidgetRevenueConfigurationType | undefined) || {};

	const [dataRenderType, setDataRenderType] = useState(
		accountRanges || (!accountRanges && !formula) ? dataRenderTypes[0] : dataRenderTypes[1],
	);

	const handleDataTypeChange = (newDataRenderType?: DataRenderType) =>
		newDataRenderType && setDataRenderType(newDataRenderType);

	const periodicity = periodicities.find(({ id }) => id === periodicityKey);
	const graphicChartType = chartTypes.find(({ id }) => id === chartTypeKey);

	const periodsDataSource = useCallback(
		async (): Promise<{
			items: PeriodicityType[];
			count: number;
		}> =>
			Promise.resolve({
				items: periodicities,
				count: periodicities.length,
			}),
		[],
	);

	const chartTypesDataSource = useCallback(
		async (): Promise<{
			items: ChartType[];
			count: number;
		}> =>
			Promise.resolve({
				items: chartTypes,
				count: chartTypes.length,
			}),
		[],
	);

	const dataTypesDataSource = useCallback(
		async (): Promise<{
			items: DataRenderType[];
			count: number;
		}> =>
			Promise.resolve({
				items: dataRenderTypes,
				count: dataRenderTypes.length,
			}),
		[],
	);

	const onSubmit = useCallback(
		({
			accountRanges: formAccountRanges,
			isBalanceCumulative: formIsBalanceCumulative,
			name,
			periodicity: { id: formPeriodicityId },
			chartType: { id: chartTypeId },
			formula: formFormula,
			isAvailableOnDesktopFormat,
			isAvailableOnMobileFormat,
		}: FormType) =>
			onSave(name, isAvailableOnDesktopFormat, isAvailableOnMobileFormat, {
				isBalanceCumulative: formIsBalanceCumulative,
				periodicity: formPeriodicityId,
				accountRanges: formAccountRanges,
				chartType: chartTypeId,
				formula: formFormula,
				isAvailableOnDesktopFormat,
				isAvailableOnMobileFormat,
			}),
		[onSave],
	);

	return (
		<SettingsDialogPage onBack={onBack} title={initialName}>
			<Controller
				control={control}
				defaultValue={initialName}
				render={({ field }) => <SettingsItemTextField {...field} label="Nom du widget" required />}
				rules={{ required: true }}
				name="name"
			/>

			<SettingsGroup>
				<Controller
					control={control}
					defaultValue={periodicity}
					render={({ field }) => (
						<SettingsItemAutocomplete<PeriodicityType>
							{...field}
							dataSource={periodsDataSource}
							description="Configuration de la plage de comptes pour le CA."
							getOptionLabel={({ label }) => label}
							label="Périodicité"
							disableClearable
							required
						/>
					)}
					rules={{ required: true }}
					name="periodicity"
				/>

				<Controller
					control={control}
					defaultValue={graphicChartType}
					render={({ field }) => (
						<SettingsItemAutocomplete<ChartType>
							{...field}
							dataSource={chartTypesDataSource}
							description="Configuration du type de graphique."
							getOptionLabel={({ label }) => label}
							label="Type de graphique"
							disableClearable
							required
						/>
					)}
					rules={{ required: true }}
					name="chartType"
				/>

				<Controller
					defaultValue={isBalanceCumulative}
					name="isBalanceCumulative"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le solde est cumulatif."
							label="Solde cumulatif"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<SettingsGroup>
				<SettingsItemAutocomplete<DataRenderType>
					dataSource={dataTypesDataSource}
					description="Définit le choix du calcul des données."
					getOptionLabel={({ label }) => label}
					label="Type des données"
					value={dataRenderType}
					onChange={handleDataTypeChange}
					disableClearable
				/>

				{dataRenderType === dataRenderTypes[0] ? (
					<Controller
						control={control}
						defaultValue={accountRanges}
						render={({ field: { value, ...field } }) => <AccountRangesForm {...field} accountRanges={value} />}
						name="accountRanges"
						rules={{ required: true }}
					/>
				) : (
					<SettingsItemFormulaField control={control} initialValue={formula} />
				)}
			</SettingsGroup>

			<SettingsGroup label="Configuration">
				<Controller
					defaultValue={initialIsAvailableOnDesktopFormat}
					name="isAvailableOnDesktopFormat"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le widget sera visible sur ordinateur."
							label="Visible version ordinateur"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
				<Controller
					defaultValue={initialIsAvailableOnMobileFormat}
					name="isAvailableOnMobileFormat"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le widget sera visible sur mobile."
							label="Visible version mobile"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<Stack direction="row" justifyContent="flex-end">
				<Button
					disabled={!isValid}
					onClick={handleSubmit(onSubmit)}
					startIcon={<Icon path={mdiPencil} size="small" />}
					variant="contained"
				>
					Appliquer
				</Button>
			</Stack>
		</SettingsDialogPage>
	);
};

export default WidgetRevenueConfiguration;
