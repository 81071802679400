import {
	Button,
	Icon,
	SettingsGroup,
	SettingsItemCheckbox,
	SettingsItemTextField,
	TextField,
} from '@elipssolution/harfang';
import { mdiPencil } from '@mdi/js';
import { ListItemText, Stack, styled } from '@mui/material';
import { useState } from 'react';
import sanitizeHtml from 'sanitize-html';

import SettingsDialogPage from '../../components/SettingsDialogPage';
import { WidgetComponentProps, WidgetConfigurationComponentProps } from '../../types/widget';

type WidgetHtmlConfigurationType = {
	html?: string;
};

const BaseSettingsItem = styled('div')(({ theme: { palette, shape, spacing, shadows } }) => ({
	flex: 1,

	display: 'flex',
	alignItems: 'flex-start',
	flexDirection: 'column',

	padding: spacing(0.5, 1.5, 1.5, 1.5),

	boxShadow: shadows[1],

	borderRadius: shape.borderRadius * 2,
	border: `1px solid ${palette.divider}`,

	'& > div:first-of-type': {
		flex: 0,
	},

	'& > div:last-of-type': {
		flex: 1,

		'& > div': {
			flex: 1,

			'& > textarea': {
				height: '100% !important',
				overflow: 'scroll !important',
			},
		},
	},
}));

const ContentWrapper = styled('div')({
	height: '100%',

	border: 0,
});

export const WidgetHtmlConfiguration = ({
	name: initialName,
	isAvailableOnDesktopFormat: initialIsAvailableOnDesktopFormat,
	isAvailableOnMobileFormat: initialIsAvailableOnMobileFormat,
	configuration: initialConfiguration,
	onBack,
	onSave,
}: WidgetConfigurationComponentProps) => {
	const [name, setName] = useState(initialName ?? 'HTML');
	const [isAvailableOnDesktopFormat, setIsAvailableOnDesktopFormat] = useState(initialIsAvailableOnDesktopFormat);
	const [isAvailableOnMobileFormat, setIsAvailableOnMobileFormat] = useState(initialIsAvailableOnMobileFormat);
	const [configuration, setConfiguration] = useState(initialConfiguration as WidgetHtmlConfigurationType | undefined);

	return (
		<SettingsDialogPage title={name} onBack={onBack}>
			<SettingsItemTextField
				label="Nom du widget"
				value={name}
				onChange={(changedName) => setName(changedName ?? '')}
			/>

			<SettingsGroup label="Configuration">
				<SettingsItemCheckbox
					checked={isAvailableOnDesktopFormat}
					description="Si coché, le widget sera visible sur ordinateur."
					label="Visible version ordinateur"
					onChange={(_, checked: boolean) => setIsAvailableOnDesktopFormat(checked)}
				/>
				<SettingsItemCheckbox
					checked={isAvailableOnMobileFormat}
					description="Si coché, le widget sera visible sur mobile."
					label="Visible version mobile"
					onChange={(_, checked: boolean) => setIsAvailableOnMobileFormat(checked)}
				/>
			</SettingsGroup>

			<BaseSettingsItem>
				<ListItemText primary="Contenu" />
				<TextField onChange={(value) => setConfiguration({ html: value })} value={configuration?.html} multiline />
			</BaseSettingsItem>

			<Stack direction="row" justifyContent="flex-end">
				<Button
					startIcon={<Icon path={mdiPencil} size="small" />}
					variant="contained"
					onClick={() => onSave(name, isAvailableOnDesktopFormat, isAvailableOnMobileFormat, configuration)}
				>
					Appliquer
				</Button>
			</Stack>
		</SettingsDialogPage>
	);
};

const sanitizeOptions = {
	allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'iframe']),
	allowedAttributes: {
		a: ['href', 'name', 'target'],
		img: ['alt', 'height', 'loading', 'src', 'srcset', 'title', 'width'],
		iframe: ['allow', 'allowfullscreen', 'frameborder', 'height', 'src', 'title', 'width'],
		'*': ['style'],
	},
};

const WidgetHtml = ({ configuration, readOnly }: WidgetComponentProps) => {
	const { html = '<p>Hello World!</p>' } = (configuration as WidgetHtmlConfigurationType | undefined) ?? {};

	return (
		<Stack
			flex={1}
			sx={{
				...(readOnly && {
					pointerEvents: 'none',
				}),
			}}
		>
			<ContentWrapper
				dangerouslySetInnerHTML={{
					__html: sanitizeHtml(html, sanitizeOptions),
				}}
			/>
		</Stack>
	);
};

export default WidgetHtml;
