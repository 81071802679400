import CustomerFilesTableSection from './CustomerFilesTableSection';
import ExternalGroupsTableSection from './ExternalGroupsTableSection';
import ExternalUsersTableSection from './ExternalUsersTableSection';
import PermissionWall from '../../../components/PermissionWall';
import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { PermissionEnum } from '../../../types/permission';
import { useSession } from '../../components/SessionProvider';
import { UserTypeEnum } from '../../types/user';

const ExternalAccess = () => {
	const {
		user: { type },
	} = useSession();

	return (
		<SettingsDialogPage title="Accès entreprise">
			{type === UserTypeEnum.INTERNAL && (
				<PermissionWall permissionCodes={[PermissionEnum.EXTERNAL_GROUP_MANAGE]}>
					<ExternalGroupsTableSection />
				</PermissionWall>
			)}

			<PermissionWall
				permissionCodes={type === UserTypeEnum.INTERNAL ? [PermissionEnum.CUSTOMER_FILE_EXTERNAL_USER_MANAGE] : []}
			>
				<>
					<CustomerFilesTableSection />
					<ExternalUsersTableSection />
				</>
			</PermissionWall>
		</SettingsDialogPage>
	);
};

export default ExternalAccess;
