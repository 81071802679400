import { gql } from '@apollo/client';

import { WIDGET_FIELDS } from './widgets';
import { CustomerFileDashboardType, DomainDashboardType } from '../types/dashboard';

const DASHBOARD_WIDGET_FIELDS = `
	${WIDGET_FIELDS}
	moduleCode
`;

// QUERIES

export type FetchCustomerFileDashboardType = {
	customerFileDashboardByCustomerFile: CustomerFileDashboardType;
};

export const FETCH_CUSTOMER_FILE_DASHBOARD = gql`
	query CustomerFileDashboardByCustomerFile($customerFileId: ID!) {
		customerFileDashboardByCustomerFile(customerFileId: $customerFileId) {
			id
			widgets {
				${DASHBOARD_WIDGET_FIELDS}
			}
		}
	}
`;

export type FetchDomainDashboardType = {
	domainDashboardByDomain: DomainDashboardType;
};

export const FETCH_DOMAIN_DASHBOARD = gql`
	query DomainDashboardByDomain($domainId: ID!) {
		domainDashboardByDomain(domainId: $domainId) {
			id
			widgets {
				${DASHBOARD_WIDGET_FIELDS}
				moduleId
			}
		}
	}
`;

// MUTATIONS

export type CreateCustomerFileDashboardType = {
	createCustomerFileDashboard: CustomerFileDashboardType;
};

export const CREATE_CUSTOMER_FILE_DASHBOARD = gql`
	mutation CreateCustomerFileDashboard($createCustomerFileDashboardInput: CreateCustomerFileDashboardInput!) {
		createCustomerFileDashboard(createCustomerFileDashboardInput: $createCustomerFileDashboardInput) {
			id
			widgets {
				${DASHBOARD_WIDGET_FIELDS}
			}
		}
	}
`;

export type UpdateCustomerFileDashboardType = {
	updateCustomerFileDashboard: CustomerFileDashboardType;
};

export const UPDATE_CUSTOMER_FILE_DASHBOARD = gql`
	mutation UpdateCustomerFileDashboard($updateCustomerFileDashboardInput: UpdateCustomerFileDashboardInput!) {
		updateCustomerFileDashboard(updateCustomerFileDashboardInput: $updateCustomerFileDashboardInput) {
			id
			widgets {
				${DASHBOARD_WIDGET_FIELDS}
			}
		}
	}
`;

export type RemoveCustomerFileDashboardType = {
	removeCustomerFileDashboard: Pick<CustomerFileDashboardType, 'id'>;
};

export const REMOVE_CUSTOMER_FILE_DASHBOARD = gql`
	mutation RemoveCustomerFileDashboard($removeCustomerFileDashboardId: ID!) {
		removeCustomerFileDashboard(id: $removeCustomerFileDashboardId) {
			id
		}
	}
`;

export type CreateDomainDashboardType = {
	createDomainDashboard: DomainDashboardType;
};

export const CREATE_DOMAIN_DASHBOARD = gql`
	mutation CreateDomainDashboard($createDomainDashboardInput: CreateDomainDashboardInput!) {
		createDomainDashboard(createDomainDashboardInput: $createDomainDashboardInput) {
			id
			widgets {
				${DASHBOARD_WIDGET_FIELDS}
			}
		}
	}
`;

export type UpdateDomainDashboardType = {
	updateDomainDashboard: DomainDashboardType;
};

export const UPDATE_DOMAIN_DASHBOARD = gql`
	mutation UpdateDomainDashboard($updateDomainDashboardInput: UpdateDomainDashboardInput!) {
		updateDomainDashboard(updateDomainDashboardInput: $updateDomainDashboardInput) {
			id
			widgets {
				${DASHBOARD_WIDGET_FIELDS}
			}
		}
	}
`;
