import { ApolloError, useMutation } from '@apollo/client';
import {
	Button,
	ConfirmationDialog,
	DialogProps,
	Icon,
	SettingsGroup,
	SettingsItemCheckbox,
	SettingsItemTextField,
} from '@elipssolution/harfang';
import { mdiPencil, mdiPlus } from '@mdi/js';
import { styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { DomainType } from '../../../../../../types/domain';
import { generateErrorInformations } from '../../../../../../utils/errorHandler';
import {
	CREATE_SETTING_DOMAIN_CHECK_ACCOUNT_TYPE,
	CreateSettingDomainCheckAccountTypeType,
	REMOVE_SETTING_DOMAIN_CHECK_ACCOUNT_TYPE,
	RemoveSettingDomainCheckAccountTypeType,
	UPDATE_SETTING_DOMAIN_CHECK_ACCOUNT_TYPE,
	UpdateSettingDomainCheckAccountTypeType,
} from '../../../../api/settingCheckAccountType';
import { CheckTypeEnum } from '../../../../types/check';
import { SettingDomainCheckAccountType } from '../../../../types/settingCheckAccountType';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type CheckAccountTypesDomainFormProps = {
	selectedCheckAccountType?: SettingDomainCheckAccountType;
	domainId?: DomainType['id'];
	checkType: CheckTypeEnum;
};

type FormFieldType = Pick<SettingDomainCheckAccountType, 'accountPrefix' | 'isEnabled' | 'name'>;

const defaultConfirmationDialogDetails = {
	dialogErrorMessage: undefined,
	isOpen: false,
};

const CheckAccountTypesDomainForm = ({
	selectedCheckAccountType: initialCheckAccountType,
	domainId,
	checkType,
}: CheckAccountTypesDomainFormProps) => {
	const { back } = useSettingsDialog();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<FormFieldType>();

	const [checkAccountType, setCheckAccountType] = useState(initialCheckAccountType);
	const [errorMessage, setErrorMessage] = useState('');
	const [{ dialogErrorMessage, isOpen }, setConfirmationDialogDetails] = useState<{
		dialogErrorMessage?: string;
		isOpen: boolean;
	}>(defaultConfirmationDialogDetails);
	const [isCreateSettingDomainCheckAccountTypeSucceeded, setIsCreateSettingDomainCheckAccountTypeSucceeded] =
		useState(false);
	const [isUpdateSettingDomainCheckAccountTypeSucceeded, setIsUpdateSettingDomainCheckAccountTypeSucceeded] =
		useState(false);

	const { id: checkAccountTypeId, name: checkAccountTypeName } = useMemo(
		() => (checkAccountType || {}) as SettingDomainCheckAccountType,
		[checkAccountType],
	);

	const closeConfirmationDialog = useCallback(() => setConfirmationDialogDetails(defaultConfirmationDialogDetails), []);

	const openConfirmationDialog = useCallback(
		() =>
			setConfirmationDialogDetails({
				isOpen: true,
			}),
		[],
	);

	const [createSettingDomainCheckAccountType, { loading: isCreateLoading }] =
		useMutation<CreateSettingDomainCheckAccountTypeType>(CREATE_SETTING_DOMAIN_CHECK_ACCOUNT_TYPE, {
			onCompleted: () => {
				setIsCreateSettingDomainCheckAccountTypeSucceeded(true);
				setTimeout(() => setIsCreateSettingDomainCheckAccountTypeSucceeded(false), 3000);
			},
		});

	const [updateSettingDomainCheckAccountType, { loading: isUpdateLoading }] =
		useMutation<UpdateSettingDomainCheckAccountTypeType>(UPDATE_SETTING_DOMAIN_CHECK_ACCOUNT_TYPE, {
			onCompleted: () => {
				setIsUpdateSettingDomainCheckAccountTypeSucceeded(true);
				setTimeout(() => setIsUpdateSettingDomainCheckAccountTypeSucceeded(false), 3000);
			},
		});

	const [removeSettingDomainCheckAccountType, { loading: isRemoveLoading }] =
		useMutation<RemoveSettingDomainCheckAccountTypeType>(REMOVE_SETTING_DOMAIN_CHECK_ACCOUNT_TYPE, {
			onCompleted: back,
			onError: (error: ApolloError) =>
				setConfirmationDialogDetails((prevValue) => ({
					...prevValue,
					dialogErrorMessage: generateErrorInformations({
						error,
						resource: 'quickentry_removeSettingDomainCheckAccountType',
					})?.message,
				})),
		});

	const checkTypeMessage = useMemo(
		() => (checkType === CheckTypeEnum.CHECK_ISSUANCE ? `pour l'émission de chèques` : 'pour la remise de chèques'),
		[checkType],
	);

	const isLoading = useMemo(() => isCreateLoading || isUpdateLoading, [isCreateLoading, isUpdateLoading]);

	const isMutationButtonDisabled = useMemo(
		() => isRemoveLoading || !isValid || !isDirty,
		[isDirty, isRemoveLoading, isValid],
	);

	const mutationButtonName = useMemo(() => {
		if (checkAccountTypeId) {
			if (isCreateSettingDomainCheckAccountTypeSucceeded) return 'Type de compte ajouté';
			if (isUpdateSettingDomainCheckAccountTypeSucceeded) return 'Type de compte modifié';
			return 'Modifier';
		}
		return 'Ajouter';
	}, [
		isCreateSettingDomainCheckAccountTypeSucceeded,
		isUpdateSettingDomainCheckAccountTypeSucceeded,
		checkAccountTypeId,
	]);

	const mutationButtonStartIcon = useMemo(
		() => <Icon path={checkAccountTypeId ? mdiPencil : mdiPlus} />,
		[checkAccountTypeId],
	);

	const onSubmit = useCallback(
		(values: FormFieldType) => {
			const { accountPrefix, isEnabled, name } = values;

			setErrorMessage('');

			const modeMutationInfoMap = new Map([
				[
					'create',
					{
						mutation: createSettingDomainCheckAccountType,
						mutationName: 'quickentry_createSettingDomainCheckAccountType',
						inputValues: {
							domainId,
							name,
							accountPrefix,
							isEnabled,
							checkType,
						},
					},
				],
				[
					'update',
					{
						mutation: updateSettingDomainCheckAccountType,
						mutationName: 'quickentry_updateSettingDomainCheckAccountType',
						inputValues: {
							id: checkAccountTypeId,
							isEnabled,
							name,
						},
					},
				],
			]);

			const {
				mutation,
				mutationName = '',
				inputValues,
			} = modeMutationInfoMap.get(checkAccountTypeId ? 'update' : 'create') ?? {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { id }: { id: string } = data[`${mutationName}` as keyof typeof data];

					setCheckAccountType({ ...values, id });
				})
				.catch((error: ApolloError) =>
					setErrorMessage(
						generateErrorInformations({
							error,
							resource: checkAccountTypeId
								? 'quickentry_updateSettingDomainCheckAccountType'
								: 'quickentry_createSettingDomainCheckAccountType',
						})?.message,
					),
				);
		},
		[createSettingDomainCheckAccountType, domainId, checkType, updateSettingDomainCheckAccountType, checkAccountTypeId],
	);

	const handleRemoval = useCallback(
		() =>
			removeSettingDomainCheckAccountType({
				variables: { quickentryRemoveSettingDomainCheckAccountTypeId: checkAccountTypeId },
			}),
		[removeSettingDomainCheckAccountType, checkAccountTypeId],
	);

	useEffect(() => reset(checkAccountType), [reset, checkAccountType]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveLoading,
				persistantErrorMessage: dialogErrorMessage,
				color: 'error',
				label: 'Supprimer',
				onClick: handleRemoval,
				variant: 'contained',
			},
		],
		[closeConfirmationDialog, handleRemoval, isRemoveLoading, dialogErrorMessage],
	);

	return (
		<SettingsDialogPage title={checkAccountTypeName ?? `Ajout d'un type de compte ${checkTypeMessage}.`}>
			<SettingsGroup>
				<Controller
					control={control}
					name="name"
					render={({ field }) => (
						<SettingsItemTextField
							{...field}
							description={`Nom du type de compte ${checkTypeMessage}.`}
							label="Nom"
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					control={control}
					name="accountPrefix"
					render={({ field }) => (
						<SettingsItemTextField
							{...field}
							description={`Préfixe du type de compte ${checkTypeMessage}.`}
							disabled={!!checkAccountTypeId}
							label="Préfixe de compte"
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					control={control}
					defaultValue
					name="isEnabled"
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description={`Si coché, le type du compte ${checkTypeMessage} est actif.`}
							label="Actif"
						/>
					)}
				/>
			</SettingsGroup>

			<ActionWrapper>
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={mutationButtonStartIcon}
					variant="contained"
				>
					{mutationButtonName}
				</Button>

				{errorMessage && <ErrorWrapper>{errorMessage}</ErrorWrapper>}

				{checkAccountTypeId && (
					<Button color="error" disabled={isLoading} onClick={openConfirmationDialog} variant="outlined">
						Supprimer
					</Button>
				)}
			</ActionWrapper>

			{checkAccountTypeId && (
				<ConfirmationDialog
					actionsDialog={actionsDialog}
					open={isOpen}
					onClose={closeConfirmationDialog}
					title={`Êtes-vous sûr de vouloir supprimer le type de compte saisie chèques ${checkAccountTypeName} ?`}
				/>
			)}
		</SettingsDialogPage>
	);
};

export default CheckAccountTypesDomainForm;
