import { useMutation } from '@apollo/client';

import {
	CREATE_STORAGE_DOWNLOAD,
	CreateStorageDownloadType,
	REMOVE_STORAGE_DOWNLOAD,
	RemoveStorageDownloadType,
	UPDATE_STORAGE_DOWNLOAD,
	UpdateStorageDownloadType,
} from '../api/settingStorageDownload';

type UseSettingStorageDownloadMutationsProps = {
	onCompleted?: (data: CreateStorageDownloadType | UpdateStorageDownloadType | RemoveStorageDownloadType) => void;
};

const useSettingStorageDownloadMutations = ({ onCompleted }: UseSettingStorageDownloadMutationsProps = {}) => {
	const [
		createStorageDownload,
		{ data: createStorageDownloadData, error: createStorageDownloadError, loading: isCreateStorageDownloadLoading },
	] = useMutation<CreateStorageDownloadType>(CREATE_STORAGE_DOWNLOAD, {
		...(onCompleted && { onCompleted }),
	});

	const [
		updateStorageDownload,
		{ data: updateStorageDownloadData, error: updateStorageDownloadError, loading: isUpdateStorageDownloadLoading },
	] = useMutation<UpdateStorageDownloadType>(UPDATE_STORAGE_DOWNLOAD, {
		...(onCompleted && { onCompleted }),
	});

	const [
		removeStorageDownload,
		{ data: removeStorageDownloadData, error: removeStorageDownloadError, loading: isRemoveStorageDownloadLoading },
	] = useMutation<RemoveStorageDownloadType>(REMOVE_STORAGE_DOWNLOAD, {
		...(onCompleted && { onCompleted }),
	});

	return {
		create: createStorageDownload,
		update: updateStorageDownload,
		remove: removeStorageDownload,
		data: createStorageDownloadData || updateStorageDownloadData || removeStorageDownloadData,
		error: createStorageDownloadError || updateStorageDownloadError || removeStorageDownloadError,
		loading: isCreateStorageDownloadLoading || isUpdateStorageDownloadLoading || isRemoveStorageDownloadLoading,
	};
};

export default useSettingStorageDownloadMutations;
