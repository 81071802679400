import { v4 as uuid } from 'uuid';

import { WidgetType } from '../../types/widget';
import { WidgetEnum } from '../../utils/Widget';
import { CustomerFileDashboardType, DomainDashboardType } from '../types/dashboard';

type ApplicationType = {
	key: string;
	type: string;
	name: string;
	isInternal: boolean;
	isExternal: boolean;
	specific?: string;
};

const duplicateWidgetExternalApplications = (
	applications: ApplicationType[],
): {
	application: ApplicationType;
	oldImage?: string;
}[] =>
	applications.map(({ key, specific, ...appRest }) => {
		const newId = uuid();

		if (!specific) {
			return { application: { key: newId, ...appRest } };
		}

		const appConfig = JSON.parse(specific) as Record<string, unknown>;
		const { image } = appConfig as { image: string };

		const newImage = image?.replace(key, newId) ?? undefined;

		return {
			application: {
				key: newId,
				specific: JSON.stringify({
					...appConfig,
					image: newImage,
				}),
				...appRest,
			},
			oldImage: image,
		};
	});

export const duplicateDashboardWidgets = ({
	widgets,
}: CustomerFileDashboardType | DomainDashboardType): {
	duplicatedWidgets: WidgetType[];
	imagesToAdd: { newUuid: string; oldImage: string; newImage: string }[];
} => {
	const imagesToAdd: { newUuid: string; oldImage: string; newImage: string }[] = [];

	const duplicatedWidgets = widgets.flatMap(
		({
			configuration,
			height,
			moduleCode,
			name,
			type,
			width,
			x,
			y,
			isAvailableOnDesktopFormat,
			isAvailableOnMobileFormat,
		}) => {
			const commonBase = {
				height,
				moduleCode,
				name,
				type,
				width,
				x,
				y,
				isAvailableOnDesktopFormat,
				isAvailableOnMobileFormat,
			};

			if (type !== WidgetEnum.EXTERNAL_APPLICATIONS) {
				return [
					{
						configuration,
						...commonBase,
					},
				];
			}

			if (!configuration) {
				return [];
			}

			const { applications } = JSON.parse(configuration) as {
				applications: ApplicationType[];
			};

			const duplicatedApplications = duplicateWidgetExternalApplications(applications);

			duplicatedApplications.forEach(({ application: { key, specific }, oldImage }) => {
				if (specific && oldImage) {
					const { image } = JSON.parse(specific) as { image: string };
					image && imagesToAdd.push({ newUuid: key, oldImage, newImage: image });
				}
			});

			return [
				{
					configuration: JSON.stringify({ applications: duplicatedApplications.map(({ application }) => application) }),
					...commonBase,
				},
			];
		},
	);

	return { duplicatedWidgets, imagesToAdd };
};
