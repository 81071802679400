import { gql } from '@apollo/client';

import { ERROR_FIELDS } from './error';
import { AccountType } from '../types/account';
import { ErrorType } from '../types/error';

// QUERIES

export type FetchCustomerFileCheckRemittanceSettingCounterpartAccountType = {
	quickentry_settingCustomerFileCheckRemittanceCounterpartAccount: {
		data: {
			accountCode: AccountType['code'];
		};
		error: ErrorType;
	};
};

export const FETCH_CUSTOMER_FILE_CHECK_REMITTANCE_SETTING_COUNTERPART_ACCOUNT = gql`
	query Quickentry_settingCustomerFileCheckRemittanceCounterpartAccount($id: ID!) {
		quickentry_settingCustomerFileCheckRemittanceCounterpartAccount(id: $id) {
			data {
				accountCode
			}
			${ERROR_FIELDS}
		}
	}
`;

export type FetchDomainCheckRemittanceSettingCounterpartAccountType = {
	quickentry_settingDomainCheckRemittanceCounterpartAccount: {
		accountCode: AccountType['code'];
	};
};

export const FETCH_DOMAIN_CHECK_REMITTANCE_SETTING_COUNTERPART_ACCOUNT = gql`
	query Quickentry_settingDomainCheckRemittanceCounterpartAccount($id: ID!) {
		quickentry_settingDomainCheckRemittanceCounterpartAccount(id: $id) {
			accountCode
		}
	}
`;

// MUTATIONS

export type CreateCustomerFileCheckRemittanceSettingCounterpartAccountType = {
	quickentry_createSettingCustomerFileCheckRemittanceCounterpartAccount: {
		data: { accountCode: AccountType['code'] };
	};
};

export const CREATE_CUSTOMER_FILE_CHECK_REMITTANCE_SETTING_COUNTERPART_ACCOUNT = gql`
	mutation Quickentry_createSettingCustomerFileCheckRemittanceCounterpartAccount(
		$createSettingCustomerFileCheckRemittanceCounterpartAccountInput: quickentry_CreateSettingCustomerFileCounterpartAccountInput!
	) {
		quickentry_createSettingCustomerFileCheckRemittanceCounterpartAccount(
			createSettingCustomerFileCheckRemittanceCounterpartAccountInput: $createSettingCustomerFileCheckRemittanceCounterpartAccountInput
		) {
			data {
				accountCode
			}
		}
	}
`;

export type CreateDomainCheckRemittanceSettingCounterpartAccountType = {
	quickentry_createSettingDomainCheckRemittanceCounterpartAccount: {
		accountCode: AccountType['code'];
	};
};

export const CREATE_DOMAIN_CHECK_REMITTANCE_SETTING_COUNTERPART_ACCOUNT = gql`
	mutation Quickentry_createSettingDomainCheckRemittanceCounterpartAccount(
		$createSettingDomainCheckRemittanceCounterpartAccountInput: quickentry_CreateSettingDomainCounterpartAccountInput!
	) {
		quickentry_createSettingDomainCheckRemittanceCounterpartAccount(
			createSettingDomainCheckRemittanceCounterpartAccountInput: $createSettingDomainCheckRemittanceCounterpartAccountInput
		) {
			accountCode
		}
	}
`;
