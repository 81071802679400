import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Table, TableColumnType, useTable } from '@elipssolution/harfang';
import { Checkbox, Stack } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import modulesConfig from '../../../../modules/modules';
import { CustomerFileType } from '../../../../types/customerFile';
import { DomainType } from '../../../../types/domain';
import { ModuleAvailabilityType, SettingCustomerFilesModulesEnabledByModule } from '../../../../types/module';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { FETCH_ALL_CUSTOMER_FILE_IDS_BY_DOMAIN, FetchAllCustomerFileIdsByDomainType } from '../../../api/customerFile';
import { FETCH_MODULES } from '../../../api/module';
import { FETCH_SETTING_MODULES, FetchSettingModulesType } from '../../../api/settingModule';
import {
	FETCH_SETTING_CUSTOMER_FILES_MODULES_ENABLED_BY_DOMAIN,
	FETCH_SETTING_MODULES_AVAILABILITY_BY_DOMAIN,
	FetchSettingCustomerFilesModulesEnabledByDomainType,
	FetchSettingModulesAvailabilityByDomainType,
	UPDATE_SETTING_CUSTOMER_FILE_MODULE_AVAILABILITY_BY_DOMAIN,
	UpdateSettingCustomerFileModuleAvailabilityByDomainType,
} from '../../../api/settingModuleAvailability';

type DomainModulesAvailabilityType = {
	id: string;
	code: string;
	name: string;
};

type SettingModuleAvailabilityByDomain = {
	customerFile: {
		id: string;
		code: string;
		name: string;
	};
	moduleAvailabilities: ModuleAvailabilityType[];
};

type UpdateSettingCustomerFileModuleAvailabilityInput = {
	moduleId: string;
	customerFileId: string;
	isEnabled: boolean;
};

type ModulesAvailabilityTableProps = {
	domainId: DomainType['id'];
	onError: (errorMessage: string) => void;
};

const ModulesAvailabilityTable = ({ domainId, onError }: ModulesAvailabilityTableProps) => {
	const tableInstance = useTable();

	const [changedModulesAvailabilities, setChangedModulesAvailabilities] = useState<
		UpdateSettingCustomerFileModuleAvailabilityInput[]
	>([]);
	const [customerFilesModulesEnabled, setCustomerFilesModulesEnabled] = useState<
		SettingCustomerFilesModulesEnabledByModule[]
	>([]);
	const [searchedCustomerFileIds, setSearchedCustomerFileIds] = useState<CustomerFileType['id'][]>([]);

	const {
		data: settingCustomerFilesModulesEnabledByDomainData,
		refetch: refetchSettingCustomerFilesModulesEnabledByDomain,
	} = useQuery<FetchSettingCustomerFilesModulesEnabledByDomainType>(
		FETCH_SETTING_CUSTOMER_FILES_MODULES_ENABLED_BY_DOMAIN,
		{
			notifyOnNetworkStatusChange: true,
			onCompleted: ({ settingCustomerFilesModulesEnabledByDomain }) =>
				setCustomerFilesModulesEnabled(settingCustomerFilesModulesEnabledByDomain),
			variables: {
				domainId,
			},
		},
	);

	const initialSettingCustomerFilesModulesEnabled = useMemo(() => {
		const { settingCustomerFilesModulesEnabledByDomain } = settingCustomerFilesModulesEnabledByDomainData || {};

		return settingCustomerFilesModulesEnabledByDomain;
	}, [settingCustomerFilesModulesEnabledByDomainData]);

	const isDirty = useMemo(
		() =>
			!initialSettingCustomerFilesModulesEnabled?.every(({ moduleId, customerFileIds }) => {
				const subCustomerFileIds = customerFilesModulesEnabled.find(
					({ moduleId: subModuleId }) => subModuleId === moduleId,
				)?.customerFileIds;

				return customerFileIds.sort().join(',') === subCustomerFileIds?.sort().join(',');
			}),
		[customerFilesModulesEnabled, initialSettingCustomerFilesModulesEnabled],
	);

	const { data: settingModulesData } = useQuery<FetchSettingModulesType>(FETCH_SETTING_MODULES);

	const modulesAvailable = useMemo(() => {
		const { settingModules: fetchedSettingModules = [] } = settingModulesData || {};

		return fetchedSettingModules.reduce((acc, { id, code }) => {
			const moduleConfig = modulesConfig.find(({ code: configCode }) => configCode === code);

			return moduleConfig
				? [
						...acc,
						{
							id,
							code,
							name: moduleConfig.name,
						},
				  ]
				: acc;
		}, [] as DomainModulesAvailabilityType[]);
	}, [settingModulesData]);

	const [fetchAllCustomerFileIdsByDomain, { data: fetchAllCustomerFileIdsByDomainData }] =
		useLazyQuery<FetchAllCustomerFileIdsByDomainType>(FETCH_ALL_CUSTOMER_FILE_IDS_BY_DOMAIN, {
			variables: {
				domainId,
			},
		});

	const customerFileIdsByDomain = useMemo(() => {
		const { customerFilesByDomainWithoutPagination } = fetchAllCustomerFileIdsByDomainData || {};

		return customerFilesByDomainWithoutPagination;
	}, [fetchAllCustomerFileIdsByDomainData]);

	const [fetchSettingModulesAvailabilityByDomain, { data: fetchSettingModulesAvailabilityByDomainData }] =
		useLazyQuery<FetchSettingModulesAvailabilityByDomainType>(FETCH_SETTING_MODULES_AVAILABILITY_BY_DOMAIN);

	const countModulesAvailability = useMemo(() => {
		const { settingCustomerFileModuleAvailabilityByDomain } = fetchSettingModulesAvailabilityByDomainData || {};
		const { count } = settingCustomerFileModuleAvailabilityByDomain || {};

		return count || 0;
	}, [fetchSettingModulesAvailabilityByDomainData]);

	const stickyLineInfo = useMemo(
		() =>
			countModulesAvailability > 0
				? {
						customerFile: {
							id: 'all',
							code: '',
							name: 'TOUS',
						},
						moduleAvailabilities: [],
				  }
				: undefined,
		[countModulesAvailability],
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: SettingModuleAvailabilityByDomain[];
		}> => {
			const { data, error } = await fetchSettingModulesAvailabilityByDomain({
				variables: {
					domainId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				settingCustomerFileModuleAvailabilityByDomain: { count = 0, items = [] },
			} = data ?? {
				settingCustomerFileModuleAvailabilityByDomain: {},
			};

			setSearchedCustomerFileIds(search ? items.map(({ customerFile: { id: customerFileId } }) => customerFileId) : []);

			return {
				count,
				items,
			};
		},
		[domainId, fetchSettingModulesAvailabilityByDomain],
	);

	const resetChangedModulesAvailability = useCallback(() => {
		setChangedModulesAvailabilities([]);
		refetchSettingCustomerFilesModulesEnabledByDomain().catch((error) => {
			throw error;
		});
	}, [refetchSettingCustomerFilesModulesEnabledByDomain]);

	const [updateSettingCustomerFileModuleAvailabilityByDomain] =
		useMutation<UpdateSettingCustomerFileModuleAvailabilityByDomainType>(
			UPDATE_SETTING_CUSTOMER_FILE_MODULE_AVAILABILITY_BY_DOMAIN,
			{
				refetchQueries: [FETCH_MODULES],
			},
		);

	const changeModulesAvailabilityStatus = useCallback(
		() =>
			updateSettingCustomerFileModuleAvailabilityByDomain({
				variables: {
					updateSettingCustomerFileModuleAvailabilityByDomainInput: changedModulesAvailabilities,
				},
			})
				.then(() => {
					tableInstance.reload();
					resetChangedModulesAvailability();
				})
				.catch((queryError: ApolloError) => {
					onError(
						generateErrorInformations({
							error: queryError,
							resource: 'updateModuleAvailabilityByCustomerFile',
						}).message,
					);

					throw queryError;
				}),
		[
			changedModulesAvailabilities,
			onError,
			resetChangedModulesAvailability,
			tableInstance,
			updateSettingCustomerFileModuleAvailabilityByDomain,
		],
	);

	const handleModuleAvailabilityChange = useCallback(
		({ customerFileId, moduleId, isEnabled }: UpdateSettingCustomerFileModuleAvailabilityInput) => {
			setChangedModulesAvailabilities((prevValue) => [
				...prevValue.filter(({ moduleId: prevModuleId, customerFileId: prevCustomerFileId }) =>
					prevModuleId === moduleId ? prevCustomerFileId !== customerFileId : true,
				),
				{ customerFileId, moduleId, isEnabled },
			]);

			setCustomerFilesModulesEnabled((prevValue) =>
				prevValue.map(({ moduleId: prevModuleId, customerFileIds }) =>
					moduleId === prevModuleId
						? {
								moduleId: prevModuleId,
								customerFileIds: isEnabled
									? [...customerFileIds, customerFileId]
									: customerFileIds.filter((id) => id !== customerFileId),
						  }
						: { moduleId: prevModuleId, customerFileIds },
				),
			);
		},
		[],
	);

	const handleChangeAllModulesAvailability = useCallback(
		({
			moduleId,
			isEnabled,
			customerFiles = [],
		}: {
			moduleId: ModuleAvailabilityType['moduleId'];
			isEnabled: ModuleAvailabilityType['isEnabled'];
			customerFiles?: {
				id: CustomerFileType['id'];
			}[];
		}) => {
			setChangedModulesAvailabilities((prevValue) => [
				...prevValue.filter(
					({ moduleId: prevModuleId, customerFileId }) =>
						prevModuleId !== moduleId ||
						(prevModuleId === moduleId && !customerFiles.some(({ id }) => id === customerFileId)),
				),
				...(customerFiles.flatMap(({ id: customerFileId }) =>
					!prevValue.some(
						({ moduleId: prevModuleId, customerFileId: prevCustomerFileId, isEnabled: prevIsEnabled }) =>
							prevModuleId === moduleId && prevCustomerFileId === customerFileId && prevIsEnabled === isEnabled,
					)
						? { customerFileId, moduleId, isEnabled }
						: [],
				) as UpdateSettingCustomerFileModuleAvailabilityInput[]),
			]);

			setCustomerFilesModulesEnabled((prevValue) =>
				prevValue.map(({ moduleId: prevModuleId, customerFileIds }) =>
					moduleId === prevModuleId
						? {
								moduleId: prevModuleId,
								customerFileIds: isEnabled
									? [
											...customerFileIds.filter(
												(id) => !customerFiles.some(({ id: customerFileId }) => customerFileId === id),
											),
											...customerFiles.map(({ id }) => id),
									  ]
									: customerFileIds.filter(
											(id) => !customerFiles.some(({ id: customerFileId }) => customerFileId === id),
									  ),
						  }
						: { moduleId: prevModuleId, customerFileIds },
				),
			);
		},
		[],
	);

	const handleAllModuleAvailabilityChange = useCallback(
		({
			moduleId,
			isEnabled,
		}: {
			moduleId: ModuleAvailabilityType['moduleId'];
			isEnabled: ModuleAvailabilityType['isEnabled'];
		}) => {
			if (!customerFileIdsByDomain) {
				fetchAllCustomerFileIdsByDomain()
					.then(({ data }) => {
						const { customerFilesByDomainWithoutPagination } = data || {};

						const concernedCustomerFiles =
							searchedCustomerFileIds.length > 0
								? customerFilesByDomainWithoutPagination?.filter(({ id }) => searchedCustomerFileIds.includes(id))
								: customerFilesByDomainWithoutPagination;

						handleChangeAllModulesAvailability({
							moduleId,
							isEnabled,
							customerFiles: concernedCustomerFiles,
						});
					})
					.catch((error) => {
						throw error;
					});
			} else {
				const concernedCustomerFiles =
					searchedCustomerFileIds.length > 0
						? customerFileIdsByDomain?.filter(({ id }) => searchedCustomerFileIds.includes(id))
						: customerFileIdsByDomain;

				handleChangeAllModulesAvailability({ moduleId, isEnabled, customerFiles: concernedCustomerFiles });
			}
		},
		[
			customerFileIdsByDomain,
			fetchAllCustomerFileIdsByDomain,
			handleChangeAllModulesAvailability,
			searchedCustomerFileIds,
		],
	);

	const getIsCheckboxEnabled = useCallback(
		({
			moduleId,
			customerFileId,
		}: {
			moduleId: ModuleAvailabilityType['moduleId'];
			customerFileId: CustomerFileType['id'];
		}) => {
			if (customerFileId === 'all') {
				const activedCustomerFilesId = customerFilesModulesEnabled.find(
					(item) => item.moduleId === moduleId,
				)?.customerFileIds;

				const concernedEnabledCustomerFileIds =
					searchedCustomerFileIds.length > 0
						? activedCustomerFilesId?.filter((id) => searchedCustomerFileIds.includes(id))
						: activedCustomerFilesId;

				return concernedEnabledCustomerFileIds?.length === countModulesAvailability;
			}

			const isChangedModuleEnabled = customerFilesModulesEnabled
				.find((item) => item.moduleId === moduleId)
				?.customerFileIds.some((id) => id === customerFileId);

			return !!isChangedModuleEnabled;
		},
		[countModulesAvailability, customerFilesModulesEnabled, searchedCustomerFileIds],
	);

	const getIsCheckboxIndeterminate = useCallback(
		({ moduleId, customerFileId }: { moduleId: string; customerFileId: CustomerFileType['id'] }) => {
			if (customerFileId !== 'all') {
				return false;
			}
			const activedCustomerFilesId = customerFilesModulesEnabled.find(
				(item) => item.moduleId === moduleId,
			)?.customerFileIds;

			const concernedEnabledCustomerFileIds =
				searchedCustomerFileIds.length > 0
					? activedCustomerFilesId?.filter((id) => searchedCustomerFileIds.includes(id))
					: activedCustomerFilesId;

			return (
				(concernedEnabledCustomerFileIds?.length || 0) > 0 &&
				concernedEnabledCustomerFileIds?.length !== countModulesAvailability
			);
		},
		[countModulesAvailability, customerFilesModulesEnabled, searchedCustomerFileIds],
	);

	const columns: TableColumnType<SettingModuleAvailabilityByDomain>[] = useMemo(
		() => [
			{
				field: 'customerFile',
				key: 'customerFile',
				title: 'Dossier client',
				render: ({ customerFile }) => (customerFile ? customerFile.name : 'TOUS'),
				width: 200,
			},
			...modulesAvailable.map(
				({ id: moduleId, code: moduleCode, name }) =>
					({
						align: 'center',
						field: moduleCode,
						key: moduleCode,
						title: name,
						render: ({ customerFile }) => (
							<Checkbox
								checked={getIsCheckboxEnabled({
									customerFileId: customerFile.id,
									moduleId,
								})}
								indeterminate={getIsCheckboxIndeterminate({ moduleId, customerFileId: customerFile.id })}
								onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
									customerFile.id === 'all'
										? handleAllModuleAvailabilityChange({ moduleId, isEnabled: checked })
										: handleModuleAvailabilityChange({
												customerFileId: customerFile.id,
												moduleId,
												isEnabled: checked,
										  })
								}
							/>
						),
						width: 120,
					} as TableColumnType<SettingModuleAvailabilityByDomain>),
			),
		],
		[
			getIsCheckboxEnabled,
			getIsCheckboxIndeterminate,
			handleAllModuleAvailabilityChange,
			handleModuleAvailabilityChange,
			modulesAvailable,
		],
	);

	return (
		<Stack flex={1}>
			<Table<SettingModuleAvailabilityByDomain>
				table={tableInstance}
				columns={columns}
				dataSource={dataSource}
				stickyRowItem={stickyLineInfo}
				title="Modules"
				enableSearch
			/>

			<Stack direction="row" justifyContent="flex-end" gap={1}>
				<Button disabled={!isDirty} onClick={resetChangedModulesAvailability}>
					Annuler
				</Button>
				<Button disabled={!isDirty} onClick={changeModulesAvailabilityStatus} variant="contained">
					Valider
				</Button>
			</Stack>
		</Stack>
	);
};

export default ModulesAvailabilityTable;
