import CustomerFileTable from './customerFile/CustomerFileTable';
import InternalGroupTable from './internalGroup/InternalGroupTable';
import InternalUserTable from './internalUser/InternalUserTable';
import PermissionWall from '../../../components/PermissionWall';
import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { PermissionEnum } from '../../../types/permission';

const InternalAccess = () => (
	<SettingsDialogPage title="Accès cabinet">
		<PermissionWall permissionCodes={[PermissionEnum.INTERNAL_GROUP_MANAGE]}>
			<InternalGroupTable />
		</PermissionWall>

		<PermissionWall permissionCodes={[PermissionEnum.CUSTOMER_FILE_INTERNAL_USER_MANAGE]}>
			<>
				<InternalUserTable />
				<CustomerFileTable />
			</>
		</PermissionWall>
	</SettingsDialogPage>
);

export default InternalAccess;
