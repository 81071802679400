import CheckAccountTypesDomainForm from './CheckAccountTypesDomainForm';
import CheckAccountTypesDomainTable from './CheckAccountTypesDomainTable';
import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../../hooks/useSettingsDialog';
import { DomainType } from '../../../../../../types/domain';
import { CheckTypeEnum } from '../../../../types/check';
import { SettingDomainCheckAccountType } from '../../../../types/settingCheckAccountType';

type CheckAccountTypesProps = {
	domainId?: DomainType['id'];
	checkType?: CheckTypeEnum;
};

const CheckAccountTypes = ({ domainId, checkType = CheckTypeEnum.CHECK_ISSUANCE }: CheckAccountTypesProps) => {
	const { push } = useSettingsDialog();

	const handleCheckAccountTypeSelection = (selectedCheckAccountType?: SettingDomainCheckAccountType) => {
		push(
			<CheckAccountTypesDomainForm
				checkType={checkType}
				domainId={domainId}
				selectedCheckAccountType={selectedCheckAccountType}
			/>,
		);
	};

	return (
		<SettingsDialogPage
			title={checkType === CheckTypeEnum.CHECK_ISSUANCE ? `Type d'émission de chèques` : 'Type de remise de chèques'}
		>
			<CheckAccountTypesDomainTable
				domainId={domainId}
				onCheckAccountTypeSelection={handleCheckAccountTypeSelection}
				checkType={checkType}
			/>
		</SettingsDialogPage>
	);
};

export default CheckAccountTypes;
