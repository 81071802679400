import { Chip, Icon, Table, TableColumnType } from '@elipssolution/harfang';
import { mdiAlert } from '@mdi/js';
import { Stack } from '@mui/material';

import StorageDownloadForm from './StorageDownloadForm';
import LinkIcon from '../../../../components/LinkIcon';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import useDocumentConnectors from '../../hooks/useDocumentConnectors';
import { ConnectorType } from '../../types/connector';

const columns: TableColumnType<Pick<ConnectorType, 'code' | 'name' | 'settingStorageDownload'>>[] = [
	{
		field: 'name',
		key: 'name',
		title: 'Nom',
		width: 200,
	},
	{
		key: 'missingConfiguration',
		render: ({ settingStorageDownload }) =>
			!settingStorageDownload?.id && (
				<Chip
					color="warning"
					label={
						<Stack direction="row" alignItems="center" gap={1}>
							<Icon path={mdiAlert} size="small" />
							Configuration manquante
						</Stack>
					}
				/>
			),
		flexGrow: 0,
		width: 230,
	},
	{
		key: 'navigate',
		width: 40,
		render: () => <LinkIcon />,
		flexGrow: 0,
	},
];

const dataSourceVariables = { variables: { hasRead: true } };

const StorageDownloadConnectorsTable = () => {
	const { push } = useSettingsDialog();

	const { dataSource: storageDownloadConnectorDataSource } = useDocumentConnectors(dataSourceVariables);

	const navigateToStorageDownloadForm = ({ code, settingStorageDownload }: ConnectorType) =>
		push(<StorageDownloadForm connectorCode={code} storageDownloadId={settingStorageDownload?.id} />);

	return (
		<Table
			columns={columns}
			dataSource={storageDownloadConnectorDataSource}
			onRowClick={navigateToStorageDownloadForm}
			title="Connecteurs"
			enableSearch
		/>
	);
};

export default StorageDownloadConnectorsTable;
