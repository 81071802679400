import { useLazyQuery } from '@apollo/client';
import { TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import SynchronizationJournalExclusionSettingForm from './SynchronizationJournalExclusionSettingForm';
import LinkIcon from '../../../../components/LinkIcon';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import {
	FETCH_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTINGS,
	FetchSynchronizationJournalSettingsType,
} from '../../../api/synchronizationJournalExclusionSetting';
import { SynchronizationJournalExclusionSetting } from '../../../types/synchronizationJournalExclusionSetting';

const columns: TableColumnType<SynchronizationJournalExclusionSetting>[] = [
	{
		field: 'code',
		key: 'code',
		title: 'Code',
		width: 200,
		sortable: true,
		flexGrow: 0,
	},
	{
		field: 'label',
		key: 'label',
		title: 'Libellé',
		width: 300,
		sortable: true,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

type SynchronizationJournalExclusionSettingsProps = {
	customerFileId: CustomerFileType['id'];
};

const SynchronizationJournalExclusionSettings = ({ customerFileId }: SynchronizationJournalExclusionSettingsProps) => {
	const { push } = useSettingsDialog();
	const [fetchSynchronizationJournalSettings] = useLazyQuery<FetchSynchronizationJournalSettingsType>(
		FETCH_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTINGS,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<SynchronizationJournalExclusionSetting>,
		) => {
			const { field, order } = orderBy || {};
			const { data, error } = await fetchSynchronizationJournalSettings({
				variables: {
					customerFileId,
					page: {
						limit,
						offset,
					},
					search,
					...(orderBy && { orderBy: { field, order } }),
				},
			});

			if (error) {
				throw error;
			}

			const {
				synchronizationJournalExclusionSettings: { items = [], count = 0 },
			} = data ?? {
				synchronizationJournalExclusionSettings: {},
			};

			return { items, count };
		},
		[fetchSynchronizationJournalSettings, customerFileId],
	);

	const handleAddSynchronizationExclusionSetting = () =>
		push(<SynchronizationJournalExclusionSettingForm customerFileId={customerFileId} />);

	const handleEditSynchronizationExclusionSetting = (
		synchronizationJournalExclusionSetting: SynchronizationJournalExclusionSetting,
	) =>
		push(
			<SynchronizationJournalExclusionSettingForm
				customerFileId={customerFileId}
				synchronizationJournalExclusionSetting={synchronizationJournalExclusionSetting}
			/>,
		);

	return (
		<SettingsDialogPage title="Synchonisation">
			<SettingsTable<SynchronizationJournalExclusionSetting>
				dataSource={dataSource}
				tableColumns={columns}
				title="Journaux exclus"
				addButtonLabel="Ajouter une exclusion"
				onAddButtonClick={handleAddSynchronizationExclusionSetting}
				onRowClick={handleEditSynchronizationExclusionSetting}
			/>
		</SettingsDialogPage>
	);
};

export default SynchronizationJournalExclusionSettings;
