import { Button, Icon } from '@elipssolution/harfang';
import { mdiPlus } from '@mdi/js';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import ProcedureDialog from './ProcedureDialog';
import SignaturesKpi from './SignaturesKpi';
import PermissionWall from '../../../components/PermissionWall';
import { PermissionEnum } from '../../../types/permission';
import { ProcedureType } from '../types/procedure';

type ProcedureTableHeaderProps = {
	isProcedureDialogOpen?: boolean;
	procedureId?: ProcedureType['id'];
	onCloseProcedureDialog?: () => void;
};

const ProceduresTableHeader = ({
	isProcedureDialogOpen,
	procedureId,
	onCloseProcedureDialog,
}: ProcedureTableHeaderProps) => {
	const [isOpenDialog, setIsOpenDialog] = useState(false);
	const handleCreateProcedure = () => setIsOpenDialog(true);

	useEffect(() => {
		setIsOpenDialog(!!isProcedureDialogOpen);
	}, [isProcedureDialogOpen]);

	return (
		<Stack flexDirection="row" gap={2} alignItems="center">
			<PermissionWall permissionCodes={[PermissionEnum.SIGN_PROCEDURE_WRITE]}>
				<Button variant="contained" startIcon={<Icon path={mdiPlus} size="small" />} onClick={handleCreateProcedure}>
					Créer une procédure
				</Button>
			</PermissionWall>
			<SignaturesKpi />

			<ProcedureDialog
				isOpen={isOpenDialog}
				procedureId={procedureId}
				onClose={() => {
					onCloseProcedureDialog?.();
					setIsOpenDialog(false);
				}}
			/>
		</Stack>
	);
};

export default ProceduresTableHeader;
