import { ApolloError, useMutation } from '@apollo/client';
import {
	Chip,
	ConfirmationDialog,
	DialogProps,
	Icon,
	IconButton,
	TableColumnType,
	Tooltip,
	useTable,
} from '@elipssolution/harfang';
import { mdiDelete, mdiSync } from '@mdi/js';
import { Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import useArchivedCustomerFileDataSource from './hooks/useArchivedCustomerFileDataSource';
import SettingsTable from '../../../components/SettingsTable';
import { CustomerFileType, CustomerFileWithDomainType } from '../../../types/customerFile';
import { DIALOG_CLOSE_DELAY } from '../../../utils/dialogCloseDelay';
import { generateErrorInformations } from '../../../utils/errorHandler';
import { REMOVE_CUSTOMER_FILE } from '../../api/customerFile';
import { SYNCHRONIZE_CUSTOMER_FILE } from '../../api/synchonization';

const ArchivedCustomerFileTableSection = () => {
	const tableInstance = useTable();

	const { dataSource: archivedCustomerFileDataSource } = useArchivedCustomerFileDataSource();

	const [customerFileToDelete, setCustomerFileToDelete] = useState<Pick<CustomerFileType, 'id' | 'code' | 'name'>>();
	const [deleteCustomerFileError, setDeleteCustomerFileError] = useState<string>();
	const [hasRemoveCustomerFileSucceeded, setHasRemoveCustomerFileSucceeded] = useState(false);

	const handleConfirmationDialogClose = () => {
		setCustomerFileToDelete(undefined);
		setDeleteCustomerFileError(undefined);
		setHasRemoveCustomerFileSucceeded(false);
	};

	const [synchronizeCustomerFile] = useMutation(SYNCHRONIZE_CUSTOMER_FILE);

	const handleSynchronizeButtonClick = (customerFileId: CustomerFileType['id']) =>
		synchronizeCustomerFile({
			variables: {
				customerFileId,
			},
		});

	const [removeCustomerFile, { loading: isRemoveCustomerFileLoading }] = useMutation(REMOVE_CUSTOMER_FILE, {
		onCompleted: () => {
			setHasRemoveCustomerFileSucceeded(true);

			setTimeout(() => {
				handleConfirmationDialogClose();

				tableInstance.reload();
			}, DIALOG_CLOSE_DELAY);
		},
		onError: (error: ApolloError) =>
			setDeleteCustomerFileError(generateErrorInformations({ error, resource: 'removeCustomerFile' })?.message),
	});

	const handleRemoveCustomerFileButtonClick = useCallback(
		() =>
			removeCustomerFile({
				variables: {
					removeCustomerFileId: customerFileToDelete?.id,
				},
			}),
		[customerFileToDelete?.id, removeCustomerFile],
	);

	const columns: TableColumnType<CustomerFileWithDomainType>[] = [
		{
			field: 'code',
			flexGrow: 0,
			key: 'code',
			sortable: true,
			title: 'Code',
			width: 100,
		},
		{
			field: 'name',
			key: 'name',
			sortable: true,
			title: 'Nom du dossier',
			width: 420,
		},
		{
			key: 'chip',
			render: ({ domain: { isDefault, name } }) => <Chip label={name} color={isDefault ? 'info' : 'default'} />,
			title: 'Domaine',
			width: 150,
		},
		{
			key: 'actions',
			align: 'right',
			flexGrow: 0,
			render: ({ id, name, code }) => (
				<Stack flexDirection="row" gap={1}>
					<Tooltip content="Synchroniser le dossier">
						<IconButton onClick={() => handleSynchronizeButtonClick(id)} size="small">
							<Icon path={mdiSync} />
						</IconButton>
					</Tooltip>
					<Tooltip content="Supprimer le dossier">
						<IconButton onClick={() => setCustomerFileToDelete({ id, name, code })} size="small">
							<Icon path={mdiDelete} />
						</IconButton>
					</Tooltip>
				</Stack>
			),
			width: 80,
		},
	];

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveCustomerFileLoading,
				label: 'Annuler',
				onClick: handleConfirmationDialogClose,
			},
			{
				onClick: handleRemoveCustomerFileButtonClick,
				loading: isRemoveCustomerFileLoading,
				error: !!deleteCustomerFileError,
				persistantErrorMessage: deleteCustomerFileError,
				success: !!hasRemoveCustomerFileSucceeded,
				color: 'error',
				label: hasRemoveCustomerFileSucceeded ? 'Dossier supprimé' : 'Supprimer le dossier',
				variant: 'contained',
			},
		],
		[
			deleteCustomerFileError,
			handleRemoveCustomerFileButtonClick,
			hasRemoveCustomerFileSucceeded,
			isRemoveCustomerFileLoading,
		],
	);

	return (
		<>
			<SettingsTable<CustomerFileWithDomainType>
				table={tableInstance}
				dataSource={archivedCustomerFileDataSource}
				tableColumns={columns}
				title="Dossiers archivés"
			/>

			<ConfirmationDialog
				actionsDialog={actionsDialog}
				maxWidth={false}
				onClose={handleConfirmationDialogClose}
				open={!!customerFileToDelete}
				title={`Êtes-vous sûr de vouloir supprimer le dossier ${customerFileToDelete?.code ?? ''} - ${
					customerFileToDelete?.name ?? ''
				} ?`}
			/>
		</>
	);
};

export default ArchivedCustomerFileTableSection;
