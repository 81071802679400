import { SVGProps } from 'react';

const KilometricExpensesSVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 24 24" height={60} width={60} {...props}>
		<path d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 4.41 8.273 5.768 4.618 0 0 1-.2-1.175 5.768 4.618 0 0 1 .245-1.325C4.905 16.292 4 14.283 4 12a8 8 0 0 1 8-8 8 8 0 0 1 8 8c0 2.296-.916 4.317-2.484 5.8a5.768 4.618 0 0 1 .23 1.298 5.768 4.618 0 0 1-.209 1.216A10 10 0 0 0 22 12 10 10 0 0 0 12 2zm-2 4a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1zm4 0a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1zM6.91 8.94a1 1 0 0 0-.94.62c-.2.52.03 1.1.56 1.31l.02.01 3.22 1.3.23.1c.12.72.59 1.31 1.25 1.57 1.02.42 2.19-.08 2.6-1.1.42-1.02-.08-2.19-1.1-2.6-.66-.27-1.42-.15-1.98.28l-.27-.11L7.3 9c-.14-.03-.26-.06-.39-.06zM17 9a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1z" />
		<path
			d="M-30.513 7.584a4.153 4.273 0 0 0-4.152 4.273 4.153 4.273 0 0 0 4.152 4.272 4.153 4.273 0 0 0 4.154-4.272 4.153 4.273 0 0 0-4.154-4.273zm.18 1.811c.634 0 1.215.24 1.654.634l-.488.49a1.792 1.792 0 0 0-2.757.53l1.866.001-.276.553h-1.771a1.826 1.826 0 0 0-.023.276c0 .094.009.184.023.275h2.047l-.276.554h-1.59c.298.573.9.965 1.59.965.447 0 .853-.164 1.165-.435l.49.488a2.465 2.465 0 0 1-1.655.636c-1.081 0-2-.689-2.347-1.654h-1.24l.275-.554h.844c-.016-.09-.015-.181-.015-.275 0-.094-.001-.184.015-.276h-1.12l.276-.553h.965a2.495 2.495 0 0 1 2.347-1.655z"
			style={{
				fill: '#000',
				fillOpacity: 1,
				strokeWidth: 0.258,
			}}
			transform="matrix(.87216 0 0 .87216 38.667 9.144)"
		/>
	</svg>
);
export default KilometricExpensesSVG;
