import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import {
	Button,
	SettingsItemAutocomplete,
	Icon,
	SettingsGroup,
	SettingsItemTextField,
	useAutocomplete,
	SettingsItemCheckbox,
} from '@elipssolution/harfang';
import { mdiPlus } from '@mdi/js';
import { styled, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import AddedCustomerFileUserRelationTable from './AddedCustomerFileUserRelationTable';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import { FETCH_INTERNAL_GROUPS, FetchInternalGroupsType } from '../../../api/internalGroup';
import {
	CREATE_INTERNAL_USER_WITH_CUSTOMER_FILES,
	CreateInternalUserWithCustomerFilesType,
	FETCH_INTERNAL_USERS_WITHOUT_CUSTOMER_FILE_RELATION,
	FetchInternalUsersWithoutCustomerFileRelationType,
} from '../../../api/internalUser';
import {
	CREATE_INTERNAL_USER_CUSTOMER_FILE_RELATION,
	CreateInternalUserCustomerFileRelationType,
	REMOVE_INTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER,
} from '../../../api/internalUserCustomerFile';
import { GroupType } from '../../../types/group';
import { AddInternalUserType, InternalUserType } from '../../../types/user';
import SettingsItemEmailField from '../../SettingsItemEmailField';
import { useUserCustomerFileRelationContext } from '../../UserCustomerFileRelationProvider';

const FormWrapper = styled('form')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: spacing(2),
}));

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type AddingModeType = {
	id: string;
	label: string;
};

const addingModes: AddingModeType[] = [
	{ id: 'existing', label: 'Existant' },
	{ id: 'new', label: 'Nouveau' },
];

const addingModesDataSource = async (): Promise<{
	items: AddingModeType[];
	count: number;
}> =>
	Promise.resolve({
		items: addingModes,
		count: addingModes.length,
	});

type FormType = {
	user?: InternalUserType;
	addingMode?: AddingModeType;
	firstName?: InternalUserType['firstName'];
	lastName?: InternalUserType['lastName'];
	email?: InternalUserType['email'];
	group?: GroupType;
	isBillingManager: InternalUserType['isBillingManager'];
};

type AddCustomerFileUserRelationFormProps = {
	customerFile: CustomerFileType;
};

const AddCustomerFileUserRelationForm = ({
	customerFile: { id: customerFileId, name: customerFileName },
}: AddCustomerFileUserRelationFormProps) => {
	const { back } = useSettingsDialog();
	const { userCustomerFileRelations, setUserCustomerFileRelations } = useUserCustomerFileRelationContext();

	const userAutocomplete = useAutocomplete();
	const groupAutocomplete = useAutocomplete();
	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
		watch,
	} = useForm<FormType>({
		mode: 'onChange',
	});

	const addingMode = watch('addingMode');

	const [error, setError] = useState<string>();
	const [internalUsersToAdd, setInternalUsersToAdd] = useState(userCustomerFileRelations as AddInternalUserType[]);

	const [fetchInternalUsersWithoutRelation] = useLazyQuery<FetchInternalUsersWithoutCustomerFileRelationType>(
		FETCH_INTERNAL_USERS_WITHOUT_CUSTOMER_FILE_RELATION,
	);

	const internalUsersDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: InternalUserType[];
		}> => {
			const { data, error: queryError } = await fetchInternalUsersWithoutRelation({
				variables: {
					customerFileId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				internalUsersWithoutCustomerFileRelation: { count = 0, items = [] },
			} = data ?? {
				internalUsersWithoutCustomerFileRelation: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchInternalUsersWithoutRelation, customerFileId],
	);

	const [fetchInternalGroups] = useLazyQuery<FetchInternalGroupsType>(FETCH_INTERNAL_GROUPS);

	const internalGroupsDataSource = useCallback(
		async (limit: number, offset: number, search?: string) => {
			const { data, error: queryError } = await fetchInternalGroups({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			if (!data) {
				throw new Error('Aucune donnée reçue');
			}

			return data.internalGroups;
		},
		[fetchInternalGroups],
	);

	const [createInternalUserCustomerFileRelation, { loading: isCreateInternalUserCustomerFileRelationLoading }] =
		useMutation<CreateInternalUserCustomerFileRelationType>(CREATE_INTERNAL_USER_CUSTOMER_FILE_RELATION, {
			onError: (createError) =>
				setError(
					generateErrorInformations({ error: createError, resource: 'createInternalUserCustomerFileRelation' }).message,
				),
		});

	const [createInternalUserWithCustomerFiles] = useMutation<CreateInternalUserWithCustomerFilesType>(
		CREATE_INTERNAL_USER_WITH_CUSTOMER_FILES,
		{
			onError: (createError) =>
				setError(
					generateErrorInformations({ error: createError, resource: 'createInternalUserWithCustomerFiles' }).message,
				),
		},
	);

	const [removeInternalUserCustomerFileRelation] = useMutation(
		REMOVE_INTERNAL_USER_CUSTOMER_FILE_RELATION_BY_CUSTOMER_FILE_AND_USER,
		{
			onError: (mutationError) =>
				setError(
					generateErrorInformations({ error: mutationError, resource: 'removeInternalUserCustomerFileRelation' })
						.message,
				),
		},
	);

	const handleFormReset = useCallback(
		(addMode: AddingModeType) => {
			reset({
				user: undefined,
				firstName: undefined,
				lastName: undefined,
				email: undefined,
				addingMode: addMode,
				isBillingManager: false,
			});

			groupAutocomplete.reload();
			userAutocomplete.reload();
		},
		[userAutocomplete, groupAutocomplete, reset],
	);

	const onSubmit = useCallback(
		({ user, firstName, lastName, email, group, isBillingManager }: FormType) => {
			const modeMutationInfoMap = new Map([
				[
					'existing',
					{
						mutation: createInternalUserCustomerFileRelation,
						mutationName: 'createInternalUserCustomerFileRelation',
						inputValues: {
							userId: user?.id,
							customerFileId,
						},
					},
				],
				[
					'new',
					{
						mutation: createInternalUserWithCustomerFiles,
						mutationName: 'createInternalUserWithCustomerFiles',
						inputValues: {
							customerFileIds: [customerFileId],
							internalUser: {
								email,
								firstName,
								lastName,
								groupId: group?.id,
							},
						},
					},
				],
			]);

			const { mutation, mutationName = '', inputValues } = modeMutationInfoMap.get(user ? 'existing' : 'new') ?? {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: inputValues,
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { id } = user
						? data[mutationName as keyof typeof data]
						: (
								data[mutationName as keyof typeof data] as {
									customerFileIds: CustomerFileType['id'][];
									internalUser: InternalUserType;
								}
						  ).internalUser;

					if (firstName && lastName && email && group) {
						setInternalUsersToAdd((prevInternalUsers) => [
							...prevInternalUsers,
							{
								id,
								group,
								firstName,
								lastName,
								email,
								isBillingManager,
							},
						]);
					} else if (user) {
						setInternalUsersToAdd((prevInternalUsers) => [...prevInternalUsers, user]);
					}

					addingMode && handleFormReset(addingMode);
				})
				.catch((mutationError: ApolloError) =>
					setError(generateErrorInformations({ error: mutationError, resource: mutationName }).message),
				);
		},
		[
			createInternalUserCustomerFileRelation,
			customerFileId,
			createInternalUserWithCustomerFiles,
			addingMode,
			handleFormReset,
		],
	);

	const handleRemoveInternalUser = useCallback(
		async (internalUserId: AddInternalUserType['id']) => {
			await removeInternalUserCustomerFileRelation({
				variables: { customerFileId, userId: internalUserId },
			});

			setInternalUsersToAdd((prevInternalUsers) => {
				const internalUsersToAddFiltered = prevInternalUsers.filter(({ id }) => id !== internalUserId);

				setUserCustomerFileRelations(internalUsersToAddFiltered);

				return internalUsersToAddFiltered;
			});
			userAutocomplete.reload();
		},
		[customerFileId, removeInternalUserCustomerFileRelation, setUserCustomerFileRelations, userAutocomplete],
	);

	const handleBack = useCallback(() => {
		if (userCustomerFileRelations.length > 0) {
			setUserCustomerFileRelations([]);
		}
		back();
	}, [back, setUserCustomerFileRelations, userCustomerFileRelations.length]);

	useEffect(() => {
		if (internalUsersToAdd.length > 0) {
			setUserCustomerFileRelations(internalUsersToAdd);
		}
	}, [internalUsersToAdd, setUserCustomerFileRelations]);

	return (
		<SettingsDialogPage title={`Ajout d'utilisateurs - ${customerFileName}`} onBack={handleBack}>
			<FormWrapper>
				<SettingsGroup label="Mode d'ajout">
					<Controller
						control={control}
						defaultValue={addingModes[0]}
						render={({ field: { onChange, ...field } }) => {
							const handleChange = (newAddingMode?: AddingModeType) => {
								onChange(newAddingMode);
								newAddingMode && handleFormReset(newAddingMode);
							};

							return (
								<SettingsItemAutocomplete<AddingModeType>
									{...field}
									dataSource={addingModesDataSource}
									description="Permet de lier un utilisateur existant ou un nouvel utilisateur au dossier."
									getOptionLabel={({ label }) => label}
									label="Mode d'ajout"
									onChange={handleChange}
									disableClearable
								/>
							);
						}}
						name="addingMode"
					/>

					{addingMode?.id === 'new' ? (
						<>
							<Controller
								control={control}
								render={({ field: { onChange, ...field } }) => {
									const handleUpperCaseChange = (value?: string) => {
										onChange(value?.toUpperCase());
									};

									return (
										<SettingsItemTextField
											{...field}
											description="Nom de l'utilisateur."
											label="Nom"
											onChange={handleUpperCaseChange}
											required
										/>
									);
								}}
								name="lastName"
								rules={{ required: true }}
							/>
							<Controller
								control={control}
								render={({ field: { onChange, ...field } }) => {
									const handleFirstLetterUpperCaseChange = (value?: string) => {
										onChange(value ? value.charAt(0).toUpperCase() + value.slice(1) : undefined);
									};

									return (
										<SettingsItemTextField
											{...field}
											description="Prénom de l'utilisateur."
											label="Prénom"
											onChange={handleFirstLetterUpperCaseChange}
										/>
									);
								}}
								name="firstName"
							/>
							<SettingsItemEmailField<FormType> control={control} />
							<Controller
								control={control}
								name="group"
								render={({ field }) => (
									<SettingsItemAutocomplete<GroupType>
										{...field}
										autocomplete={groupAutocomplete}
										dataSource={internalGroupsDataSource}
										description="Groupe attaché à l'utilisateur."
										getOptionLabel={({ name }) => name ?? ''}
										label="Groupe"
										disableClearable
										required
									/>
								)}
								rules={{ required: true }}
							/>
							<Controller
								name="isBillingManager"
								defaultValue={false}
								control={control}
								render={({ field: { onChange, value, ...field } }) => (
									<SettingsItemCheckbox
										{...field}
										checked={value}
										description=""
										label="Est responsable facturation"
										onChange={(_, checked: boolean) => onChange(checked)}
									/>
								)}
							/>
						</>
					) : (
						<Controller
							control={control}
							name="user"
							render={({ field }) => (
								<SettingsItemAutocomplete<InternalUserType>
									{...field}
									autocomplete={userAutocomplete}
									dataSource={internalUsersDataSource}
									description="Utilisateur à ajouter."
									getOptionLabel={({ lastName, firstName }) => `${firstName ?? ''} ${lastName}`.trim()}
									label="Utilisateur"
									disableClearable
									required
								/>
							)}
							rules={{ required: true }}
						/>
					)}
				</SettingsGroup>
			</FormWrapper>

			<Stack justifyContent="space-between" flexDirection="row-reverse">
				<Button
					disabled={isCreateInternalUserCustomerFileRelationLoading || !isDirty || !isValid}
					startIcon={<Icon path={mdiPlus} />}
					onClick={handleSubmit(onSubmit)}
					variant="contained"
				>
					{addingMode?.id === 'new' ? 'Lier un nouvel utilisateur' : 'Lier un utilisateur existant'}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</Stack>

			<AddedCustomerFileUserRelationTable
				internalUsers={internalUsersToAdd}
				onRemoveInternalUser={handleRemoveInternalUser}
			/>
		</SettingsDialogPage>
	);
};

export default AddCustomerFileUserRelationForm;
