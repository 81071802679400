import { ReactNode } from 'react';

import SimulatorCard from './SimulatorCard';

type SimulatorInstanceProps = {
	name: string;
	url: string;
	readOnly: boolean;
	svgIcon: ReactNode;
	backgroundColor: string;
};

const SimulatorInstance = ({ name, url, svgIcon, readOnly, backgroundColor }: SimulatorInstanceProps) => {
	const handleClick = () => window.open(url, '_blank');

	return (
		<SimulatorCard
			name={name}
			image={svgIcon}
			onClick={handleClick}
			readOnly={readOnly}
			backgroundColor={backgroundColor}
		/>
	);
};

export default SimulatorInstance;
