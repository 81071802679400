import { Button, Icon, SettingsGroup, SettingsItemCheckbox, SettingsItemTextField } from '@elipssolution/harfang';
import { mdiPencil } from '@mdi/js';
import { Stack } from '@mui/material';
import { useState } from 'react';

import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { WidgetWithKeyType } from '../../../types/widget';
import { getWidgetTemplateByType } from '../../widgets/widgets';

const DefaultConfigurationComponent = ({
	name: initialName,
	isAvailableOnDesktopFormat: initialIsAvailableOnDesktopFormat,
	isAvailableOnMobileFormat: initialIsAvailableOnMobileFormat,
	onSave,
	onBack,
}: {
	name?: string;
	isAvailableOnDesktopFormat: boolean;
	isAvailableOnMobileFormat: boolean;
	onSave: (
		name: string,
		isAvailableOnDesktopFormat: boolean,
		isAvailableOnMobileFormat: boolean,
		configuration?: object,
	) => Promise<void>;
	onBack: () => void;
}) => {
	const [name, setName] = useState(initialName);
	const [isAvailableOnDesktopFormat, setIsAvailableOnDesktopFormat] = useState(initialIsAvailableOnDesktopFormat);
	const [isAvailableOnMobileFormat, setIsAvailableOnMobileFormat] = useState(initialIsAvailableOnMobileFormat);

	const handleNameChange = (changedName?: string) => {
		setName(changedName);
	};

	const handleSave = () => onSave(name ?? '', isAvailableOnDesktopFormat, isAvailableOnMobileFormat);

	return (
		<SettingsDialogPage title={name} onBack={onBack}>
			<SettingsItemTextField label="Nom du widget" value={name} onChange={handleNameChange} />

			<SettingsGroup label="Configuration">
				<SettingsItemCheckbox
					checked={isAvailableOnDesktopFormat}
					description="Si coché, le widget sera visible sur ordinateur."
					label="Visible version ordinateur"
					onChange={(_, checked: boolean) => setIsAvailableOnDesktopFormat(checked)}
				/>
				<SettingsItemCheckbox
					checked={isAvailableOnMobileFormat}
					description="Si coché, le widget sera visible sur mobile."
					label="Visible version mobile"
					onChange={(_, checked: boolean) => setIsAvailableOnMobileFormat(checked)}
				/>
			</SettingsGroup>

			<Stack direction="row" justifyContent="flex-end">
				<Button startIcon={<Icon path={mdiPencil} size="small" />} variant="contained" onClick={handleSave}>
					Appliquer
				</Button>
			</Stack>
		</SettingsDialogPage>
	);
};

type SettingsWidgetConfigurationProps = {
	widgetWithKey: WidgetWithKeyType;
	onSave: (widgetWithKey: WidgetWithKeyType) => Promise<void>;
	onBack: () => void;
};

const SettingsWidgetConfiguration = ({ widgetWithKey, onSave, onBack }: SettingsWidgetConfigurationProps) => {
	const { key, widget } = widgetWithKey;
	const { name, isAvailableOnDesktopFormat, isAvailableOnMobileFormat, configuration } = widget;
	const jsonConfiguration = configuration ? (JSON.parse(configuration) as object) : undefined;

	const handleSave = async (
		savedName: string,
		savedIsAvailableOnDesktopFormat: boolean,
		savedIsAvailableOnMobileFormat: boolean,
		savedConfiguration?: object,
	) => {
		const stringConfiguration = savedConfiguration ? JSON.stringify(savedConfiguration) : undefined;
		return onSave({
			key,
			widget: {
				...widget,
				name: savedName,
				isAvailableOnDesktopFormat: savedIsAvailableOnDesktopFormat,
				isAvailableOnMobileFormat: savedIsAvailableOnMobileFormat,
				configuration: stringConfiguration,
			},
		});
	};

	const { configurationComponent: ConfigurationComponent } = getWidgetTemplateByType(widgetWithKey.widget.type);

	return ConfigurationComponent ? (
		<ConfigurationComponent
			name={name}
			isAvailableOnDesktopFormat={isAvailableOnDesktopFormat}
			isAvailableOnMobileFormat={isAvailableOnMobileFormat}
			widgetWithKey={widgetWithKey}
			configuration={jsonConfiguration}
			onSave={handleSave}
			onBack={onBack}
		/>
	) : (
		<DefaultConfigurationComponent
			name={name}
			isAvailableOnDesktopFormat={isAvailableOnDesktopFormat}
			isAvailableOnMobileFormat={isAvailableOnMobileFormat}
			onSave={handleSave}
			onBack={onBack}
		/>
	);
};

export default SettingsWidgetConfiguration;
