import { useLazyQuery } from '@apollo/client';
import { Chip, Icon, IconButton, Menu, TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { mdiDotsVertical } from '@mdi/js';
import { MenuItem } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';

import Domain from './domain/Domain';
import DomainDuplication from './domain/DomainDuplication';
import LinkIcon from '../../../components/LinkIcon';
import SettingsTable from '../../../components/SettingsTable';
import { useSettingsDialog } from '../../../hooks/useSettingsDialog';
import { DomainType } from '../../../types/domain';
import { FETCH_DOMAINS, FetchDomainsType } from '../../api/domain';

const Options = ({ domain }: { domain: DomainType }) => {
	const { push } = useSettingsDialog();

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

	const openOptionsMenu = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const closeOptionsMenu = (event: MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(undefined);
	};

	const navigateToDomainDuplicationForm = (event: MouseEvent<HTMLLIElement, globalThis.MouseEvent>) => {
		event.stopPropagation();
		push(<DomainDuplication domain={domain} />);
	};

	return (
		<>
			<IconButton onClick={openOptionsMenu}>
				<Icon path={mdiDotsVertical} />
			</IconButton>

			<Menu
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={closeOptionsMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={navigateToDomainDuplicationForm}>Dupliquer</MenuItem>
			</Menu>
		</>
	);
};

const columns: TableColumnType<DomainType>[] = [
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom du domaine',
		width: 200,
	},
	{
		key: 'chip',
		flexGrow: 0,
		render: ({ isDefault }) => isDefault && <Chip label="Défaut" color="info" />,
		width: 85,
	},
	{
		key: 'options',
		flexGrow: 0,
		width: 40,
		render: (domain) => <Options domain={domain} />,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

const DomainTableSection = () => {
	const { push } = useSettingsDialog();
	const [fetchDomains] = useLazyQuery<FetchDomainsType>(FETCH_DOMAINS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<DomainType>,
		): Promise<{
			count: number;
			items: DomainType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchDomains({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				domains: { count = 0, items = [] },
			} = data ?? {
				domains: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchDomains],
	);
	const openDomainPage = (domain?: DomainType) => {
		push(<Domain domain={domain} />);
	};

	return (
		<SettingsTable<DomainType>
			addButtonLabel="Ajouter un domaine"
			dataSource={dataSource}
			minHeight={400}
			onAddButtonClick={openDomainPage}
			onRowClick={openDomainPage}
			tableColumns={columns}
			title="Domaines"
		/>
	);
};

export default DomainTableSection;
