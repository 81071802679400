import { Button, Icon, SettingsGroup, SettingsItemCheckbox, SettingsItemTextField } from '@elipssolution/harfang';
import { mdiPencil } from '@mdi/js';
import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import ExternalApplicationForm from './ExternalApplicationForm';
import ExternalApplicationsTable from './ExternalApplicationsTable';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import {
	WidgetConfigurationComponentProps,
	WidgetExternalApplicationType,
	WidgetExternalApplicationsConfigurationType,
} from '../../../../types/widget';
import { useWidgetImages } from '../../../settings/widgets/provider/WidgetImagesProvider';

enum ViewStep {
	TABLE,
	ADD,
	EDIT,
}

const WidgetExternalApplicationsConfiguration = ({
	name: initialName = 'Applications externes',
	isAvailableOnDesktopFormat: initialIsAvailableOnDesktopFormat,
	isAvailableOnMobileFormat: initialIsAvailableOnMobileFormat,
	widgetWithKey,
	configuration,
	onSave,
	onBack,
}: WidgetConfigurationComponentProps) => {
	const { saveImages } = useWidgetImages();

	const { applications: initialApplications = [] } =
		(configuration as WidgetExternalApplicationsConfigurationType | undefined) ?? {};

	const [name, setName] = useState(initialName);
	const [isAvailableOnDesktopFormat, setIsAvailableOnDesktopFormat] = useState(initialIsAvailableOnDesktopFormat);
	const [isAvailableOnMobileFormat, setIsAvailableOnMobileFormat] = useState(initialIsAvailableOnMobileFormat);
	const [applications, setApplications] = useState(initialApplications);
	const [formState, setViewStep] = useState<ViewStep>(ViewStep.TABLE);
	const [selectedApplication, setSelectedApplication] = useState<WidgetExternalApplicationType>();

	const setApplicationsSorted = (unsortedApplications: WidgetExternalApplicationType[]) => {
		const sortedApplications = unsortedApplications.sort((a, b) => a.name.localeCompare(b.name));

		setApplications(sortedApplications);
	};

	const navigateBack = () => {
		setSelectedApplication(undefined);
		setViewStep(ViewStep.TABLE);
	};

	const handleAddApplication = (application: WidgetExternalApplicationType) => {
		navigateBack();
		setApplicationsSorted([...applications, application]);
	};

	const handleEditApplication = (application: WidgetExternalApplicationType) => {
		navigateBack();
		setApplicationsSorted([
			...applications.filter((previousValue) => previousValue.key !== application.key),
			application,
		]);
	};

	const handleRemoveApplication = ({ key }: WidgetExternalApplicationType) => {
		navigateBack();
		setApplications((previousValues) => previousValues.filter((previousValue) => previousValue.key !== key));
	};

	const handleSave = async () => {
		navigateBack();
		await saveImages();
		return onSave(name, isAvailableOnDesktopFormat, isAvailableOnMobileFormat, { applications });
	};

	const navigateToAddApplication = () => setViewStep(ViewStep.ADD);

	const navigateToEditApplication = (application: WidgetExternalApplicationType) => {
		setSelectedApplication(application);
		setViewStep(ViewStep.EDIT);
	};

	useEffect(() => setName(name), [name]);

	return (
		<>
			{formState === ViewStep.TABLE && (
				<SettingsDialogPage title={name} onBack={onBack}>
					<SettingsItemTextField label="Nom du widget" value={name} onChange={(value) => setName(value ?? '')} />

					<SettingsGroup label="Configuration">
						<SettingsItemCheckbox
							checked={isAvailableOnDesktopFormat}
							description="Si coché, le widget sera visible sur ordinateur."
							label="Visible version ordinateur"
							onChange={(_, checked: boolean) => setIsAvailableOnDesktopFormat(checked)}
						/>
						<SettingsItemCheckbox
							checked={isAvailableOnMobileFormat}
							description="Si coché, le widget sera visible sur mobile."
							label="Visible version mobile"
							onChange={(_, checked: boolean) => setIsAvailableOnMobileFormat(checked)}
						/>
					</SettingsGroup>

					<ExternalApplicationsTable applications={applications} onSelectApplication={navigateToEditApplication} />

					<Stack direction="row" justifyContent="flex-end" gap={1}>
						<Button onClick={navigateToAddApplication}>Ajouter une application</Button>
						<Button startIcon={<Icon path={mdiPencil} size="small" />} variant="contained" onClick={handleSave}>
							Appliquer
						</Button>
					</Stack>
				</SettingsDialogPage>
			)}

			{formState === ViewStep.ADD && (
				<ExternalApplicationForm
					widgetWithKey={widgetWithKey}
					onSubmit={handleAddApplication}
					onRemove={handleRemoveApplication}
					onBack={navigateBack}
				/>
			)}

			{formState === ViewStep.EDIT && (
				<ExternalApplicationForm
					widgetWithKey={widgetWithKey}
					application={selectedApplication}
					onRemove={handleRemoveApplication}
					onSubmit={handleEditApplication}
					onBack={navigateBack}
				/>
			)}
		</>
	);
};

export default WidgetExternalApplicationsConfiguration;
