import { SettingsGroup, SettingsItemNavigation } from '@elipssolution/harfang';

import BillExchangeCounterpartAccount from './billExchangeCounterpartAccount/BillExchangeCounterpartAccount';
import CheckAccountTypes from './checkAccountTypes/CheckAccountTypes';
import CheckRemittanceCounterpartAccount from './checkRemittanceCounterpartAccount/CheckRemittanceCounterpartAccount';
import JournalCodes from './journalCodes/JournalCodes';
import PaymentModes from './paymentModes/PaymentModes';
import Templates from './templates/Templates';
import SettingsDialogPage from '../../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../../hooks/useSettingsDialog';
import { DomainType } from '../../../../../types/domain';
import { CheckTypeEnum } from '../../../types/check';

type DomainSectionProps = {
	domain?: DomainType;
};

const DomainSection = ({ domain }: DomainSectionProps) => {
	const { push } = useSettingsDialog();

	const { id: domainId, name: domainName } = domain || {};

	const navigateToTemplatesSection = () => push(<Templates domainId={domainId} />);

	const navigateToJournalCodesSection = () => push(<JournalCodes domainId={domainId} />);
	const navigateToPaymentModesSection = () => push(<PaymentModes domainId={domainId} />);
	const navigateToCheckIssuanceAccountTypesSection = () => push(<CheckAccountTypes domainId={domainId} />);
	const navigateToCheckRemittanceAccountTypesSection = () =>
		push(<CheckAccountTypes domainId={domainId} checkType={CheckTypeEnum.CHECK_REMITTANCE} />);
	const navigateToCheckRemittanceCounterpartAccountSection = () =>
		push(<CheckRemittanceCounterpartAccount domainId={domainId} />);
	const navigateToBillExchangeCounterparAccountSection = () =>
		push(<BillExchangeCounterpartAccount domainId={domainId} />);

	return (
		<SettingsDialogPage title={domainName}>
			<SettingsGroup label="Paramétrage Modèles de pièce">
				<SettingsItemNavigation label="Modèles de pièces" onClick={navigateToTemplatesSection} />
			</SettingsGroup>

			<SettingsGroup label="Paramétrage Saisie chèque, remise de chèque et LCR">
				<SettingsItemNavigation label="Journaux" onClick={navigateToJournalCodesSection} />
				<SettingsItemNavigation label="Modes de règlement" onClick={navigateToPaymentModesSection} />
				<SettingsItemNavigation label='Type "Chèque émis"' onClick={navigateToCheckIssuanceAccountTypesSection} />
				<SettingsItemNavigation
					label='Type "Remise de chèque"'
					onClick={navigateToCheckRemittanceAccountTypesSection}
				/>
				<SettingsItemNavigation
					label="Compte de contrepartie de remise de chèque"
					onClick={navigateToCheckRemittanceCounterpartAccountSection}
				/>
				<SettingsItemNavigation
					label="Compte de contrepartie LCR émises"
					onClick={navigateToBillExchangeCounterparAccountSection}
				/>
			</SettingsGroup>
		</SettingsDialogPage>
	);
};

export default DomainSection;
