import { SettingsGroup } from '@elipssolution/harfang';
import { Control, Controller } from 'react-hook-form';

import ConnectorFieldsForm from '../../components/ConnectorFieldsForm';
import { ConnectorType } from '../../types/connector';
import { StorageDownloadFormType } from '../../types/storageDownloadFormType';

type ConnectorDetailsFormProps = {
	control: Control<StorageDownloadFormType>;
	connectorParameters: ConnectorType['parameters'];
};

const ConfigurationForm = ({ control, connectorParameters }: ConnectorDetailsFormProps) => {
	const validateConfiguration = (value: Record<string, string | number> | undefined) =>
		!!value &&
		connectorParameters.every(({ code, isRequired, type }) => {
			const formValue = value[code];

			if (isRequired && (formValue === null || formValue === undefined)) {
				return false;
			}

			if (type === 'number' && Number.isNaN(formValue)) {
				return false;
			}

			return true;
		});

	return (
		<SettingsGroup label="Configuration">
			<Controller
				control={control}
				render={({ field }) => <ConnectorFieldsForm {...field} fields={connectorParameters} />}
				name="configuration"
				rules={{ validate: validateConfiguration }}
			/>
		</SettingsGroup>
	);
};

export default ConfigurationForm;
