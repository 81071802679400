import { Tooltip } from '@elipssolution/harfang';
import { ButtonBase, Stack, Typography, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

export const logoSize = 86;

const StyledButtonBase = styled(ButtonBase)(({ theme: { shape, spacing } }) => ({
	flex: '1 1',
	borderRadius: shape.borderRadius * 2,
	overflow: 'hidden',
	padding: spacing(1),
}));

const StyledImage = styled(Stack, {
	shouldForwardProp: (prop) => prop !== 'bgColor',
})<{ bgColor: string }>(({ bgColor }) => ({
	justifyContent: 'center',
	alignItems: 'center',
	position: 'relative',
	width: logoSize,
	height: logoSize,
	borderRadius: 16,
	overflow: 'hidden',
	'&::after': {
		content: '""',
		position: 'absolute',
		inset: 0,
		backgroundColor: bgColor,
		opacity: 0.3,
	},
}));

const DisabledWrapper = ({
	readOnly,
	disabled,
	disabledMessage,
	children,
}: {
	readOnly?: boolean;
	disabled?: boolean;
	disabledMessage?: string;
	children: JSX.Element;
}): JSX.Element =>
	!readOnly && disabled && disabledMessage ? (
		<Tooltip content={disabledMessage}>
			<Stack>{children}</Stack>
		</Tooltip>
	) : (
		<Stack>{children}</Stack>
	);

type SimulatorCardCardProps = {
	name: string;
	image: ReactNode;
	disabled?: boolean;
	disabledMessage?: string;
	readOnly?: boolean;
	backgroundColor: string;
	onClick: () => void;
};

const SimulatorCard = ({
	name,
	image,
	disabled,
	disabledMessage,
	readOnly,
	backgroundColor,
	onClick,
}: SimulatorCardCardProps) => (
	<DisabledWrapper readOnly={readOnly} disabled={disabled} disabledMessage={disabledMessage}>
		<StyledButtonBase disabled={disabled || readOnly} onClick={onClick}>
			<Stack gap={1} width="100%" height="100%" alignItems="center">
				<StyledImage bgColor={backgroundColor} className={clsx({ disabled })}>
					{image}
				</StyledImage>

				<Typography align="center" width="100%" noWrap>
					{name}
				</Typography>
			</Stack>
		</StyledButtonBase>
	</DisabledWrapper>
);

export default SimulatorCard;
