import {
	ButtonSwitch,
	Dialog,
	DialogProps,
	Icon,
	IconButton,
	Select,
	Table,
	TableColumnType,
} from '@elipssolution/harfang';
import { mdiAlertOutline, mdiChevronDown, mdiChevronUp, mdiInformation, mdiInformationOutline } from '@mdi/js';
import { Checkbox, FormControlLabel, Stack, styled, Typography } from '@mui/material';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { useCallback, useMemo, useState } from 'react';

import { useSession } from '../../../../src/components/SessionProvider';
import { DIALOG_CLOSE_DELAY } from '../../../../src/utils/dialogCloseDelay';
import { uploadFileWithFormDataAndReturningErrorResponse } from '../../../../src/utils/file';
import { VisibilityLabelsTypeEnum, VisibilityTypeEnum } from '../../types/visibility';

const CELL_WIDTH = 230;

const FIELD_MAP = new Map([
	['Ne pas importer', 'Do not import'],
	['Nom', 'lastName'],
	['Prénom', 'firstName'],
	['Email', 'email'],
	['Numéro de téléphone', 'phoneNumber'],
]);

const StyledDialog = styled(({ className, ...props }: DialogProps) => (
	<Dialog {...props} classes={{ paper: className }} />
))(({ theme: { spacing } }) => ({
	'& > div:first-of-type > div': {
		gap: spacing(3),
	},
}));

const StyledValue = styled('span')({
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflowX: 'hidden',
	fontSize: '0.875rem',
	lineHeight: 1.43,
});

const InfoWrapper = styled('div')(({ theme: { shape, spacing, palette } }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'left',
	borderRadius: shape.borderRadius,
	color: palette.text.primary,
	gap: spacing(1),
}));

const StyledMessageContainer = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	gap: spacing(2),
	padding: spacing(0.5),
	alignItems: 'center',
}));

const StyledMessage = styled('div')({
	width: 700,
	flexGrow: 1,
});

const StyledIcon = styled('div')({
	width: 40,
	textAlign: 'center',
});

const TableWrapper = styled('div')(() => ({
	overflowX: 'auto',
}));

const buttonSwitchItems = Object.values(VisibilityTypeEnum).map((id) => ({ id, label: VisibilityLabelsTypeEnum[id] }));

const translateField = (field: string) => Array.from(FIELD_MAP.keys()).find((key) => FIELD_MAP.get(key) === field);

export type DataRow = {
	[key: string]: string;
};

type ErrorType = {
	linesLimitExceeded?: boolean;
	duplicates?: { line: number }[];
	missingFields?: { line: number; fields: ('firstName' | 'lastName' | 'email')[] }[];
	format?: { line: number; fields: ('phoneNumber' | 'email')[] }[];
	totalImports?: number;
};

type ImportContactsDialogProps = {
	file?: File;
	nbColumns: number;
	dataSample: DataRow[];
	selectedFields: string[];
	onDataSampleChange: (data: DataRow[]) => void;
	onSelectedFieldsChange: (fields: string[]) => void;
	onClose: () => void;
};

const ImportContactsDialog = ({
	file,
	nbColumns,
	dataSample,
	selectedFields,
	onDataSampleChange,
	onSelectedFieldsChange,
	onClose,
}: ImportContactsDialogProps) => {
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};
	const { customerFile: sessionCustomerFile } = useSession();

	const [isHeaderIncluded, setIsHeaderIncluded] = useState(false);
	const [isVisibilityPrivate, setIsVisibilityPrivate] = useState(false);
	const [isMissingFieldsOrDuplicatesErrorsDisplayed, setIsMissingFieldsOrDuplicatesErrorsDisplayed] = useState(false);
	const [isFormatErrorsDisplayed, setIsFormatErrorsDisplayed] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [hasSucceeded, setHasSucceeded] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const [errors, setErrors] = useState<ErrorType>();
	const [uploadError, setUploadError] = useState<string>();

	const mappingObject = useMemo(
		() =>
			selectedFields.reduce((acc, option, index) => {
				const key = FIELD_MAP.get(option);
				if (key && key !== 'Do not import') {
					acc[key] = index;
				}
				return acc;
			}, {} as Record<string, number>),
		[selectedFields],
	);

	const handleClose = useCallback(() => {
		onDataSampleChange([]);
		setHasErrors(false);
		setHasSucceeded(false);
		onSelectedFieldsChange([]);
		setIsHeaderIncluded(false);
		setIsFormatErrorsDisplayed(false);
		setIsMissingFieldsOrDuplicatesErrorsDisplayed(false);
		setUploadError(undefined);
		onClose();
	}, [onDataSampleChange, onSelectedFieldsChange, onClose]);

	const handleFieldChange = useCallback(
		(index: number, value: string) => {
			const newOptions = [...selectedFields];
			newOptions[index] = value;
			onSelectedFieldsChange(newOptions);
		},
		[onSelectedFieldsChange, selectedFields],
	);

	const getAvailableFields = useCallback(
		(currentIndex: number) => {
			const usedOptions = selectedFields.filter((option, idx) => option !== 'Ne pas importer' && idx !== currentIndex);
			return Array.from(FIELD_MAP.keys()).filter((option) => !usedOptions.includes(option));
		},
		[selectedFields],
	);

	const handleHeaderChange = () => setIsHeaderIncluded(!isHeaderIncluded);

	const handleVisibilityTypeSelection = (id: string) => setIsVisibilityPrivate(id === VisibilityTypeEnum.PRIVATE);

	const sendFile = useCallback(async () => {
		if (!access_token || !sessionCustomerFile?.id) return;

		if (file) {
			const uri = new URL(`/sign/contactsImport`, window.location.href);
			uri.searchParams.append('isPrivate', isVisibilityPrivate.toString());
			uri.searchParams.append('isHeaderIncluded', isHeaderIncluded.toString());

			if (mappingObject) {
				uri.searchParams.append('mapper', JSON.stringify(mappingObject));
			}

			setIsUploading(true);

			await uploadFileWithFormDataAndReturningErrorResponse<ErrorType>(access_token, file, sessionCustomerFile.id, uri)
				.then((errorResponse) => {
					if (errorResponse) {
						setHasErrors(true);
						setErrors(errorResponse);
					} else {
						setHasSucceeded(true);
						setTimeout(handleClose, DIALOG_CLOSE_DELAY);
					}
					setIsUploading(false);
				})
				.catch((error) => {
					setIsUploading(false);
					setUploadError("Erreur lors de l'import des contacts depuis le fichier.");
					throw error;
				});
		}
	}, [access_token, sessionCustomerFile, file, isVisibilityPrivate, isHeaderIncluded, mappingObject, handleClose]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] =>
			hasErrors
				? [
						{
							label: 'Fermer',
							onClick: handleClose,
						},
				  ]
				: [
						{
							label: 'Annuler',
							onClick: handleClose,
						},
						{
							disabled:
								mappingObject.firstName === undefined ||
								mappingObject.lastName === undefined ||
								mappingObject.email === undefined ||
								isUploading,
							loading: isUploading,
							error: hasErrors || !!uploadError,
							persistantErrorMessage: uploadError,
							success: hasSucceeded,
							label: !hasSucceeded ? 'Importer les contacts' : 'Contacts importés',
							onClick: sendFile,
							variant: 'contained',
						},
				  ],
		[
			uploadError,
			handleClose,
			hasErrors,
			hasSucceeded,
			isUploading,
			mappingObject.email,
			mappingObject.firstName,
			mappingObject.lastName,
			sendFile,
		],
	);

	const columns: TableColumnType<DataRow>[] = useMemo(
		() =>
			Array.from({ length: nbColumns }, (_, index) => index).map((index) => ({
				align: 'left',
				key: index.toString(),
				width: CELL_WIDTH,
				field: index.toString(),
				renderHeader: () => (
					<Select
						sx={{ mb: 1, height: 30, marginTop: 1 }}
						value={selectedFields[index] || ''}
						onChange={(e) => {
							if (e) {
								handleFieldChange(index, e);
							}
						}}
						options={getAvailableFields(index)}
						renderValue={(value) => <StyledValue>{value}</StyledValue>}
						disableClearable
					/>
				),
			})),
		[getAvailableFields, handleFieldChange, nbColumns, selectedFields],
	);

	const renderErrors = useMemo(
		() =>
			!errors ? null : (
				<>
					{!!errors?.linesLimitExceeded && (
						<StyledMessageContainer>
							<StyledIcon>
								<Icon path={mdiInformationOutline} color="error" />
							</StyledIcon>
							<StyledMessage>
								<Typography variant="subtitle1" fontWeight="bold">
									Le fichier contient trop de lignes pour être importé. Merci de réduire le contenu à 500 lignes
									maximum.
								</Typography>
							</StyledMessage>
						</StyledMessageContainer>
					)}

					{!!errors?.totalImports && (
						<StyledMessageContainer>
							<StyledIcon>
								<Icon path={mdiInformationOutline} color="info" />
							</StyledIcon>
							<StyledMessage>
								<Typography variant="subtitle1" fontWeight="bold">
									{errors.totalImports === 1
										? '1 contact a été importé'
										: `${errors.totalImports} contacts ont été importés`}
								</Typography>
							</StyledMessage>
						</StyledMessageContainer>
					)}

					{errors?.format && errors.format.length > 0 && (
						<>
							<StyledMessageContainer>
								<StyledIcon>
									<Icon path={mdiAlertOutline} color="warning" />
								</StyledIcon>
								<StyledMessage>
									<Typography variant="subtitle1" fontWeight="bold">
										{errors.format.length === 1
											? '1 contact a été importé mais contient des erreurs'
											: `${errors.format.length} contacts ont été importés mais contiennent des erreurs`}
									</Typography>
								</StyledMessage>
								<StyledIcon>
									<IconButton onClick={() => setIsFormatErrorsDisplayed(!isFormatErrorsDisplayed)}>
										<Icon path={isFormatErrorsDisplayed ? mdiChevronUp : mdiChevronDown} />
									</IconButton>
								</StyledIcon>
							</StyledMessageContainer>

							<StyledMessageContainer>
								<StyledIcon />
								<StyledMessage>
									{isFormatErrorsDisplayed &&
										errors.format.map(({ line, fields }) => (
											<Typography key={line}>
												Ligne {line} - {fields.map((field) => translateField(field)).join(' et ')}
												{fields.length > 1 ? ' incorrects' : ' incorrect'}
											</Typography>
										))}
								</StyledMessage>
							</StyledMessageContainer>
						</>
					)}

					{((errors?.duplicates && errors.duplicates.length > 0) ||
						(errors?.missingFields && errors.missingFields.length > 0)) && (
						<>
							<StyledMessageContainer>
								<StyledIcon>
									<Icon path={mdiInformationOutline} color="error" />
								</StyledIcon>
								<StyledMessage>
									{(errors?.duplicates?.length ?? 0) + (errors?.missingFields?.length ?? 0) === 1 ? (
										<Typography variant="subtitle1" fontWeight="bold">
											1 contact n&apos;a pas été importé
										</Typography>
									) : (
										<Typography variant="subtitle1" fontWeight="bold">
											{(errors?.duplicates?.length ?? 0) + (errors?.missingFields?.length ?? 0)} contacts n&apos;ont pas
											été importés
										</Typography>
									)}
								</StyledMessage>
								<StyledIcon>
									<IconButton
										onClick={() =>
											setIsMissingFieldsOrDuplicatesErrorsDisplayed(!isMissingFieldsOrDuplicatesErrorsDisplayed)
										}
									>
										<Icon path={isMissingFieldsOrDuplicatesErrorsDisplayed ? mdiChevronUp : mdiChevronDown} />
									</IconButton>
								</StyledIcon>
							</StyledMessageContainer>

							<StyledMessageContainer>
								<StyledIcon />
								<StyledMessage>
									{isMissingFieldsOrDuplicatesErrorsDisplayed &&
										errors?.duplicates?.map(({ line }) => <Typography key={line}>Ligne {line} - Doublon</Typography>)}
									{isMissingFieldsOrDuplicatesErrorsDisplayed &&
										errors?.missingFields?.map(({ line, fields }) => (
											<Typography key={line}>
												Ligne {line} - {fields.map((field) => translateField(field)).join(' et ')}
												{fields.length > 1 ? ' manquants' : ' manquant'}
											</Typography>
										))}
								</StyledMessage>
							</StyledMessageContainer>
						</>
					)}
				</>
			),
		[errors, isFormatErrorsDisplayed, isMissingFieldsOrDuplicatesErrorsDisplayed],
	);

	return (
		<StyledDialog
			maxWidth="lg"
			actionsDialog={actionsDialog}
			title="Import de contacts"
			open={dataSample.length > 0}
			onClose={handleClose}
			fullWidth
		>
			{!hasErrors && (
				<Stack height="100%" gap={1}>
					<Stack flexDirection="row" gap={3} alignItems="flex-end">
						<ButtonSwitch
							label="Visibilité globale"
							items={buttonSwitchItems}
							onClick={handleVisibilityTypeSelection}
							selectedItem={
								buttonSwitchItems.find(({ id }) =>
									isVisibilityPrivate ? id === VisibilityTypeEnum.PRIVATE : id === VisibilityTypeEnum.PUBLIC,
								)?.id ?? VisibilityTypeEnum.PUBLIC
							}
						/>
						<FormControlLabel
							control={<Checkbox checked={isHeaderIncluded} onChange={handleHeaderChange} />}
							label="La première ligne contient les entêtes"
						/>
					</Stack>

					<TableWrapper>
						<Table<DataRow>
							columns={columns}
							dataSource={() =>
								new Promise((resolve) => {
									resolve({
										items: dataSample.slice(isHeaderIncluded ? 1 : 0, 6),
										count: isHeaderIncluded ? dataSample.length - 1 : dataSample.length,
									});
								})
							}
						/>
					</TableWrapper>

					<InfoWrapper>
						<Icon path={mdiInformation} />
						<Typography>
							Le tableau ci-dessus présente uniquement un aperçu des premières lignes du fichier, et non
							l&apos;intégralité des données.
						</Typography>
					</InfoWrapper>
				</Stack>
			)}

			{hasErrors && renderErrors}
		</StyledDialog>
	);
};

export default ImportContactsDialog;
