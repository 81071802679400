import getConfig from 'next/config';
import { useCallback, useEffect } from 'react';

import { useSession } from './SessionProvider';
import modules from '../../modules/modules';
import { NavigationItemType } from '../../types/module';
import useCustomRouter from '../hooks/useCustomRouter';
import usePreviousRoute from '../hooks/usePreviousRoute';
import { PublicRuntimeConfigType } from '../types/publicRuntimeConfig';

const AccessGuard = ({ children }: { children: JSX.Element }) => {
	const { publicRuntimeConfig } = getConfig() as PublicRuntimeConfigType;
	const { standaloneModule } = publicRuntimeConfig;
	const { checkPermission } = useSession();
	const { pathname: currentPath, push } = useCustomRouter();

	const prevPath = usePreviousRoute();

	const getNavigationItemByPath = useCallback(
		({
			pathSegments,
			levels,
		}: {
			pathSegments: string[];
			levels?: NavigationItemType[];
		}): NavigationItemType | undefined => {
			if (pathSegments.length === 0) return undefined;

			const [currentSegment, ...remainingSegments] = pathSegments;
			const currentLevel = (levels || modules)?.find(({ route }) => route.slice(1) === currentSegment);

			if (remainingSegments.length === 0) return currentLevel;

			return getNavigationItemByPath({
				pathSegments: remainingSegments,
				levels: currentLevel?.subItems,
			});
		},
		[],
	);

	const validatePermissions = useCallback(
		({ pathSegments, level }: { pathSegments: string[]; level?: NavigationItemType }): boolean => {
			if (pathSegments.length === 0 || (pathSegments.length !== 0 && !level?.subItems)) return true;

			const [currentSegment, ...remainingSegments] = pathSegments;
			const currentLevel = (level?.subItems || modules)?.find(({ route }) => route.slice(1) === currentSegment);

			if (!currentLevel) return false;

			const hasPermission = currentLevel.permissionCodes?.some(checkPermission) ?? true;

			if (!hasPermission) return false;

			return validatePermissions({
				pathSegments: remainingSegments,
				level: currentLevel,
			});
		},
		[checkPermission],
	);

	useEffect(() => {
		const previousSegments = prevPath?.split('/').filter(Boolean) ?? [];
		const currentSegments = currentPath.split('/').filter(Boolean);
		const [moduleRoute] = currentSegments;

		const diffIndex = currentSegments.findIndex((segment, index) => segment !== previousSegments[index]);

		if (diffIndex === -1) return;

		const initialModule = getNavigationItemByPath({
			pathSegments: currentSegments.slice(0, diffIndex),
			levels: modules,
		});

		if (
			(!!standaloneModule && moduleRoute !== standaloneModule) ||
			(currentSegments.length > 0 &&
				!validatePermissions({
					pathSegments: currentSegments.slice(diffIndex),
					level: initialModule,
				}))
		) {
			push('/error/403').catch((error) => {
				throw error;
			});
		}
	}, [currentPath, getNavigationItemByPath, prevPath, push, standaloneModule, validatePermissions]);

	return children;
};

export default AccessGuard;
