export enum PermissionEnum {
	CUSTOMER_FILE_INTERNAL_USER_MANAGE = 'customer-file-internal-user-manage',
	INTERNAL_GROUP_MANAGE = 'internal-group-manage',

	CUSTOMER_FILE_EXTERNAL_USER_MANAGE = 'customer-file-external-user-manage',
	EXTERNAL_GROUP_MANAGE = 'external-group-manage',

	IMPERSONATE = 'impersonate',
	THEME_MANAGE = 'theme-manage',

	CUSTOMER_FILE_MANAGE = 'customer-file-manage',
	DOMAIN_MANAGE = 'domain-manage',
	WIDGET_CUSTOMER_FILE_MANAGE = 'widget-customer-file-manage',
	WIDGET_DOMAIN_MANAGE = 'widget-domain-manage',

	// ACCOUNTING
	ACCOUNTING_READ = 'accounting-read',
	ACCOUNTING_SETTING = 'accounting-setting',
	ACCOUNTING_SYNCHRONIZE = 'accounting-synchronize',

	// DOCUMENT
	DOCUMENT_DOWNLOAD = 'document-download',
	DOCUMENT_UPLOAD = 'document-upload',
	DOCUMENT_SETTING_STORAGE = 'document-setting-storage',
	DOCUMENT_SETTING_TAG = 'document-setting-tag',

	// QUICKENTRY
	QUICKENTRY_VOUCHER_READ = 'quickentry-voucher-read',
	QUICKENTRY_VOUCHER_WRITE = 'quickentry-voucher-write',
	QUICKENTRY_VOUCHER_VALIDATE = 'quickentry-voucher-validate',
	QUICKENTRY_BILL_EXCHANGE_READ = 'quickentry-bill-exchange-read',
	QUICKENTRY_BILL_EXCHANGE_WRITE = 'quickentry-bill-exchange-write',
	QUICKENTRY_BILL_EXCHANGE_VALIDATE = 'quickentry-bill-exchange-validate',
	QUICKENTRY_CHECK_ISSUANCE_READ = 'quickentry-check-issuance-read',
	QUICKENTRY_CHECK_ISSUANCE_WRITE = 'quickentry-check-issuance-write',
	QUICKENTRY_CHECK_ISSUANCE_VALIDATE = 'quickentry-check-issuance-validate',
	QUICKENTRY_CHECK_REMITTANCE_READ = 'quickentry-check-remittance-read',
	QUICKENTRY_CHECK_REMITTANCE_WRITE = 'quickentry-check-remittance-write',
	QUICKENTRY_CHECK_REMITTANCE_VALIDATE = 'quickentry-check-remittance-validate',
	QUICKENTRY_SETTING = 'quickentry-setting',
	QUICKENTRY_VALIDATE = 'quickentry-validate',

	// SIGN
	SIGN_ACCESS = 'sign-access',
	SIGN_PROCEDURE_WRITE = 'sign-procedure-write',
	SIGN_CONTACT_WRITE = 'sign-contact-write',

	// BROCHURE
	BROCHURE_GENERAL = 'brochure-general',
	BROCHURE_LEGAL = 'brochure-legal',
	BROCHURE_STATISTICS = 'brochure-statistics',
	BROCHURE_INVOICING = 'brochure-invoicing',
	BROCHURE_WRITE = 'brochure-write',
	BROCHURE_VALIDATE = 'brochure-validate',
	BROCHURE_SIGN = 'brochure-sign',
}
