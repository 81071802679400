import { ApolloError, NetworkStatus, useLazyQuery, useMutation } from '@apollo/client';
import {
	Chip,
	ConfirmationDialog,
	DialogProps,
	Icon,
	IconButton,
	TableColumnType,
	TableInstance,
} from '@elipssolution/harfang';
import { mdiDelete } from '@mdi/js';
import { useCallback, useEffect, useMemo, useState } from 'react';

import AddUserCustomerFileRelationsForm from './AddUserCustomerFileRelationsForm';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	FETCH_INTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_USER,
	FetchInternalUserCustomerFileRelationsByUserType,
	REMOVE_INTERNAL_USER_CUSTOMER_FILE_RELATION,
} from '../../../api/internalUserCustomerFile';
import { InternalUserCustomerFileRelationType } from '../../../types/relation';
import { AddInternalUserRelationWithCustomerFilesType, AddInternalUserType, UserType } from '../../../types/user';
import { useUserCustomerFileRelationContext } from '../../UserCustomerFileRelationProvider';

type CustomerFileTableProps = {
	isAdministrator?: boolean;
	user: UserType;
	tableInstance: TableInstance;
};

const defaultConfirmationDialogDetails = {
	dialogErrorMessage: undefined,
	isOpen: false,
	relationId: '',
	customerFileId: '',
};

const CustomerFilesByUserTable = ({ isAdministrator, user, tableInstance }: CustomerFileTableProps) => {
	const { push } = useSettingsDialog();
	const { userCustomerFileRelations, setUserCustomerFileRelations } = useUserCustomerFileRelationContext();

	const { id: userId } = user;

	const [{ dialogErrorMessage, isOpen, relationId, customerFileId }, setConfirmationDialogDetails] = useState<{
		dialogErrorMessage?: string;
		isOpen: boolean;
		relationId: string;
		customerFileId: CustomerFileType['id'];
	}>(defaultConfirmationDialogDetails);

	const [fetchCustomerFilesByUser, { networkStatus, refetch }] =
		useLazyQuery<FetchInternalUserCustomerFileRelationsByUserType>(
			FETCH_INTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_USER,
			{
				notifyOnNetworkStatusChange: true,
				variables: {
					userId,
				},
			},
		);

	const closeConfirmationDialog = () => setConfirmationDialogDetails(defaultConfirmationDialogDetails);

	const openConfirmationDialog = useCallback(
		(relationIdSelected: string, customerFileIdSelected: CustomerFileType['id']) =>
			setConfirmationDialogDetails({
				isOpen: true,
				relationId: relationIdSelected,
				customerFileId: customerFileIdSelected,
			}),
		[],
	);

	const [
		removeInternalUserCustomerFileRelation,
		{
			loading: isRemoveInternalUserCustomerFileRelationLoading,
			called: isRemoveInternalUserCustomerFileRelationCalled,
			reset: resetRemoveInternalUserCustomerFileRelation,
		},
	] = useMutation(REMOVE_INTERNAL_USER_CUSTOMER_FILE_RELATION, {
		onCompleted: () => {
			closeConfirmationDialog();

			if (userCustomerFileRelations.length > 0) {
				setUserCustomerFileRelations((prevValues) => {
					if ('user' in prevValues[0]) {
						return (prevValues as AddInternalUserRelationWithCustomerFilesType[]).map(
							({ user: prevUser, customerFiles: prevCustomerFiles }) =>
								prevUser.id === userId
									? {
											user: prevUser,
											customerFiles: prevCustomerFiles.filter(({ id }) => id !== customerFileId),
									  }
									: { user: prevUser, customerFiles: prevCustomerFiles },
						);
					}

					return (prevValues as AddInternalUserType[]).filter(({ id: prevValueId }) => prevValueId !== userId);
				});
			}
		},
		onError: (error: ApolloError) =>
			setConfirmationDialogDetails((prevValue) => ({
				...prevValue,
				dialogErrorMessage: generateErrorInformations({ error, resource: 'removeInternalUserCustomerFileRelation' })
					?.message,
			})),
	});

	const columns: TableColumnType<InternalUserCustomerFileRelationType>[] = useMemo(
		() => [
			{
				flexGrow: 0,
				key: 'code',
				render: ({ customerFile: { code } }) => code,
				title: 'Code',
				width: 100,
			},
			{
				key: 'name',
				render: ({ customerFile: { name } }) => name,
				title: 'Nom du dossier',
				width: 400,
			},
			{
				key: 'chip',
				render: ({
					customerFile: {
						domain: { isDefault, name },
					},
				}) => <Chip label={name} color={isDefault ? 'info' : 'default'} />,
				title: 'Domaine',
				width: 150,
			},
			...(!isAdministrator
				? [
						{
							align: 'center',
							key: 'actions',
							flexGrow: 0,
							render: ({ id, customerFile: { id: customerFileIdSelected } }) => (
								<IconButton onClick={() => openConfirmationDialog(id, customerFileIdSelected)}>
									<Icon path={mdiDelete} />
								</IconButton>
							),
							width: 40,
						} as TableColumnType<InternalUserCustomerFileRelationType>,
				  ]
				: []),
		],
		[isAdministrator, openConfirmationDialog],
	);

	const deleteInternalUserCustomerFileRelation = useCallback(
		(internalUserCustomerFileRelationId: string) =>
			removeInternalUserCustomerFileRelation({
				variables: {
					removeInternalUserCustomerFileRelationId: internalUserCustomerFileRelationId,
				},
			}),
		[removeInternalUserCustomerFileRelation],
	);

	const customerFileByUserDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: InternalUserCustomerFileRelationType[];
		}> => {
			const { data, error } = await fetchCustomerFilesByUser({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				internalUserCustomerFileRelationsByUser: { count = 0, items = [] },
			} = data ?? {
				internalUserCustomerFileRelationsByUser: {},
			};

			isRemoveInternalUserCustomerFileRelationCalled && resetRemoveInternalUserCustomerFileRelation();

			return {
				count,
				items,
			};
		},
		[
			fetchCustomerFilesByUser,
			isRemoveInternalUserCustomerFileRelationCalled,
			resetRemoveInternalUserCustomerFileRelation,
		],
	);

	const handleDeleteInternalUserCustomerFileRelation = useCallback(
		() => deleteInternalUserCustomerFileRelation(relationId),
		[deleteInternalUserCustomerFileRelation, relationId],
	);

	const handleRelationUserIdSelection = useCallback(
		() => push(<AddUserCustomerFileRelationsForm user={user} />),
		[push, user],
	);

	// Reload the table if the query has been refetched
	useEffect(() => {
		if (networkStatus === NetworkStatus.refetch) {
			(async () => {
				await refetch();
				tableInstance.reload();
			})().catch((e) => {
				throw e;
			});
		}
	}, [tableInstance, networkStatus, refetch]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveInternalUserCustomerFileRelationLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				loading: isRemoveInternalUserCustomerFileRelationLoading,
				persistantErrorMessage: dialogErrorMessage,
				onClick: handleDeleteInternalUserCustomerFileRelation,
				color: 'error',
				label: 'Supprimer',
				variant: 'contained',
			},
		],
		[isRemoveInternalUserCustomerFileRelationLoading, dialogErrorMessage, handleDeleteInternalUserCustomerFileRelation],
	);

	return (
		<>
			<SettingsTable<InternalUserCustomerFileRelationType>
				addButtonLabel={!isAdministrator ? 'Ajout de dossiers' : null}
				dataSource={customerFileByUserDataSource}
				onAddButtonClick={!isAdministrator ? handleRelationUserIdSelection : undefined}
				table={tableInstance}
				tableColumns={columns}
				title="Dossiers"
			/>

			<ConfirmationDialog
				actionsDialog={actionsDialog}
				maxWidth={false}
				onClose={closeConfirmationDialog}
				open={isOpen}
				title="Êtes-vous sûr de vouloir supprimer la relation utilisateur / dossier client ?"
			/>
		</>
	);
};

export default CustomerFilesByUserTable;
