import { useQuery } from '@apollo/client';
import { SettingsGroup, SettingsItemNavigation } from '@elipssolution/harfang';
import { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { CustomerFileType } from '../../../types/customerFile';
import { DomainType } from '../../../types/domain';
import { ModuleCodeEnum, WidgetTemplateType, WidgetWithKeyType } from '../../../types/widget';
import { WidgetEnum } from '../../../utils/Widget';
import { FETCH_SETTING_MODULES, FetchSettingModulesType } from '../../api/settingModule';
import {
	FETCH_SETTING_CUSTOMER_FILES_MODULES_ENABLED_BY_DOMAIN,
	FETCH_SETTING_MODULES_AVAILABILITY_BY_CUSTOMER_FILE,
	FetchSettingCustomerFilesModulesEnabledByDomainType,
	FetchSettingModulesAvailabilityByCustomerFileType,
} from '../../api/settingModuleAvailability';
import { widgetsByModule } from '../../widgets/widgets';

const filterWidgets = ({
	hasSimulators,
	moduleWidgets,
}: {
	moduleWidgets: WidgetTemplateType[];
	hasSimulators: boolean;
}) => (hasSimulators ? moduleWidgets.filter(({ type }) => type !== WidgetEnum.SIMULATORS) : moduleWidgets);

type SettingsWidgetSelectorProps = {
	customerFileId?: CustomerFileType['id'];
	domainId?: DomainType['id'];
	onAddWidget: (widgetWithKey: WidgetWithKeyType) => void;
	onBack: () => void;
	widgetsWithKey: WidgetWithKeyType[];
};

const SettingsWidgetSelector = ({
	customerFileId,
	domainId,
	onAddWidget,
	onBack,
	widgetsWithKey,
}: SettingsWidgetSelectorProps) => {
	const { data: customerFileModuleAvailabilitiesData } = useQuery<FetchSettingModulesAvailabilityByCustomerFileType>(
		FETCH_SETTING_MODULES_AVAILABILITY_BY_CUSTOMER_FILE,
		{
			skip: !customerFileId,
			variables: { customerFileId },
		},
	);

	const { data: settingCustomerFilesModulesEnabledByDomainData } =
		useQuery<FetchSettingCustomerFilesModulesEnabledByDomainType>(
			FETCH_SETTING_CUSTOMER_FILES_MODULES_ENABLED_BY_DOMAIN,
			{
				skip: !domainId,
				variables: {
					domainId,
				},
			},
		);

	const { data: settingModulesData } = useQuery<FetchSettingModulesType>(FETCH_SETTING_MODULES, {
		skip: !!customerFileId,
	});

	const settingModuleCodes = useMemo(() => {
		if (customerFileId) {
			return (
				customerFileModuleAvailabilitiesData?.settingModuleAvailabilityByCustomerFile.flatMap(
					({ isEnabled, moduleCode }) => (isEnabled ? [moduleCode] : []),
				) ?? []
			);
		}

		const modules = settingModulesData?.settingModules || [];

		const availableModuleCodes =
			settingCustomerFilesModulesEnabledByDomainData?.settingCustomerFilesModulesEnabledByDomain.flatMap(
				({ customerFileIds, moduleId }) =>
					customerFileIds.length > 0 ? [modules.find(({ id }) => id === moduleId)?.code] : [],
			) ?? [];

		if (!availableModuleCodes.includes('general')) {
			availableModuleCodes.push('general');
		}

		return availableModuleCodes;
	}, [
		customerFileId,
		customerFileModuleAvailabilitiesData?.settingModuleAvailabilityByCustomerFile,
		settingCustomerFilesModulesEnabledByDomainData?.settingCustomerFilesModulesEnabledByDomain,
		settingModulesData?.settingModules,
	]);

	const handleClick = useCallback(
		({
			name,
			type,
			minW,
			minH,
			moduleCode,
			isAvailableOnDesktopFormat,
			isAvailableOnMobileFormat,
		}: WidgetTemplateType) => {
			const nextY = widgetsWithKey.reduce((acc, { widget: { y, height } }) => {
				const bottomY = y + height;
				return bottomY > acc ? bottomY : acc;
			}, 0);

			const createdWidget: WidgetWithKeyType = {
				key: uuid(),
				widget: {
					name,
					type,
					x: 0,
					y: nextY,
					width: minW,
					height: minH,
					moduleCode,
					isAvailableOnDesktopFormat,
					isAvailableOnMobileFormat,
				},
			};

			onAddWidget(createdWidget);
		},
		[onAddWidget, widgetsWithKey],
	);

	const filteredWidgetsByModule = useMemo(() => {
		const hasSimulators = widgetsWithKey.some(({ widget }) => widget.type === WidgetEnum.SIMULATORS);

		const moduleCodesWithGeneral = settingModuleCodes.includes(ModuleCodeEnum.GENERAL)
			? settingModuleCodes
			: [...settingModuleCodes, ModuleCodeEnum.GENERAL];

		return widgetsByModule.flatMap(({ module: { code, name }, widgets: moduleWidgets }) =>
			moduleCodesWithGeneral.includes(code)
				? [
						<SettingsGroup key={code} label={name}>
							{filterWidgets({ moduleWidgets, hasSimulators })?.map((widgetTemplate) => (
								<SettingsItemNavigation
									key={widgetTemplate.type}
									label={widgetTemplate.name}
									onClick={() => handleClick(widgetTemplate)}
								/>
							))}
						</SettingsGroup>,
				  ]
				: [],
		);
	}, [handleClick, settingModuleCodes, widgetsWithKey]);

	return (
		<SettingsDialogPage title="Ajout d'un widget" onBack={onBack}>
			{filteredWidgetsByModule}
		</SettingsDialogPage>
	);
};

export default SettingsWidgetSelector;
