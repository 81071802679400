import { SVGProps } from 'react';

const MarginCoefficientSVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 24 24" height={60} width={60} {...props}>
		<path d="M11.695 12.474h8.123c.145-1.92-.923-4.647-2.203-5.92-1.273-1.273-3.826-2.34-5.92-2.377m0 18.167c-1.38 0-2.68-.263-3.9-.79a10.01 10.01 0 0 1-3.17-2.13c-.9-.9-1.614-1.96-2.14-3.18a9.688 9.688 0 0 1-.79-3.9c0-1.386.263-2.686.79-3.9a10.15 10.15 0 0 1 2.14-3.17c.9-.9 1.956-1.613 3.17-2.14a9.734 9.734 0 0 1 3.9-.79c1.38 0 2.676.264 3.89.79a10.218 10.218 0 0 1 3.17 2.15 9.994 9.994 0 0 1 2.15 3.17c.526 1.214.79 2.51.79 3.89 0 1.367-.264 2.66-.79 3.88a10.13 10.13 0 0 1-2.13 3.18c-.9.914-1.96 1.63-3.18 2.15a9.688 9.688 0 0 1-3.9.79z" />
		<path
			d="M11.7 2.328h10.003v10.141H11.7z"
			style={{
				fill: '#fff',
				fillOpacity: 1,
				strokeWidth: 0.479359,
			}}
		/>
		<path d="M13.463 1.44v9h9c-.5-4.8-4.2-8.5-9-9" />
		<path
			d="m18.661 4.942-3.529 3.53.47.47 3.53-3.53m-3.176-.352a.706.706 0 0 0-.706.706.706.706 0 0 0 .706.705.706.706 0 0 0 .705-.705.706.706 0 0 0-.705-.706m2.353 2.353a.706.706 0 0 0-.706.705.706.706 0 0 0 .706.706.706.706 0 0 0 .705-.706.706.706 0 0 0-.705-.705z"
			style={{
				fill: '#fff',
				fillOpacity: 1,
				strokeWidth: 0.235294,
			}}
		/>
	</svg>
);
export default MarginCoefficientSVG;
