import { Button } from '@elipssolution/harfang';
import { lighten, styled, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import clsx from 'clsx';

import Banner from './Banner';
import { useSession } from './SessionProvider';
import useCustomRouter from '../hooks/useCustomRouter';

const ImpersonateBannerContainer = styled(Banner)({
	backgroundColor: lighten(purple['700'], 0.9),
	color: purple['700'],

	'&.fixed': {
		position: 'fixed',
		height: 'fit-content',

		zIndex: 10,
	},
});

const ImpersonateBanner = () => {
	const { pathname } = useCustomRouter();
	const { impersonate, impersonatedUser } = useSession();
	const { firstName, lastName } = impersonatedUser ?? {};

	const isOnErrorPage = pathname.startsWith('/error');

	return lastName ? (
		<ImpersonateBannerContainer className={clsx({ fixed: isOnErrorPage })}>
			<Typography>
				Vous utilisez actuellement l&apos;application avec le profil
				<strong> {`${firstName ?? ''} ${lastName}`.trim()}</strong>
			</Typography>
			<Button variant="outlined" color="inherit" size="small" onClick={() => impersonate()}>
				Quitter
			</Button>
		</ImpersonateBannerContainer>
	) : null;
};

export default ImpersonateBanner;
