import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
	Button,
	Icon,
	SettingsGroup,
	SettingsItemAutocomplete,
	SettingsItemCheckbox,
	SettingsItemTextField,
	useAutocomplete,
	useTable,
} from '@elipssolution/harfang';
import { mdiAlertCircle, mdiPlus } from '@mdi/js';
import { Stack, Typography, styled } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import AddCustomerFilesTable from './AddCustomerFilesTable';
import AddedUsersTableSection from './AddedUsersTableSection';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	FETCH_INTERNAL_GROUPS,
	FETCH_INTERNAL_GROUP_BY_DEFAULT,
	FetchInternalGroupByDefaultType,
	FetchInternalGroupsType,
} from '../../../api/internalGroup';
import {
	CREATE_INTERNAL_USER_WITH_CUSTOMER_FILES,
	CreateInternalUserWithCustomerFilesType,
	REMOVE_INTERNAL_USER,
} from '../../../api/internalUser';
import { GroupType } from '../../../types/group';
import {
	AddInternalUserRelationWithCustomerFilesType,
	AddInternalUserType,
	CreateInternalUserFormType,
} from '../../../types/user';
import SettingsItemEmailField from '../../SettingsItemEmailField';
import { useUserCustomerFileRelationContext } from '../../UserCustomerFileRelationProvider';

const ErrorWrapper = styled('div')(({ theme: { spacing, palette, shape } }) => ({
	overflow: 'auto',
	minHeight: 100,
	minWidth: '50%',
	maxWidth: '75%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	padding: spacing(1),
	gap: spacing(1),
	margin: '16px auto 0 auto',
	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

const CreateInternalUserForm = () => {
	const { back } = useSettingsDialog();
	const { userCustomerFileRelations, setUserCustomerFileRelations } = useUserCustomerFileRelationContext();

	const customerFileTableInstance = useTable();
	const groupAutocomplete = useAutocomplete();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<CreateInternalUserFormType>({
		mode: 'onChange',
	});
	const [isCreateInternalUserWithCustomerFilesSucceeded, setIsCreateInternalUserWithCustomerFilesSucceeded] =
		useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [selectedCustomerFiles, setSelectedCustomerFiles] = useState<CustomerFileType[]>([]);
	const [internalUsersWithCustomerFileToAdd, setInternalUsersWithCustomerFileToAdd] = useState(
		userCustomerFileRelations as AddInternalUserRelationWithCustomerFilesType[],
	);

	const { data: internalGroupByDefaultData, loading: isInternalGroupByDefaultLoading } =
		useQuery<FetchInternalGroupByDefaultType>(FETCH_INTERNAL_GROUP_BY_DEFAULT);

	const [createInternalUserWithCustomerFiles, { loading: isCreateInternalUserLoading }] =
		useMutation<CreateInternalUserWithCustomerFilesType>(CREATE_INTERNAL_USER_WITH_CUSTOMER_FILES, {
			onCompleted: () => {
				setIsCreateInternalUserWithCustomerFilesSucceeded(true);
				setTimeout(() => setIsCreateInternalUserWithCustomerFilesSucceeded(false), 3000);
			},
			onError: (error) => {
				setErrorMessage(generateErrorInformations({ error, resource: 'createInternalUserWithCustomerFiles' }).message);
			},
		});

	const [removeInternalUser, { loading: isRemoveInternalUserLoading }] = useMutation(REMOVE_INTERNAL_USER);

	const [fetchInternalGroups, { loading: isInternalGroupsLoading }] =
		useLazyQuery<FetchInternalGroupsType>(FETCH_INTERNAL_GROUPS);

	const internalGroupDataSource = useCallback(
		async (limit: number, offset: number, search?: string) => {
			const { data, error } = await fetchInternalGroups({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			if (!data) {
				throw new Error('Aucune donnée reçue');
			}

			return data.internalGroups;
		},
		[fetchInternalGroups],
	);

	const isLoading = useMemo(
		() =>
			isInternalGroupsLoading ||
			isInternalGroupByDefaultLoading ||
			isCreateInternalUserLoading ||
			isRemoveInternalUserLoading,
		[
			isCreateInternalUserLoading,
			isInternalGroupByDefaultLoading,
			isInternalGroupsLoading,

			isRemoveInternalUserLoading,
		],
	);

	const isMutationButtonDisabled = useMemo(() => isLoading || !isValid || !isDirty, [isDirty, isLoading, isValid]);

	const handleResetForm = useCallback(() => {
		reset({
			lastName: undefined,
			firstName: undefined,
			email: undefined,
			group: internalGroupByDefaultData?.internalGroupByDefault,
		});

		groupAutocomplete.reload();
	}, [groupAutocomplete, internalGroupByDefaultData?.internalGroupByDefault, reset]);

	const handleSelectedCustomerFilesChange = (newSelectedCustomerFiles: CustomerFileType[]) =>
		setSelectedCustomerFiles(newSelectedCustomerFiles);

	const onSubmit = useCallback(
		({
			email: formEmail,
			lastName: formLastName,
			firstName: formFirstName,
			group: formGroup,
			isUserCreatedWithBrochurePermissionsFromInternalContactRoles:
				formIsUserCreatedWithBrochurePermissionsFromInternalContactRoles,
			isBillingManager: formIsBillingManager,
		}: CreateInternalUserFormType) => {
			return createInternalUserWithCustomerFiles({
				variables: {
					createInternalUserWithCustomerFilesInput: {
						customerFileIds: selectedCustomerFiles.map(({ id }) => id),
						internalUser: {
							email: formEmail,
							lastName: formLastName,
							firstName: formFirstName || null,
							groupId: formGroup?.id,
							isUserCreatedWithBrochurePermissionsFromInternalContactRoles:
								formIsUserCreatedWithBrochurePermissionsFromInternalContactRoles,
							isBillingManager: formIsBillingManager,
						},
					},
				},
			})
				.then(({ data }) => {
					if (!data) return;

					const { id } = data.createInternalUserWithCustomerFiles.internalUser;

					if (formEmail && formFirstName && formGroup && formLastName) {
						setInternalUsersWithCustomerFileToAdd((prevInternalUser) => [
							...prevInternalUser,
							{
								user: {
									id,
									email: formEmail,
									lastName: formLastName,
									firstName: formFirstName,
									group: formGroup,
									isBillingManager: formIsBillingManager,
								},
								customerFiles: selectedCustomerFiles,
							},
						]);
						handleSelectedCustomerFilesChange([]);
						customerFileTableInstance.reload();
						handleResetForm();
					}
				})
				.catch((mutationError: ApolloError) =>
					setErrorMessage(
						generateErrorInformations({ error: mutationError, resource: 'createInternalUserWithCustomerFiles' })
							.message,
					),
				);
		},
		[createInternalUserWithCustomerFiles, selectedCustomerFiles, customerFileTableInstance, handleResetForm],
	);

	const handleRemoveInternalUser = useCallback(
		async (internalUserId: AddInternalUserType['id']) => {
			await removeInternalUser({
				variables: {
					removeInternalUserId: internalUserId,
				},
			});

			setInternalUsersWithCustomerFileToAdd((prevInternalUsers) => {
				const internalUsersFiltered = prevInternalUsers.filter(({ user: { id } }) => id !== internalUserId);

				setUserCustomerFileRelations(internalUsersFiltered);

				return internalUsersFiltered;
			});
		},
		[removeInternalUser, setUserCustomerFileRelations],
	);

	const handleBack = useCallback(() => {
		if (userCustomerFileRelations.length > 0) {
			setUserCustomerFileRelations([]);
		}
		back();
	}, [back, setUserCustomerFileRelations, userCustomerFileRelations.length]);

	useEffect(() => {
		if (internalUsersWithCustomerFileToAdd.length > 0) {
			setUserCustomerFileRelations(internalUsersWithCustomerFileToAdd);
		}
	}, [internalUsersWithCustomerFileToAdd, setUserCustomerFileRelations]);

	return (
		<SettingsDialogPage title="Ajout d'utilisateurs" onBack={handleBack}>
			<SettingsGroup>
				<Controller
					name="lastName"
					control={control}
					render={({ field: { onChange, ...field } }) => {
						const handleUpperCaseChange = (value?: string) => {
							onChange(value?.toUpperCase());
						};

						return (
							<SettingsItemTextField
								{...field}
								description="Nom de l'utilisateur."
								label="Nom"
								onChange={handleUpperCaseChange}
								required
							/>
						);
					}}
					rules={{ required: true }}
				/>
				<Controller
					name="firstName"
					control={control}
					render={({ field: { onChange, ...field } }) => {
						const handleFirstLetterUpperCaseChange = (value?: string) => {
							onChange(value ? value.charAt(0).toUpperCase() + value.slice(1) : undefined);
						};

						return (
							<SettingsItemTextField
								{...field}
								description="Prénom de l'utilisateur."
								label="Prénom"
								onChange={handleFirstLetterUpperCaseChange}
							/>
						);
					}}
				/>
				<SettingsItemEmailField<CreateInternalUserFormType> control={control} />
				<Controller
					name="group"
					control={control}
					render={({ field }) => (
						<SettingsItemAutocomplete<GroupType>
							{...field}
							autocomplete={groupAutocomplete}
							dataSource={internalGroupDataSource}
							description="Groupe attaché à l'utilisateur."
							getOptionLabel={(option) => option.name}
							label="Groupe"
							disableClearable
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="isUserCreatedWithBrochurePermissionsFromInternalContactRoles"
					defaultValue={false}
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Utiliser les informations de l'ERP comptable pour la création des droits de Process Plaquette."
							label="Droits Process Plaquette de l'ERP comptable"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
				<Controller
					name="isBillingManager"
					defaultValue={false}
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description=""
							label="Est responsable facturation"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<AddCustomerFilesTable
				selectedCustomerFiles={selectedCustomerFiles}
				onSelectedCustomerFilesChange={handleSelectedCustomerFilesChange}
				tableInstance={customerFileTableInstance}
			/>

			<Stack flexDirection="row-reverse" justifyContent="space-between">
				<Button
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={<Icon path={mdiPlus} />}
					variant="contained"
				>
					{isCreateInternalUserWithCustomerFilesSucceeded ? 'Utilisateur ajouté' : 'Ajouter'}
				</Button>

				{errorMessage && (
					<ErrorWrapper>
						<Icon path={mdiAlertCircle} />
						<Typography>{errorMessage}</Typography>
					</ErrorWrapper>
				)}
			</Stack>

			<AddedUsersTableSection users={internalUsersWithCustomerFileToAdd} onRemoveUser={handleRemoveInternalUser} />
		</SettingsDialogPage>
	);
};

export default CreateInternalUserForm;
