import { useMutation } from '@apollo/client';
import { Button, Icon, SettingsGroup, SettingsItemTextField } from '@elipssolution/harfang';
import { mdiAlertCircle, mdiPencil, mdiPlus } from '@mdi/js';
import { Stack, styled, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import CurrentSynchronizationJournalExclusionSettingSection from './CurrentSynchronizationJournalExclusionSettingSection';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { CustomerFileType } from '../../../../types/customerFile';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	CREATE_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING,
	CreateSynchronizationJournalExclusionSettingType,
	CreateSynchronizationJournalExclusionSettingVariablesType,
	REMOVE_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING,
	RemoveSynchronizationJournalExclusionSetting,
	RemoveSynchronizationJournalExclusionSettingVariablesType,
	UPDATE_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING,
	UpdateSynchronizationJournalExclusionSettingType,
	UpdateSynchronizationJournalExclusionSettingVariablesType,
} from '../../../api/synchronizationJournalExclusionSetting';
import { SynchronizationJournalExclusionSetting } from '../../../types/synchronizationJournalExclusionSetting';

const ErrorWrapper = styled('div')(({ theme: { spacing, palette, shape } }) => ({
	overflow: 'auto',
	minHeight: 100,
	minWidth: '50%',
	maxWidth: '75%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	padding: spacing(1),
	gap: spacing(1),
	margin: spacing(2, 'auto', 0, 'auto'),
	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormType = {
	code: SynchronizationJournalExclusionSetting['code'];
	label: SynchronizationJournalExclusionSetting['label'];
};

const SynchronizationJournalExclusionSettingForm = ({
	customerFileId,
	synchronizationJournalExclusionSetting,
}: {
	customerFileId: CustomerFileType['id'];
	synchronizationJournalExclusionSetting?: SynchronizationJournalExclusionSetting;
}) => {
	const { back } = useSettingsDialog();

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>({
		mode: 'onChange',
	});
	const [isCreateSynchronizationJournalExclusionSucceeded, setIsCreateSynchronizationJournalExclusionSucceeded] =
		useState(false);

	const [isUpdateSynchronizationJournalExclusionSucceeded, setIsUpdateSynchronizationJournalExclusionSucceeded] =
		useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();

	const [currentSynchronizationJournalExclusions, setCurrentSynchronizationJournalExclusions] = useState<
		SynchronizationJournalExclusionSetting[]
	>([]);

	const [synchronizationJournalExclusionToUpdate, setSynchronizationJournalExclusionToUpdate] = useState<
		SynchronizationJournalExclusionSetting | undefined
	>(synchronizationJournalExclusionSetting);

	const handleResetForm = useCallback(() => {
		reset({
			code: undefined,
			label: undefined,
		});
	}, [reset]);

	const [createSynchronizationJournalExclusion, { loading: isCreateSynchronizationJournalExclusionLoading }] =
		useMutation<
			CreateSynchronizationJournalExclusionSettingType,
			CreateSynchronizationJournalExclusionSettingVariablesType
		>(CREATE_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING, {
			onError: (error) => {
				setErrorMessage(
					generateErrorInformations({ error, resource: 'createSynchronizationJournalExclusionSetting' }).message,
				);
			},
		});

	const [updateSynchronizationJournalExclusion, { loading: isUpdateSynchronizationJournalExclusionLoading }] =
		useMutation<
			UpdateSynchronizationJournalExclusionSettingType,
			UpdateSynchronizationJournalExclusionSettingVariablesType
		>(UPDATE_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING, {
			onCompleted: () => {
				setIsUpdateSynchronizationJournalExclusionSucceeded(true);
				setTimeout(() => setIsUpdateSynchronizationJournalExclusionSucceeded(false), 3000);
			},
			onError: (error) => {
				setErrorMessage(
					generateErrorInformations({ error, resource: 'updateSynchronizationJournalExclusionSetting' }).message,
				);
			},
		});

	const [removeSynchronizationJournalExclusionSetting, { loading: isRemoveSynchronizationJournalExclusionLoading }] =
		useMutation<
			RemoveSynchronizationJournalExclusionSetting,
			RemoveSynchronizationJournalExclusionSettingVariablesType
		>(REMOVE_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING);

	const isMutationButtonDisabled = useMemo(
		() =>
			isCreateSynchronizationJournalExclusionLoading ||
			isUpdateSynchronizationJournalExclusionLoading ||
			!isValid ||
			!isDirty,
		[isCreateSynchronizationJournalExclusionLoading, isDirty, isUpdateSynchronizationJournalExclusionLoading, isValid],
	);

	const onSubmit = useCallback(
		async ({ code: formCode, label: formLabel }: FormType) => {
			if (!synchronizationJournalExclusionToUpdate?.id) {
				const { data } = await createSynchronizationJournalExclusion({
					variables: {
						createSynchronizationJournalExclusionSettingInput: {
							code: formCode,
							label: formLabel,
							customerFileId,
						},
					},
				});

				if (data?.createSynchronizationJournalExclusionSetting.id) {
					setCurrentSynchronizationJournalExclusions((previousExclusions) => [
						...previousExclusions,
						{
							id: data.createSynchronizationJournalExclusionSetting.id,
							code: formCode,
							label: formLabel,
						},
					]);
					handleResetForm();
					setIsCreateSynchronizationJournalExclusionSucceeded(true);
					setTimeout(() => setIsCreateSynchronizationJournalExclusionSucceeded(false), 3000);
				}
			} else {
				await updateSynchronizationJournalExclusion({
					variables: {
						updateSynchronizationJournalExclusionSettingInput: {
							id: synchronizationJournalExclusionToUpdate.id,
							label: formLabel,
						},
					},
				});
			}
		},
		[
			synchronizationJournalExclusionToUpdate,
			createSynchronizationJournalExclusion,
			customerFileId,
			updateSynchronizationJournalExclusion,
			handleResetForm,
		],
	);

	const handleRemoveSynchronizationJournalExclusionSetting = useCallback(
		async (synchronizationExclusionJournalToRemoveId: SynchronizationJournalExclusionSetting['id']) => {
			await removeSynchronizationJournalExclusionSetting({
				variables: {
					id: synchronizationExclusionJournalToRemoveId,
				},
			});
			if (!synchronizationJournalExclusionToUpdate)
				setCurrentSynchronizationJournalExclusions((previousSynchronizationJournalExclusions) => {
					return previousSynchronizationJournalExclusions.filter(
						({ id: synchronizationJournalExclusionId }) =>
							synchronizationJournalExclusionId !== synchronizationExclusionJournalToRemoveId,
					);
				});
			else back();
		},
		[back, removeSynchronizationJournalExclusionSetting, synchronizationJournalExclusionToUpdate],
	);

	const buttonLabel = useMemo(() => {
		if (!synchronizationJournalExclusionToUpdate)
			return isCreateSynchronizationJournalExclusionSucceeded ? 'Exclusion ajoutée' : 'Ajouter';
		return isUpdateSynchronizationJournalExclusionSucceeded ? 'Exclusion modifiée' : 'Modifier';
	}, [
		isCreateSynchronizationJournalExclusionSucceeded,
		isUpdateSynchronizationJournalExclusionSucceeded,
		synchronizationJournalExclusionToUpdate,
	]);

	useEffect(() => {
		if (synchronizationJournalExclusionToUpdate) {
			reset({
				code: synchronizationJournalExclusionToUpdate.code,
				label: synchronizationJournalExclusionToUpdate.label,
			});
		}
	}, [reset, synchronizationJournalExclusionToUpdate]);

	return (
		<SettingsDialogPage
			title={synchronizationJournalExclusionToUpdate?.label ?? "Ajout de l'exclusion d'un journal"}
			onBack={back}
		>
			<SettingsGroup>
				<Controller
					name="code"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField
							{...field}
							description="Code du journal à exclure."
							label="Code"
							disabled={!!synchronizationJournalExclusionToUpdate}
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="label"
					control={control}
					rules={{ required: true }}
					render={({ field }) => (
						<SettingsItemTextField {...field} description="Libellé du journal." label="Libellé" required />
					)}
				/>
			</SettingsGroup>

			<Stack flexDirection="row-reverse" justifyContent="space-between">
				<Button
					success={
						synchronizationJournalExclusionToUpdate
							? isUpdateSynchronizationJournalExclusionSucceeded
							: isCreateSynchronizationJournalExclusionSucceeded
					}
					loading={
						synchronizationJournalExclusionToUpdate
							? isUpdateSynchronizationJournalExclusionLoading
							: isCreateSynchronizationJournalExclusionLoading
					}
					disabled={isMutationButtonDisabled}
					onClick={handleSubmit(onSubmit)}
					startIcon={<Icon path={!synchronizationJournalExclusionToUpdate ? mdiPlus : mdiPencil} />}
					variant="contained"
				>
					{buttonLabel}
				</Button>
				{synchronizationJournalExclusionToUpdate && (
					<Button
						loading={isRemoveSynchronizationJournalExclusionLoading}
						color="error"
						onClick={() =>
							handleRemoveSynchronizationJournalExclusionSetting(synchronizationJournalExclusionToUpdate.id)
						}
						variant="outlined"
					>
						Supprimer
					</Button>
				)}

				{errorMessage && (
					<ErrorWrapper>
						<Icon path={mdiAlertCircle} />
						<Typography>{errorMessage}</Typography>
					</ErrorWrapper>
				)}
			</Stack>

			{!synchronizationJournalExclusionToUpdate && (
				<CurrentSynchronizationJournalExclusionSettingSection
					synchronizationJournalExclusions={currentSynchronizationJournalExclusions}
					onRemoveSynchronizationJournalExclusion={handleRemoveSynchronizationJournalExclusionSetting}
					onSynchronizationJournalExclusionToUpdateChange={setSynchronizationJournalExclusionToUpdate}
				/>
			)}
		</SettingsDialogPage>
	);
};

export default SynchronizationJournalExclusionSettingForm;
