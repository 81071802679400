import { ApolloError } from '@apollo/client';
import { Button, CircularProgress, ConfirmationDialog, ConfirmationDialogProps, Icon } from '@elipssolution/harfang';
import { mdiPencil, mdiPlus } from '@mdi/js';
import { Stack, styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import ConfigurationForm from './ConfigurationForm';
import ExclusionsForm from './ExclusionsForm';
import FoldersTreeForm from './FoldersTreeForm';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { DIALOG_CLOSE_DELAY } from '../../../../src/utils/dialogCloseDelay';
import { generateErrorInformations } from '../../../../utils/errorHandler';
import {
	CreateStorageDownloadType,
	FetchStorageDownloadType,
	RemoveStorageDownloadType,
	UpdateStorageDownloadType,
} from '../../api/settingStorageDownload';
import useDocumentConnector from '../../hooks/useDocumentConnector';
import useSettingStorageDownload from '../../hooks/useSettingStorageDownload';
import useSettingStorageDownloadMutations from '../../hooks/useSettingStorageDownloadMutations';
import { ConnectorType } from '../../types/connector';
import { SettingStorageDownloadType } from '../../types/settingStorage';
import { StorageDownloadFormType } from '../../types/storageDownloadFormType';

const ErrorWrapper = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	height: 36,
	padding: spacing(0, 2),

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

const isMutationCreate = (
	data: CreateStorageDownloadType | UpdateStorageDownloadType | RemoveStorageDownloadType,
): data is CreateStorageDownloadType => 'document_createSettingStorageDownload' in data;
const isMutationRemove = (
	data: CreateStorageDownloadType | UpdateStorageDownloadType | RemoveStorageDownloadType,
): data is RemoveStorageDownloadType => 'document_removeSettingStorageDownload' in data;

enum FormStateEnum {
	IDLE,
	INITIAL_LOADING,
	INITIAL_LOAD_ERROR,
}

type StorageDownloadFormProps = {
	connectorCode: ConnectorType['code'];
	storageDownloadId?: SettingStorageDownloadType['id'];
};

const StorageDownloadForm = ({ connectorCode, storageDownloadId }: StorageDownloadFormProps) => {
	const { back, replace } = useSettingsDialog();

	const [succeededMutation, setSucceededMutation] = useState<string>();
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
	const [mutationErrorMessage, setMutationErrorMessage] = useState<string>();
	const [removalErrorMessage, setRemovalErrorMessage] = useState<string>();

	const {
		connector,
		loading: isFetchConnectorLoading,
		error: fetchConnectorError,
	} = useDocumentConnector({ variables: { code: connectorCode } });
	const { name: connectorName, folders: connectorFolders = [], parameters: connectorParameters = [] } = connector ?? {};

	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		reset,
	} = useForm<StorageDownloadFormType>({
		defaultValues: {
			exclusions: [{ value: '' }],
			folders: connectorFolders?.reduce((acc, { code }) => {
				acc[code] = '';

				return acc;
			}, {} as Record<string, string>),
		},
	});

	const applyStorageDownloadToForm = useCallback(
		({ document_settingStorageDownload }: FetchStorageDownloadType) => {
			const { configuration, exclusions, folders } = document_settingStorageDownload;

			reset({
				...(exclusions && {
					exclusions: (JSON.parse(exclusions ?? '[]') as string[]).map((exclusion) => ({
						value: exclusion,
					})),
				}),
				configuration: JSON.parse(configuration ?? '{}') as StorageDownloadFormType['configuration'],
				folders: JSON.parse(folders ?? '{}') as StorageDownloadFormType['folders'],
			});
		},
		[reset],
	);

	const { loading: isFetchSettingStorageDownloadLoading, error: fetchSettingStorageDownloadError } =
		useSettingStorageDownload({
			options: { onCompleted: applyStorageDownloadToForm, skip: !storageDownloadId },
			variables: { id: storageDownloadId ?? '' },
		});

	const loadErrorMessage = useMemo(() => {
		if (fetchConnectorError) {
			return generateErrorInformations({ error: fetchConnectorError, resource: 'document_connector' }).message;
		}

		if (fetchSettingStorageDownloadError) {
			return generateErrorInformations({
				error: fetchSettingStorageDownloadError,
				resource: 'document_settingStorageDownload',
			}).message;
		}

		return undefined;
	}, [fetchConnectorError, fetchSettingStorageDownloadError]);

	const formState = useMemo(() => {
		if (isFetchConnectorLoading || isFetchSettingStorageDownloadLoading) {
			return FormStateEnum.INITIAL_LOADING;
		}

		if (fetchConnectorError || fetchSettingStorageDownloadError) {
			return FormStateEnum.INITIAL_LOAD_ERROR;
		}

		return FormStateEnum.IDLE;
	}, [
		isFetchConnectorLoading,
		fetchConnectorError,
		isFetchSettingStorageDownloadLoading,
		fetchSettingStorageDownloadError,
	]);

	const onMutationCompleted = (
		data: CreateStorageDownloadType | UpdateStorageDownloadType | RemoveStorageDownloadType,
	) => {
		if (isMutationRemove(data)) {
			setSucceededMutation('remove');
			setTimeout(() => {
				back();
			}, DIALOG_CLOSE_DELAY);
		} else if (isMutationCreate(data)) {
			setSucceededMutation('create');
			setTimeout(() => {
				replace(
					<StorageDownloadForm
						connectorCode={connectorCode}
						storageDownloadId={data.document_createSettingStorageDownload.id}
					/>,
				);
			}, 3000);
		} else {
			setSucceededMutation('update');
			setTimeout(() => {
				setSucceededMutation(undefined);
			}, 3000);
		}
	};

	const {
		create,
		update,
		remove,
		loading: isMutationLoading,
	} = useSettingStorageDownloadMutations({
		onCompleted: onMutationCompleted,
	});

	const isMutationButtonDisabled = useMemo(
		() =>
			[FormStateEnum.INITIAL_LOADING, FormStateEnum.INITIAL_LOAD_ERROR].includes(formState) ||
			isMutationLoading ||
			!isValid ||
			!isDirty,
		[formState, isDirty, isMutationLoading, isValid],
	);

	const mutationButtonLabel = useMemo(() => {
		if (storageDownloadId) {
			return succeededMutation === 'update' ? 'Configuration modifiée' : 'Modifier';
		}

		return succeededMutation === 'create' ? 'Configuration ajoutée' : 'Ajouter';
	}, [storageDownloadId, succeededMutation]);

	const onSubmit = useCallback(
		(values: StorageDownloadFormType) => {
			const { configuration, folders, exclusions } = values;

			const toSend = {
				connectorCode,
				configuration: JSON.stringify(configuration),
				folders: JSON.stringify(folders),
				...(exclusions && {
					exclusions: JSON.stringify(exclusions.map(({ value }) => value)),
				}),
				...(storageDownloadId && {
					id: storageDownloadId,
				}),
			};

			const modeMutationInfoMap = new Map([
				['create', { mutation: create, mutationName: 'createSettingStorageDownload', inputValues: toSend }],
				[
					'update',
					{
						mutation: update,
						mutationName: 'updateSettingStorageDownload',
						inputValues: { ...toSend, id: storageDownloadId },
					},
				],
			]);

			const { mutation, mutationName = '' } = modeMutationInfoMap.get(storageDownloadId ? 'update' : 'create') ?? {};

			return mutation?.({
				variables: {
					[`${mutationName}Input`]: toSend,
				},
			})
				.then(() => {
					reset(values);
				})
				.catch((error: ApolloError) => {
					setMutationErrorMessage(generateErrorInformations({ error, resource: `document_${mutationName}` }).message);

					throw error;
				});
		},
		[connectorCode, create, reset, storageDownloadId, update],
	);

	const openConfirmationDialog = () => setIsConfirmationDialogOpen(true);
	const closeConfirmationDialog = () => {
		setIsConfirmationDialogOpen(false);
		setMutationErrorMessage(undefined);
	};

	const handleRemoval = useCallback(
		() =>
			remove({ variables: { id: storageDownloadId } }).catch((error: ApolloError) => {
				setRemovalErrorMessage(
					generateErrorInformations({ error, resource: `document_removeSettingStorageDownload` }).message,
				);
			}),
		[remove, storageDownloadId],
	);

	const confirmationDialogActionsDialog: ConfirmationDialogProps['actionsDialog'] = useMemo(
		() => [
			{
				disabled: isMutationLoading,
				label: 'Annuler',
				onClick: closeConfirmationDialog,
			},
			{
				color: 'error',
				label: 'Supprimer',
				loading: isMutationLoading,
				onClick: handleRemoval,
				error: !!removalErrorMessage,
				persistantErrorMessage: removalErrorMessage,
				success: succeededMutation === 'remove',
				variant: 'contained',
			},
		],
		[handleRemoval, isMutationLoading, removalErrorMessage, succeededMutation],
	);

	return (
		<>
			<SettingsDialogPage title={`GED${connectorName ? ` - ${connectorName}` : ''}`}>
				{formState !== FormStateEnum.INITIAL_LOADING && (
					<>
						<ConfigurationForm control={control} connectorParameters={connectorParameters} />
						<FoldersTreeForm control={control} connectorFolders={connectorFolders} />
						<ExclusionsForm control={control} />

						<Stack direction="row-reverse" justifyContent="space-between">
							<Button
								disabled={isMutationButtonDisabled}
								onClick={handleSubmit(onSubmit)}
								startIcon={<Icon path={storageDownloadId ? mdiPencil : mdiPlus} />}
								variant="contained"
							>
								{mutationButtonLabel}
							</Button>

							{(loadErrorMessage || mutationErrorMessage) && (
								<ErrorWrapper>{loadErrorMessage || mutationErrorMessage}</ErrorWrapper>
							)}

							{storageDownloadId && (
								<Button color="error" onClick={openConfirmationDialog} variant="outlined">
									Supprimer
								</Button>
							)}
						</Stack>
					</>
				)}
				{formState === FormStateEnum.INITIAL_LOADING && (
					<Stack alignItems="center" paddingTop={2} gap={2}>
						<CircularProgress color="inherit" />
						Chargement des informations du connecteur et de la consultation GED
					</Stack>
				)}
			</SettingsDialogPage>

			{connectorName && storageDownloadId && (
				<ConfirmationDialog
					open={isConfirmationDialogOpen}
					onClose={closeConfirmationDialog}
					actionsDialog={confirmationDialogActionsDialog}
					title={`Êtes-vous sûr de vouloir supprimer la configuration de consultation GED pour ${connectorName} ?`}
					content={`Les clients des dossiers rattachés à ${connectorName} ne pourront plus accéder à leur GED.`}
				/>
			)}
		</>
	);
};

export default StorageDownloadForm;
