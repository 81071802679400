import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, SettingsGroup, SettingsItemAutocomplete } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { CustomerFileType } from '../../../../../../types/customerFile';
import { generateErrorInformations } from '../../../../../../utils/errorHandler';
import { FETCH_PAYMENT_MODES_BY_CUSTOMER_FILE, FetchPaymentModesByCustomerFileType } from '../../../../api/paymentMode';
import {
	CREATE_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_PAYMENT_MODE,
	CREATE_CUSTOMER_FILE_CHECK_SETTING_PAYMENT_MODE,
	CreateCustomerFileBillExchangeSettingPaymentModeType,
	CreateCustomerFileCheckSettingPaymentModeType,
	FETCH_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_PAYMENT_MODE,
	FETCH_CUSTOMER_FILE_CHECK_SETTING_PAYMENT_MODE,
	FetchCustomerFileBillExchangeSettingPaymentModeType,
	FetchCustomerFileCheckSettingPaymentModeType,
} from '../../../../api/settingPaymentMode';
import { CheckTypeEnum } from '../../../../types/check';
import { PaymentModeType } from '../../../../types/paymentMode';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormFieldType = {
	billExchangePaymentMode?: PaymentModeType;
	checkIssuancePaymentMode?: PaymentModeType;
	checkRemittancePaymentMode?: PaymentModeType;
};

type PaymentModesProps = {
	customerFileId?: CustomerFileType['id'];
};

const PaymentModes = ({ customerFileId }: PaymentModesProps) => {
	const {
		control,
		formState: { dirtyFields, isDirty, isValid },
		handleSubmit,
		setValue,
	} = useForm<FormFieldType>();

	const isFormValid = useMemo(() => isDirty && isValid, [isDirty, isValid]);

	const [error, setError] = useState<string>();
	const [
		isCreateSettingCustomerFileBillExchangePaymentModeSucceeded,
		setIsCreateSettingCustomerFileBillExchangePaymentModeSucceeded,
	] = useState(false);

	const [fetchPaymentModesByCustomerFile] = useLazyQuery<FetchPaymentModesByCustomerFileType>(
		FETCH_PAYMENT_MODES_BY_CUSTOMER_FILE,
	);

	const paymentModesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: PaymentModeType[];
		}> => {
			const { data, error: queryError } = await fetchPaymentModesByCustomerFile({
				variables: {
					customerFileId,
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				paymentModesByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				paymentModesByCustomerFile: {},
			};

			return { count, items };
		},
		[fetchPaymentModesByCustomerFile, customerFileId],
	);

	useQuery<FetchCustomerFileBillExchangeSettingPaymentModeType>(
		FETCH_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_PAYMENT_MODE,
		{
			onCompleted: ({ quickentry_settingCustomerFileBillExchangePaymentMode: { currentPaymentMode } }) =>
				setValue('billExchangePaymentMode', currentPaymentMode ?? undefined),
			onError: (queryError) =>
				setError(
					generateErrorInformations({
						error: queryError,
						resource: 'quickentry_settingCustomerFileBillExchangePaymentMode',
					}).message,
				),
			skip: !customerFileId,
			variables: { id: customerFileId },
		},
	);

	const [createCustomerFileBillExchangePaymentMode] = useMutation<CreateCustomerFileBillExchangeSettingPaymentModeType>(
		CREATE_CUSTOMER_FILE_BILL_EXCHANGE_SETTING_PAYMENT_MODE,
		{
			onCompleted: ({ quickentry_createSettingCustomerFileBillExchangePaymentMode: { currentPaymentMode } }) => {
				setValue('billExchangePaymentMode', currentPaymentMode ?? undefined);
				setIsCreateSettingCustomerFileBillExchangePaymentModeSucceeded(true);
				setTimeout(() => setIsCreateSettingCustomerFileBillExchangePaymentModeSucceeded(false), 3000);
			},
		},
	);

	useQuery<FetchCustomerFileCheckSettingPaymentModeType>(FETCH_CUSTOMER_FILE_CHECK_SETTING_PAYMENT_MODE, {
		onCompleted: ({ quickentry_settingCustomerFileCheckPaymentMode: { currentPaymentMode } }) =>
			setValue('checkIssuancePaymentMode', currentPaymentMode ?? undefined),
		onError: (queryError) =>
			setError(
				generateErrorInformations({
					error: queryError,
					resource: 'quickentry_settingCustomerFileCheckPaymentMode',
				}).message,
			),
		skip: !customerFileId,
		variables: { id: customerFileId, checkType: CheckTypeEnum.CHECK_ISSUANCE },
	});

	useQuery<FetchCustomerFileCheckSettingPaymentModeType>(FETCH_CUSTOMER_FILE_CHECK_SETTING_PAYMENT_MODE, {
		onCompleted: ({ quickentry_settingCustomerFileCheckPaymentMode: { currentPaymentMode } }) =>
			setValue('checkRemittancePaymentMode', currentPaymentMode ?? undefined),
		onError: (queryError) =>
			setError(
				generateErrorInformations({
					error: queryError,
					resource: 'quickentry_settingCustomerFileCheckPaymentMode',
				}).message,
			),
		skip: !customerFileId,
		variables: { id: customerFileId, checkType: CheckTypeEnum.CHECK_REMITTANCE },
	});

	const [createCustomerFileCheckIssuancePaymentMode] = useMutation<CreateCustomerFileCheckSettingPaymentModeType>(
		CREATE_CUSTOMER_FILE_CHECK_SETTING_PAYMENT_MODE,
		{
			onCompleted: ({ quickentry_createSettingCustomerFileCheckPaymentMode: { currentPaymentMode } }) =>
				setValue('checkIssuancePaymentMode', currentPaymentMode ?? undefined),
		},
	);

	const [createCustomerFileCheckRemittancePaymentMode] = useMutation<CreateCustomerFileCheckSettingPaymentModeType>(
		CREATE_CUSTOMER_FILE_CHECK_SETTING_PAYMENT_MODE,
		{
			onCompleted: ({ quickentry_createSettingCustomerFileCheckPaymentMode: { currentPaymentMode } }) =>
				setValue('checkRemittancePaymentMode', currentPaymentMode ?? undefined),
		},
	);

	const onSubmit = useCallback(
		(values: FormFieldType) => {
			const { billExchangePaymentMode, checkIssuancePaymentMode, checkRemittancePaymentMode } = values;

			const {
				billExchangePaymentMode: isBillExchangePaymentModeDirty,
				checkIssuancePaymentMode: isCheckIssuancePaymentModeDirty,
				checkRemittancePaymentMode: isCheckRemittancePaymentModeDirty,
			} = dirtyFields;

			const submitActions = [];

			if (isBillExchangePaymentModeDirty) {
				const { id: billExchangePaymentModeId } = billExchangePaymentMode ?? {};

				submitActions.push(
					createCustomerFileBillExchangePaymentMode({
						variables: {
							createSettingCustomerFileBillExchangePaymentModeInput: {
								customerFileId,
								paymentModeId: billExchangePaymentModeId ?? undefined,
							},
						},
					}),
				);
			}

			if (isCheckIssuancePaymentModeDirty) {
				const { id: checkIssuancePaymentModeId } = checkIssuancePaymentMode ?? {};

				submitActions.push(
					createCustomerFileCheckIssuancePaymentMode({
						variables: {
							createSettingCustomerFileCheckPaymentModeInput: {
								customerFileId,
								paymentModeId: checkIssuancePaymentModeId ?? undefined,
								checkType: CheckTypeEnum.CHECK_ISSUANCE,
							},
						},
					}),
				);
			}

			if (isCheckRemittancePaymentModeDirty) {
				const { id: checkRemittancePaymentModeId } = checkRemittancePaymentMode ?? {};

				submitActions.push(
					createCustomerFileCheckRemittancePaymentMode({
						variables: {
							createSettingCustomerFileCheckPaymentModeInput: {
								customerFileId,
								paymentModeId: checkRemittancePaymentModeId ?? undefined,
								checkType: CheckTypeEnum.CHECK_REMITTANCE,
							},
						},
					}),
				);
			}

			return Promise.all(submitActions);
		},
		[
			dirtyFields,
			createCustomerFileBillExchangePaymentMode,
			customerFileId,
			createCustomerFileCheckIssuancePaymentMode,
			createCustomerFileCheckRemittancePaymentMode,
		],
	);

	return (
		<SettingsDialogPage title="Modes de règlement">
			<SettingsGroup>
				<Controller
					control={control}
					name="checkIssuancePaymentMode"
					render={({ field: { onChange, value, ...field } }) => {
						const handleChange = (changedValue?: PaymentModeType) => onChange(changedValue ?? null);

						return (
							<SettingsItemAutocomplete<PaymentModeType>
								{...field}
								dataSource={paymentModesDataSource}
								getOptionLabel={({ code, name: journalName }) => `${code} - ${journalName}`}
								label='Mode de règlement "Chèques émis"'
								onChange={handleChange}
								value={value ?? undefined}
								disableClearable
							/>
						);
					}}
				/>
				<Controller
					control={control}
					name="checkRemittancePaymentMode"
					render={({ field: { onChange, value, ...field } }) => {
						const handleChange = (changedValue?: PaymentModeType) => onChange(changedValue ?? null);

						return (
							<SettingsItemAutocomplete<PaymentModeType>
								{...field}
								dataSource={paymentModesDataSource}
								getOptionLabel={({ code, name: journalName }) => `${code} - ${journalName}`}
								label='Mode de règlement "Remise de chèques"'
								onChange={handleChange}
								value={value ?? undefined}
								disableClearable
							/>
						);
					}}
				/>
				<Controller
					control={control}
					name="billExchangePaymentMode"
					render={({ field: { onChange, value, ...field } }) => {
						const handleChange = (changedValue?: PaymentModeType) => onChange(changedValue ?? null);

						return (
							<SettingsItemAutocomplete<PaymentModeType>
								{...field}
								dataSource={paymentModesDataSource}
								getOptionLabel={({ code, name: journalName }) => `${code} - ${journalName}`}
								label='Mode de règlement "LCR émises"'
								onChange={handleChange}
								value={value ?? undefined}
								disableClearable
							/>
						);
					}}
				/>
			</SettingsGroup>

			<ActionWrapper>
				<Button disabled={!isFormValid} onClick={handleSubmit(onSubmit)} variant="contained">
					{isCreateSettingCustomerFileBillExchangePaymentModeSucceeded ? 'LCR validée' : 'Valider'}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</ActionWrapper>
		</SettingsDialogPage>
	);
};

export default PaymentModes;
