import { useLazyQuery } from '@apollo/client';
import { Chip, IconButton, Menu, TableColumnType, TableOrderByType, Icon } from '@elipssolution/harfang';
import { mdiDotsVertical } from '@mdi/js';
import { MenuItem } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';

import CreateInternalUserForm from './CreateInternalUserForm';
import InternalUserForm from './InternalUserForm';
import LinkIcon from '../../../../components/LinkIcon';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { PermissionEnum } from '../../../../types/permission';
import { FETCH_INTERNAL_USERS, FetchInternalUsersType } from '../../../api/internalUser';
import { useSession } from '../../../components/SessionProvider';
import { InternalUserType } from '../../../types/user';

const Options = ({ user }: { user: InternalUserType }) => {
	const {
		user: { id: sessionUserId },
		impersonate,
	} = useSession();

	const {
		group: { isAdministrator: isUserAdministrator },
		id: internalUserId,
	} = user;

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

	return isUserAdministrator || sessionUserId === internalUserId ? null : (
		<>
			<IconButton
				onClick={(event) => {
					event.stopPropagation();
					setAnchorEl(event.currentTarget);
				}}
			>
				<Icon path={mdiDotsVertical} />
			</IconButton>

			<Menu
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={(event: MouseEvent) => {
					event.stopPropagation();
					setAnchorEl(undefined);
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
			>
				<MenuItem onClick={() => impersonate(user)}>Voir en tant que</MenuItem>
			</Menu>
		</>
	);
};

const InternalUserTable = () => {
	const { push } = useSettingsDialog();
	const { checkPermission, impersonatedUser } = useSession();

	const [fetchUsers] = useLazyQuery<FetchInternalUsersType>(FETCH_INTERNAL_USERS);

	const columns: TableColumnType<InternalUserType>[] = [
		{
			field: 'lastName',
			key: 'name',
			render: ({ lastName, firstName }) => `${lastName} ${firstName ?? ''}`.trim(),
			sortable: true,
			title: "Nom de l'utilisateur",
			width: 200,
		},
		{
			field: 'email',
			key: 'email',
			sortable: true,
			title: 'Email',
			width: 200,
		},
		{
			key: 'group',
			render: ({ group: { isAdministrator, isDefault, name } }) => {
				if (isAdministrator) {
					return <Chip label={name} color="error" />;
				}

				return <Chip label={name} color={isDefault ? 'info' : 'default'} />;
			},
			title: 'Groupe',
			width: 150,
		},
		...(!impersonatedUser && checkPermission(PermissionEnum.IMPERSONATE)
			? [
					{
						key: 'options',
						flexGrow: 0,
						width: 40,
						render: (user: InternalUserType) => <Options user={user} />,
					},
			  ]
			: []),
		{
			key: 'actions',
			render: () => <LinkIcon />,
			flexGrow: 0,
			width: 40,
		},
	];

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<InternalUserType>,
		): Promise<{ count: number; items: InternalUserType[] }> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchUsers({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				internalUsers: { count = 0, items = [] },
			} = data ?? {
				internalUsers: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchUsers],
	);

	const handleUserSelection = (user: InternalUserType) => push(<InternalUserForm user={user} />);

	const handleAddButtonClick = () => push(<CreateInternalUserForm />);

	return (
		<SettingsTable<InternalUserType>
			addButtonLabel="Ajout d'utilisateurs"
			dataSource={dataSource}
			onAddButtonClick={handleAddButtonClick}
			onRowClick={handleUserSelection}
			tableColumns={columns}
			title="Utilisateurs"
		/>
	);
};

export default InternalUserTable;
