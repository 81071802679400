import { SVGProps } from 'react';

const GrossNetSalarySVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 512 512" height={60} width={60} {...props}>
		<path d="M210 17.6c-11.1 2.3-22.4 8.6-31.1 17.3-25.1 25.2-25.2 65.1 0 90.1 25.4 25.2 65 25.3 90.1.1 25.3-25.4 25.3-64.8 0-90.2-15.5-15.6-37.4-22-59-17.3z" />
		<path d="M346 93.8c-33.7 28.9-38 32.9-42.5 39.8-12 18.3-33.4 33.4-56.2 39.6-10.4 2.8-34.3 3.1-44.7.5-22-5.5-42.5-18.9-55.2-36.1l-4.6-6.4-7 1.9c-16.3 4.5-28.5 9.7-41.3 17.4-39.8 24.1-66.6 62.2-75.7 107.8-3.2 16.2-3.2 43.4 0 59.5 8.7 43.3 32.2 78.7 67.9 102.5l9.3 6.2V496h112v-48h64v48h105v-66l4.3-2.1c6.7-3.4 20.6-13.3 28.2-20 14.9-13.3 29.7-32.4 37.5-48.6l3.5-7.3H496V224h-45.5l-3.5-7.3c-11.2-23.2-30.9-45.6-53.5-60.9l-9-6-.5-44.1-.5-44L346 93.8zM272 224v16h-96v-32h96v16zm105.6 3.5c11.3 8.6 6.4 26.6-7.8 28.2-5.8.7-10.8-1.5-14.4-6.3-2.4-3.1-2.9-4.7-2.9-9.4 0-4.6.5-6.3 2.7-9.2 5.6-7.4 15.2-8.8 22.4-3.3z" />
		<path
			d="M196.332 76.406a4.869 4.869 0 0 1-4.32-2.625h4.32l.75-1.5h-5.565a4.976 4.976 0 0 1-.06-.75c0-.255.022-.502.06-.75h4.815l.75-1.5h-5.07a4.869 4.869 0 0 1 7.492-1.447l1.328-1.328a6.716 6.716 0 0 0-4.5-1.725c-2.94 0-5.43 1.875-6.375 4.5h-2.625l-.75 1.5h3.045c-.045.248-.045.495-.045.75s0 .503.045.75h-2.295l-.75 1.5h3.375c.945 2.625 3.435 4.5 6.375 4.5a6.702 6.702 0 0 0 4.5-1.725l-1.335-1.327a4.804 4.804 0 0 1-3.165 1.177z"
			style={{
				strokeWidth: 0.75,
				fill: '#000',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default GrossNetSalarySVG;
