import { Icon, Table, TableColumnType } from '@elipssolution/harfang';
import { mdiCheck, mdiClose } from '@mdi/js';
import { useCallback } from 'react';

import LinkIcon from '../../../../components/LinkIcon';
import { WidgetExternalApplicationType } from '../../../../types/widget';

type ExternalApplicationsTableProps = {
	applications: WidgetExternalApplicationType[];
	onSelectApplication: (application: WidgetExternalApplicationType) => void;
};

const columns: TableColumnType<WidgetExternalApplicationType>[] = [
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom',
		width: 100,
	},
	{
		align: 'center',
		field: 'isInternal',
		key: 'isInternal',
		render: ({ isInternal }) => (
			<Icon color={isInternal ? 'success' : 'error'} path={isInternal ? mdiCheck : mdiClose} />
		),
		title: 'Cabinet',
		width: 50,
	},
	{
		align: 'center',
		field: 'isExternal',
		key: 'isExternal',
		render: ({ isExternal }) => (
			<Icon color={isExternal ? 'success' : 'error'} path={isExternal ? mdiCheck : mdiClose} />
		),
		title: 'Entreprise',
		width: 50,
	},
	{
		align: 'center',
		field: 'isAvailableOnDesktopFormat',
		key: 'isAvailableOnDesktopFormat',
		render: ({ isAvailableOnDesktopFormat }) => (
			<Icon
				color={isAvailableOnDesktopFormat ? 'success' : 'error'}
				path={isAvailableOnDesktopFormat ? mdiCheck : mdiClose}
			/>
		),
		title: 'Version ordinateur',
		width: 50,
	},
	{
		align: 'center',
		field: 'isAvailableOnMobileFormat',
		key: 'isAvailableOnMobileFormat',
		render: ({ isAvailableOnMobileFormat }) => (
			<Icon
				color={isAvailableOnMobileFormat ? 'success' : 'error'}
				path={isAvailableOnMobileFormat ? mdiCheck : mdiClose}
			/>
		),
		title: 'Version mobile',
		width: 50,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

const ExternalApplicationsTable = ({ applications, onSelectApplication }: ExternalApplicationsTableProps) => {
	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
		): Promise<{
			items: WidgetExternalApplicationType[];
			count: number;
		}> =>
			Promise.resolve({
				items: applications.slice(offset, offset + limit),
				count: applications.length,
			}),
		[applications],
	);

	const handleRowClick = useCallback(
		(application: WidgetExternalApplicationType) => onSelectApplication(application),
		[onSelectApplication],
	);

	return (
		<Table<WidgetExternalApplicationType>
			columns={columns}
			dataSource={dataSource}
			onRowClick={handleRowClick}
			title="Applications externes"
		/>
	);
};

export default ExternalApplicationsTable;
