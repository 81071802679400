import { gql } from '@apollo/client';

import { ConnectorType } from '../types/connector';

// QUERIES

export type FetchConnectorsType = {
	document_connectors: {
		count: number;
		items: ConnectorType[];
		limit: number;
		offset: number;
	};
};

export type FetchConnectorsVariablesType = {
	hasRead?: boolean;
	hasWrite?: boolean;
	page: { limit: number; offset: number };
	search?: string;
};

export const FETCH_CONNECTORS = gql`
	query Document_connectors($hasRead: Boolean, $hasWrite: Boolean, $page: Page, $search: String) {
		document_connectors(hasRead: $hasRead, hasWrite: $hasWrite, page: $page, search: $search) {
			count
			items {
				code
				name
				settingStorageDownload {
					id
				}
			}
		}
	}
`;

export type FetchConnectorType = {
	document_connector: ConnectorType;
};

export type FetchConnectorVariablesType = {
	code: ConnectorType['code'];
};

export const FETCH_CONNECTOR = gql`
	query Document_connector($code: String!) {
		document_connector(code: $code) {
			name
			folders {
				code
				name
				isRequired
				variables {
					code
					name
					color
				}
			}
			parameters {
				code
				isRequired
				name
				type
			}
		}
	}
`;
