import { useLazyQuery } from '@apollo/client';
import { Chip, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { Stack, styled, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import LinkIcon from '../../../../../components/LinkIcon';
import SettingsTable from '../../../../../components/SettingsTable';
import { FETCH_STORAGE_UPLOADS, FetchStorageUploadsType } from '../../../api/settingStorageUpload';
import { SettingStorageUploadType } from '../../../types/settingStorage';

const TooltipTagsWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	gap: spacing(0.5),

	fontWeight: 400,

	'& > div': {
		minHeight: 24,
		height: 'fit-content',
		paddingTop: spacing(0.5),
		paddingBottom: spacing(0.5),

		'& > span': {
			whiteSpace: 'wrap',
		},
	},
}));

const MeasureChip = styled(Chip)({
	position: 'absolute',
	visibility: 'hidden',
	zIndex: -1,
});

const TagsWrapper = ({ columnWidth, tags }: { columnWidth: number; tags: SettingStorageUploadType['tags'] }) => {
	const { spacing } = useTheme();

	const [visibleTags, setVisibleTags] = useState<SettingStorageUploadType['tags']>();

	const measureChipRef = useRef<HTMLDivElement>(null);
	const measureChipElement = measureChipRef.current;
	const measureChipSpan = measureChipElement?.children[0];

	const moreTagsQuantity = visibleTags ? tags.length - visibleTags.length : 0;

	const sortedTags = tags.sort((a, b) => (a.name.length >= b.name.length ? 1 : -1));

	useEffect(() => {
		if (!measureChipElement || !measureChipSpan) return;

		let maxTags = 0;
		let currentWidth = 31;

		sortedTags.every(({ name }) => {
			measureChipSpan.textContent = name;

			const chipWidth = measureChipElement.offsetWidth + +spacing(1).slice(0, -2);

			if (currentWidth + chipWidth < columnWidth) {
				currentWidth += chipWidth;
				maxTags += 1;

				return true;
			}

			return false;
		});

		setVisibleTags(sortedTags.slice(0, maxTags));
	}, [spacing, measureChipElement, measureChipSpan, columnWidth, sortedTags]);

	return (
		<>
			{visibleTags?.map(({ color, id, name }) => (
				<Chip key={id} color={color} label={name} />
			))}
			{moreTagsQuantity > 0 && (
				<Tooltip
					content={
						<TooltipTagsWrapper>
							{sortedTags.slice(-moreTagsQuantity).map(({ color, id, name }) => (
								<Chip key={id} color={color} label={name} size="small" />
							))}
						</TooltipTagsWrapper>
					}
				>
					<Chip label={`+${moreTagsQuantity}`} size="small" />
				</Tooltip>
			)}
			<MeasureChip ref={measureChipRef} />
		</>
	);
};

const columns: TableColumnType<SettingStorageUploadType>[] = [
	{
		field: 'name',
		key: 'name',
		title: 'Nom',
		width: 150,
	},
	{
		key: 'tags',
		render: ({ tags }) => (
			<ReactResizeDetector handleWidth>
				{({ width }) => (
					<Stack alignItems="center" direction="row" gap={1} overflow="hidden" width="100%">
						<TagsWrapper columnWidth={width ?? 0} tags={tags} />
					</Stack>
				)}
			</ReactResizeDetector>
		),
		title: 'Tags',
		width: 300,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

type StorageUploadTableSectionProps = {
	onStorageUploadSelection: (storageUpload?: SettingStorageUploadType) => void;
};

const StorageUploadTableSection = ({ onStorageUploadSelection }: StorageUploadTableSectionProps) => {
	const [fetchUploadStorages] = useLazyQuery<FetchStorageUploadsType>(FETCH_STORAGE_UPLOADS);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: SettingStorageUploadType[];
		}> => {
			const { data, error } = await fetchUploadStorages({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				document_settingStorageUploads: { count = 0, items = [] },
			} = data ?? {
				document_settingStorageUploads: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchUploadStorages],
	);

	const handleAddButtonClick = useCallback(() => onStorageUploadSelection(), [onStorageUploadSelection]);

	const handleRowClick = useCallback(
		(storageUpload: SettingStorageUploadType) => onStorageUploadSelection(storageUpload),
		[onStorageUploadSelection],
	);

	return (
		<SettingsTable
			addButtonLabel="Ajouter un emplacement de dépôt"
			dataSource={dataSource}
			onAddButtonClick={handleAddButtonClick}
			onRowClick={handleRowClick}
			tableColumns={columns}
			title="Emplacements de dépôt"
		/>
	);
};

export default StorageUploadTableSection;
