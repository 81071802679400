import { useLazyQuery } from '@apollo/client';
import { Chip, TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import InheritChip from '../../../../../../components/InheritChip';
import LinkIcon from '../../../../../../components/LinkIcon';
import SettingsTable from '../../../../../../components/SettingsTable';
import { CustomerFileType } from '../../../../../../types/customerFile';
import {
	FETCH_SETTING_TEMPLATES_BY_CUSTOMER_FILE,
	FetchSettingTemplatesByCustomerFileType,
} from '../../../../api/settingTemplate';
import { SettingCustomerFileTemplateType, StateEnum } from '../../../../types/settingTemplate';

const columns: TableColumnType<SettingCustomerFileTemplateType>[] = [
	{
		key: 'level',
		render: ({ isInherited, isOverload }) => (
			<InheritChip parentLabel="Domaine" childLabel="Dossier" inherited={isInherited} override={isOverload} />
		),
		title: 'Niveau',
		width: 160,
		flexGrow: 0,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom',
		width: 200,
	},
	{
		field: 'validUntil',
		key: 'validUntil',
		render: ({ validUntil = '' }) => validUntil,
		sortable: true,
		title: "Valide jusqu'à",
		width: 150,
	},
	{
		field: 'state',
		flexGrow: 0,
		key: 'state',
		render: ({ state }) => {
			const isDisabled = state === StateEnum.DISABLED;
			const isEnabled = state === StateEnum.ENABLED;

			const chipColor = () => {
				if (isDisabled) return 'default';

				return isEnabled ? 'success' : 'info';
			};

			const chipLabel = () => {
				if (isDisabled) return 'Inactif';

				return isEnabled ? 'Actif' : 'Archivé';
			};

			return <Chip color={chipColor()} label={chipLabel()} />;
		},
		sortable: true,
		title: 'État',
		width: 110,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

type TemplateCustomerFileTableProps = {
	customerFileId?: CustomerFileType['id'];
	onTemplateSelection: (template?: SettingCustomerFileTemplateType) => void;
};

const TemplateCustomerFileTable = ({ customerFileId, onTemplateSelection }: TemplateCustomerFileTableProps) => {
	const [fetchSettingCheckAccountTypesByCustomerFile] = useLazyQuery<FetchSettingTemplatesByCustomerFileType>(
		FETCH_SETTING_TEMPLATES_BY_CUSTOMER_FILE,
	);

	const settingCheckAccountTypesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<SettingCustomerFileTemplateType>,
		): Promise<{
			count: number;
			items: SettingCustomerFileTemplateType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchSettingCheckAccountTypesByCustomerFile({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					customerFileId,
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_settingTemplatesByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				quickentry_settingTemplatesByCustomerFile: {},
			};

			return {
				count,
				items: items.map(({ validUntil, ...rest }) => ({
					...rest,
					...(validUntil && {
						validUntil: new Date(validUntil).toLocaleDateString('fr-FR'),
					}),
				})),
			};
		},
		[fetchSettingCheckAccountTypesByCustomerFile, customerFileId],
	);

	const handleAddButtonClick = useCallback(() => onTemplateSelection(), [onTemplateSelection]);

	const handleRowClick = useCallback(
		(template: SettingCustomerFileTemplateType) => onTemplateSelection(template),
		[onTemplateSelection],
	);

	return (
		<SettingsTable<SettingCustomerFileTemplateType>
			dataSource={settingCheckAccountTypesDataSource}
			onAddButtonClick={handleAddButtonClick}
			onRowClick={handleRowClick}
			tableColumns={columns}
			title="Modèles"
		/>
	);
};

export default TemplateCustomerFileTable;
