import { WidgetEnum } from '../utils/Widget';

export enum ModuleCodeEnum {
	GENERAL = 'general',
	ACCOUNTING = 'accounting',
	DOCUMENT = 'document',
	QUICKENTRY = 'quickentry',
	SIGN = 'sign',
	BROCHURE = 'brochure',
}

export type WidgetType = {
	id?: string;
	configuration?: string;
	height: number;
	isAvailableOnDesktopFormat: boolean;
	isAvailableOnMobileFormat: boolean;
	name: string;
	type: WidgetEnum;
	width: number;
	x: number;
	y: number;
	moduleId?: string;
	moduleCode: ModuleCodeEnum;
};

export type WidgetComponentProps<T = object> = {
	widgetWithKey: WidgetWithKeyType;
	readOnly: boolean;
	configuration?: T;
};

export type WidgetWithKeyType = { key: string; widget: WidgetType };

export type WidgetConfigurationComponentProps = {
	widgetWithKey: WidgetWithKeyType;
	name?: string;
	isAvailableOnDesktopFormat: boolean;
	isAvailableOnMobileFormat: boolean;
	configuration?: object;
	onSave: (
		name: string,
		isAvailableOnDesktopFormat: boolean,
		isAvailableOnMobileFormat: boolean,
		configuration?: object,
	) => Promise<void>;
	onBack: () => void;
};

export type WidgetTemplateType = {
	component: (props: WidgetComponentProps) => JSX.Element;
	configurationComponent?: (props: WidgetConfigurationComponentProps) => JSX.Element;
	maxH: number;
	maxW: number;
	minH: number;
	minW: number;
	name: WidgetType['name'];
	isAvailableOnDesktopFormat: WidgetType['isAvailableOnDesktopFormat'];
	isAvailableOnMobileFormat: WidgetType['isAvailableOnMobileFormat'];
	type: WidgetType['type'];
	moduleCode: WidgetType['moduleCode'];
};

export type WidgetExternalApplicationType = {
	key: string;
	name: string;
	type?: string;
	isExternal?: boolean;
	isInternal?: boolean;
	specific?: string;
	isAvailableOnDesktopFormat?: boolean;
	isAvailableOnMobileFormat?: boolean;
};

export type WidgetSimulatorType = {
	key: string;
	name: string;
	type?: string;
	isExternal?: boolean;
	isInternal?: boolean;
	isAvailableOnDesktopFormat?: boolean;
	isAvailableOnMobileFormat?: boolean;
};
export type WidgetSimulatorsConfigurationType = {
	simulators?: WidgetSimulatorType[];
};

export type WidgetExternalApplicationsConfigurationType = {
	applications?: WidgetExternalApplicationType[];
};
