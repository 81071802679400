import { Icon, Table, TableColumnType } from '@elipssolution/harfang';
import { mdiDelete, mdiInformation, mdiPencil } from '@mdi/js';
import { styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import TemplateLineFormSection from './TemplateDomainLineFormSection';
import TableRowActionCell from '../../../../../components/TableRowActionCell';
import { AccountTypeLabelEnum } from '../../../../../types/account';
import { AnalyticalDimensionType } from '../../../../../types/analyticalDimension';
import {
	DirectionEnum,
	SettingDomainTemplateLineType,
	SettingTemplateLineType,
} from '../../../../../types/settingTemplate';

const StyledWrapper = styled('div')(({ theme: { spacing } }) => ({
	flex: 1,

	marginTop: spacing(2),
	display: 'flex',
	flexDirection: 'column',
}));

const InformationContainer = styled('div')(({ theme: { spacing, palette, shape } }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: spacing(4),

	marginTop: spacing(2),
	padding: spacing(3, 6),

	backgroundColor: `${palette.secondary.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

const templateLineDirections = [
	{ value: DirectionEnum.DEBIT, label: 'Débit' },
	{ value: DirectionEnum.CREDIT, label: 'Crédit' },
];

type TemplateDomainLineSectionProps = {
	analyticalDimensionCode?: AnalyticalDimensionType['code'];
	analyticalSectionCaption?: string;
	defaultEntryName?: string;
	onChange: (templateLines: SettingTemplateLineType[]) => void;
	templateLines: SettingTemplateLineType[];
};

const TemplateDomainLineSection = ({
	analyticalDimensionCode,
	analyticalSectionCaption,
	defaultEntryName,
	onChange,
	templateLines,
}: TemplateDomainLineSectionProps) => {
	const [updatingTemplateLine, setUpdatingTemplateLine] = useState<SettingTemplateLineType>();
	const [isCreationSucceeded, setIsCreationSucceeded] = useState(false);
	const [isUpdatingSucceeded, setIsUpdatingSucceeded] = useState(false);

	const resetUpdateTemplateLine = useCallback(() => setUpdatingTemplateLine(undefined), []);

	const addTemplateLine = useCallback(
		(templateLine: SettingTemplateLineType) => {
			onChange([...templateLines, templateLine]);
			setIsCreationSucceeded(true);
			setTimeout(() => setIsCreationSucceeded(false), 3000);
		},
		[onChange, templateLines],
	);

	const updateTemplateLines = useCallback(
		(templateLine: SettingTemplateLineType) => {
			onChange(templateLines.map((line) => (line.id === templateLine.id ? templateLine : line)));
			resetUpdateTemplateLine();
			setIsUpdatingSucceeded(true);
			setTimeout(() => setIsUpdatingSucceeded(false), 3000);
		},
		[onChange, resetUpdateTemplateLine, templateLines],
	);

	const handleFormSubmit = useCallback(
		(values: Omit<SettingTemplateLineType, 'id'>) =>
			updatingTemplateLine
				? updateTemplateLines({ ...updatingTemplateLine, ...values })
				: addTemplateLine({ ...values, id: uuid() }),
		[updateTemplateLines, addTemplateLine, updatingTemplateLine],
	);

	const removeTemplateLine = useCallback(
		(templateLineId: string) => {
			onChange(templateLines.filter((templateLine) => templateLine.id !== templateLineId));

			const { id: updatingTemplateLineId } = updatingTemplateLine || {};

			templateLineId === updatingTemplateLineId && resetUpdateTemplateLine();
		},
		[onChange, resetUpdateTemplateLine, templateLines, updatingTemplateLine],
	);

	const columns: TableColumnType<SettingDomainTemplateLineType>[] = useMemo(
		() => [
			{
				field: 'name',
				key: 'name',
				title: 'Nom',
				width: 140,
			},
			{
				field: 'direction',
				key: 'direction',
				render: ({ direction }) => templateLineDirections.find(({ value }) => value === direction)?.label,
				title: 'Sens',
				flexGrow: 0,
				width: 50,
			},
			{
				field: 'defaultTransactionName',
				key: 'defaultTransactionName',
				title: 'Libellé par défaut',
				width: 140,
			},
			{
				field: 'accountCode',
				key: 'accountCode',
				title: 'Compte',
				flexGrow: 0,
				width: 80,
			},
			{
				field: 'type',
				key: 'type',
				render: ({ type }) => AccountTypeLabelEnum[type],
				title: 'Type',
				flexGrow: 0,
				width: 60,
			},
			{
				field: 'analyticalSectionCode',
				key: 'analyticalSectionCode',
				render: ({ analyticalSectionCode }) => analyticalSectionCode || '',
				title: analyticalSectionCaption || 'Section',
				flexGrow: 0,
				width: 50,
			},
			{
				field: 'subaccountCode',
				key: 'subaccountCode',
				render: ({ subaccountCode }) => subaccountCode || '',
				title: 'Compte aux.',
				flexGrow: 0,
				width: 80,
			},

			{
				field: 'subaccountPrefix',
				key: 'subaccountPrefix',
				render: ({ subaccountPrefix }) => subaccountPrefix || '',
				title: 'Préfixe aux.',
				flexGrow: 0,
				width: 30,
			},
			{
				key: 'actions',
				title: '',
				flexGrow: 0,
				render: (template) => (
					<TableRowActionCell
						actions={[
							{
								id: 'update',
								label: 'Modifier',
								icon: <Icon path={mdiPencil} />,
								onClick: () => setUpdatingTemplateLine(template),
							},
							{
								id: 'delete',
								label: 'Supprimer',
								icon: <Icon path={mdiDelete} />,
								onClick: () => removeTemplateLine(template.id),
							},
						]}
					/>
				),
				width: 40,
			},
		],
		[analyticalSectionCaption, removeTemplateLine],
	);

	const dataSource = useCallback(
		async (): Promise<{
			items: SettingTemplateLineType[];
			count: number;
		}> =>
			Promise.resolve({
				items: templateLines,
				count: templateLines.length,
			}),
		[templateLines],
	);

	return (
		<StyledWrapper>
			<Table<SettingTemplateLineType> columns={columns} dataSource={dataSource} />

			<TemplateLineFormSection
				isCreationSucceeded={isCreationSucceeded}
				isUpdatingSucceeded={isUpdatingSucceeded}
				analyticalDimensionCode={analyticalDimensionCode}
				defaultEntryName={defaultEntryName}
				directions={templateLineDirections}
				onSubmit={handleFormSubmit}
				resetUpdateTemplateLine={resetUpdateTemplateLine}
				templateLine={updatingTemplateLine ?? undefined}
			/>
			<InformationContainer>
				<Icon path={mdiInformation} />
				Un modèle doit être composé d&apos;au moins une ligne de débit et une ligne de crédit
			</InformationContainer>
		</StyledWrapper>
	);
};

export default TemplateDomainLineSection;
