import { useLazyQuery } from '@apollo/client';
import { Chip, Icon, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiInformationOutline } from '@mdi/js';
import { Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';

import AddUserCustomerFileRelationForm from './AddUserCustomerFileRelationForm';
import LinkIcon from '../../../../components/LinkIcon';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import {
	FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_USER,
	FetchExternalUserCustomerFileRelationsByUserType,
} from '../../../api/externalUserCustomerFile';
import { useSession } from '../../../components/SessionProvider';
import { ExternalUserCustomerFileRelationType } from '../../../types/relation';
import { UserType, UserTypeEnum } from '../../../types/user';
import UserCustomerFileRelation from '../userCustomerFileRelation/UserCustomerFileRelation';

type UserCustomerFilesTableSectionProps = {
	user: UserType;
};

const UserCustomerFilesTableSection = ({ user }: UserCustomerFilesTableSectionProps) => {
	const { push } = useSettingsDialog();
	const {
		user: { type, id: sessionUserId },
	} = useSession();

	const isExternalUserSessionUser = useMemo(
		() => type === UserTypeEnum.EXTERNAL && user.id === sessionUserId,
		[sessionUserId, type, user.id],
	);

	const columns: TableColumnType<ExternalUserCustomerFileRelationType>[] = useMemo(
		() => [
			{
				flexGrow: 0,
				key: 'customerFileCode',
				render: ({ customerFile: { code } }) => code,
				title: 'Code',
				width: 100,
			},
			{
				key: 'customerFileName',
				render: ({ customerFile: { name } }) => name,
				title: "Nom de l'entreprise",
				width: 400,
			},
			{
				key: 'hasUserPermissions',
				flexGrow: 0,
				render: ({ hasUserPermissions }) =>
					hasUserPermissions && (
						<Tooltip content="Les droits de l'utilisateur ont été personnalisés">
							<Stack alignItems="center">
								<Icon path={mdiInformationOutline} />
							</Stack>
						</Tooltip>
					),
				width: 24,
			},
			...(type === UserTypeEnum.INTERNAL
				? [
						{
							key: 'groupName',
							render: ({ group: { isAdministrator, isDefault, name } }: ExternalUserCustomerFileRelationType) => {
								if (isAdministrator) {
									return <Chip label={name} color="error" />;
								}

								return <Chip label={name} color={isDefault ? 'info' : 'default'} />;
							},
							title: 'Groupe',
							width: 150,
						},
				  ]
				: []),
			...(!isExternalUserSessionUser
				? [
						{
							flexGrow: 0,
							key: 'actions',
							render: () => <LinkIcon />,
							width: 40,
						},
				  ]
				: []),
		],
		[isExternalUserSessionUser, type],
	);

	const [fetchExternalCustomerFileRelation] = useLazyQuery<FetchExternalUserCustomerFileRelationsByUserType>(
		FETCH_EXTERNAL_USER_CUSTOMER_FILE_RELATIONS_BY_USER,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: ExternalUserCustomerFileRelationType[];
		}> => {
			const { data, error } = await fetchExternalCustomerFileRelation({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
					userId: user.id,
				},
			});

			if (error) {
				throw error;
			}

			const {
				externalUserCustomerFileRelationsByUser: { count = 0, items = [] },
			} = data ?? {
				externalUserCustomerFileRelationsByUser: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchExternalCustomerFileRelation, user],
	);

	const handleAddButtonClick = useCallback(() => push(<AddUserCustomerFileRelationForm user={user} />), [push, user]);

	const handleRowClick = useCallback(
		(customerFileRelation: ExternalUserCustomerFileRelationType) =>
			push(<UserCustomerFileRelation user={user} initialCustomerFile={customerFileRelation.customerFile} />),
		[push, user],
	);

	return (
		<SettingsTable
			addButtonLabel="Ajout de dossiers"
			dataSource={dataSource}
			onAddButtonClick={!isExternalUserSessionUser ? handleAddButtonClick : undefined}
			onRowClick={!isExternalUserSessionUser ? handleRowClick : undefined}
			tableColumns={columns}
			title="Entreprises"
		/>
	);
};

export default UserCustomerFilesTableSection;
