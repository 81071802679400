import { Chip, Icon, IconButton, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiDelete } from '@mdi/js';
import { useCallback, useMemo, MouseEvent } from 'react';

import LinkIcon from '../../../../components/LinkIcon';
import SettingsTable from '../../../../components/SettingsTable';
import { useSettingsDialog } from '../../../../hooks/useSettingsDialog';
import { AddInternalUserType, InternalUserType } from '../../../types/user';
import InternalUserForm from '../internalUser/InternalUserForm';

type AddedCustomerFileUserRelationTableProps = {
	internalUsers: AddInternalUserType[];
	onRemoveInternalUser: (internalUserId: AddInternalUserType['id']) => Promise<void>;
};

const AddedCustomerFileUserRelationTable = ({
	internalUsers,
	onRemoveInternalUser,
}: AddedCustomerFileUserRelationTableProps) => {
	const { push } = useSettingsDialog();

	const removeInternalUser = useCallback(
		async (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, userId: AddInternalUserType['id']) => {
			event.stopPropagation();
			await onRemoveInternalUser(userId);
		},
		[onRemoveInternalUser],
	);

	const columns: TableColumnType<AddInternalUserType>[] = useMemo(
		() => [
			{
				field: 'lastName',
				key: 'name',
				render: ({ lastName, firstName }) => `${lastName} ${firstName ?? ''}`.trim(),
				title: "Nom de l'utilisateur",
				width: 200,
			},
			{
				field: 'email',
				key: 'email',
				title: 'Email',
				width: 200,
			},
			{
				key: 'group',
				render: ({ group }) => {
					if (!group) return undefined;

					const { name, isDefault, isAdministrator } = group || {};

					if (isAdministrator) return <Chip label={name} color="error" />;

					return <Chip label={name} color={isDefault ? 'info' : 'default'} />;
				},
				title: 'Groupe',
				width: 150,
			},
			{
				key: 'actions',
				flexGrow: 0,
				render: ({ id }) => (
					<Tooltip content="Retire la liaison entre l'utilisateur et le dossier">
						<IconButton onClick={(event) => removeInternalUser(event, id)}>
							<Icon path={mdiDelete} />
						</IconButton>
					</Tooltip>
				),
				width: 40,
			},
			{
				key: 'navigate',
				flexGrow: 0,
				render: () => <LinkIcon />,
				width: 40,
			},
		],
		[removeInternalUser],
	);

	const dataSource = useCallback(
		async (): Promise<{
			count: number;
			items: AddInternalUserType[];
		}> =>
			Promise.resolve({
				items: internalUsers,
				count: internalUsers.length,
			}),
		[internalUsers],
	);

	const handleRowClick = useCallback(
		(addInternalUserType: AddInternalUserType) => {
			push(<InternalUserForm user={addInternalUserType as InternalUserType} />);
		},
		[push],
	);

	return (
		<SettingsTable
			dataSource={dataSource}
			enableSearch={false}
			tableColumns={columns}
			title="Utilisateurs ajoutés"
			onRowClick={handleRowClick}
		/>
	);
};

export default AddedCustomerFileUserRelationTable;
