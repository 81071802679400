import { SettingsGroup } from '@elipssolution/harfang';
import { Control, Controller } from 'react-hook-form';

import ConnectorFieldsForm from '../../components/ConnectorFieldsForm';
import { ConnectorType } from '../../types/connector';
import { StorageDownloadFormType } from '../../types/storageDownloadFormType';

type FoldersTreeFormProps = {
	control: Control<StorageDownloadFormType>;
	connectorFolders: ConnectorType['folders'];
};

const FoldersTreeForm = ({ control, connectorFolders }: FoldersTreeFormProps) => {
	const validateRequiredFolders = (value: Record<string, string>): boolean =>
		connectorFolders.every(({ code, isRequired }) => {
			const formValue = value[code];

			return isRequired ? !!formValue && formValue !== '/' : true;
		});

	return (
		<SettingsGroup label="Arborescences" actionsWidth={510}>
			<Controller
				control={control}
				render={({ field }) => <ConnectorFieldsForm fields={connectorFolders} {...field} />}
				name="folders"
				rules={{
					validate: validateRequiredFolders,
				}}
			/>
		</SettingsGroup>
	);
};

export default FoldersTreeForm;
