import { SettingsGroup, SettingsItemCheckbox, SettingsItemTextField } from '@elipssolution/harfang';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { WidgetSimulatorType } from '../../../../types/widget';
import { WidgetExternalActionButtons } from '../../WidgetExternalApplications/WidgetExternalActionButtons';

type FormType = {
	name: string;
	isExternal?: boolean;
	isInternal?: boolean;
	isAvailableOnDesktopFormat?: boolean;
	isAvailableOnMobileFormat?: boolean;
};

type SimulatorInstanceFormProps = {
	simulator: WidgetSimulatorType;
	isNew: boolean;
	onSubmit: (simulator: WidgetSimulatorType) => void;
	onRemove: (simulator: WidgetSimulatorType) => void;
};

const SimulatorInstanceForm = ({ simulator, isNew, onSubmit, onRemove }: SimulatorInstanceFormProps) => {
	const {
		key,
		type,
		name: initialName,
		isInternal: isInternalInitial,
		isExternal: isExternalInitial,
		isAvailableOnMobileFormat: isAvailableOnMobileFormatInitial,
		isAvailableOnDesktopFormat: isAvailableOnDesktopFormatInitial,
	} = simulator;

	const {
		control,
		formState: { isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>();

	const handleRemove = () => onRemove(simulator);

	const handleSubmitInternal = ({ name, ...rest }: FormType) => {
		onSubmit({
			key,
			type,
			name,
			...rest,
		});
	};

	useEffect(
		() =>
			reset({
				name: initialName,
				isInternal: isInternalInitial,
				isExternal: isExternalInitial,
				isAvailableOnMobileFormat: isAvailableOnMobileFormatInitial,
				isAvailableOnDesktopFormat: isAvailableOnDesktopFormatInitial,
			}),
		[
			initialName,
			isExternalInitial,
			isInternalInitial,
			isAvailableOnDesktopFormatInitial,
			isAvailableOnMobileFormatInitial,
			reset,
		],
	);

	return (
		<>
			<SettingsGroup label="Configuration">
				<Controller
					name="name"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField {...field} description="Nom du simulateur." label="Nom" required />
					)}
					rules={{ required: true }}
				/>

				<Controller
					name="isInternal"
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs cabinet auront accès à ce simulateur."
							label="Visible cabinet"
						/>
					)}
				/>
				<Controller
					name="isExternal"
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs entreprise auront accès à ce simulateur."
							label="Visible entreprise"
						/>
					)}
				/>

				<Controller
					name="isAvailableOnDesktopFormat"
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le simulateur sera visible sur ordinateur."
							label="Visible version ordinateur"
						/>
					)}
				/>
				<Controller
					name="isAvailableOnMobileFormat"
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, le simulateur sera visible sur mobile."
							label="Visible version mobile"
						/>
					)}
				/>
			</SettingsGroup>

			<WidgetExternalActionButtons
				isValid={isValid}
				isNew={isNew}
				onRemove={handleRemove}
				onSubmit={handleSubmit(handleSubmitInternal)}
			/>
		</>
	);
};

export default SimulatorInstanceForm;
