import { useMutation, useQuery } from '@apollo/client';
import { Button, SettingsGroup, SettingsItemTextField } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { DomainType } from '../../../../../../types/domain';
import {
	CREATE_DOMAIN_CHECK_REMITTANCE_SETTING_COUNTERPART_ACCOUNT,
	CreateDomainCheckRemittanceSettingCounterpartAccountType,
	FETCH_DOMAIN_CHECK_REMITTANCE_SETTING_COUNTERPART_ACCOUNT,
	FetchDomainCheckRemittanceSettingCounterpartAccountType,
} from '../../../../api/settingCheckRemittanceCounterpartAccount';
import { AccountType } from '../../../../types/account';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormFieldType = {
	accountCode: AccountType['code'];
};

type CheckRemittanceCounterpartProps = {
	domainId?: DomainType['id'];
};

const CheckRemittanceCounterpartAccount = ({ domainId }: CheckRemittanceCounterpartProps) => {
	const {
		control,
		formState: { isDirty, isValid },
		handleSubmit,
		setValue,
	} = useForm<FormFieldType>();

	const isFormValid = useMemo(() => isDirty && isValid, [isDirty, isValid]);

	const [error, setError] = useState<string>();
	const [isCreateCheckRemittanceCounterpartAccountSucceeded, setIsCreateCheckRemittanceCounterpartAccountSucceeded] =
		useState(false);

	useQuery<FetchDomainCheckRemittanceSettingCounterpartAccountType>(
		FETCH_DOMAIN_CHECK_REMITTANCE_SETTING_COUNTERPART_ACCOUNT,
		{
			variables: {
				id: domainId,
			},
			onCompleted: ({ quickentry_settingDomainCheckRemittanceCounterpartAccount: { accountCode } }) => {
				if (accountCode) {
					setValue('accountCode', accountCode, { shouldDirty: true });
				}
			},
		},
	);

	const [createCheckRemittanceCounterpartAccount] =
		useMutation<CreateDomainCheckRemittanceSettingCounterpartAccountType>(
			CREATE_DOMAIN_CHECK_REMITTANCE_SETTING_COUNTERPART_ACCOUNT,
			{
				onCompleted: () => {
					setIsCreateCheckRemittanceCounterpartAccountSucceeded(true);
					setTimeout(() => setIsCreateCheckRemittanceCounterpartAccountSucceeded(false), 3000);
				},
				onError: (mutationError) => {
					setError(mutationError.message);
				},
			},
		);

	const onSubmit = ({ accountCode }: FormFieldType) =>
		createCheckRemittanceCounterpartAccount({
			variables: {
				createSettingDomainCheckRemittanceCounterpartAccountInput: {
					accountCode,
					domainId,
				},
			},
		});

	return (
		<SettingsDialogPage title="Contrepartie de remise de chèques">
			<SettingsGroup>
				<Controller
					name="accountCode"
					control={control}
					rules={{
						pattern: {
							value: /^5/,
							message: 'Le code du compte est incorrect',
						},
						required: true,
					}}
					render={({ field }) => <SettingsItemTextField {...field} label="Compte de contrepartie" required />}
				/>
			</SettingsGroup>

			<ActionWrapper>
				<Button disabled={!isFormValid} onClick={handleSubmit(onSubmit)} variant="contained">
					{isCreateCheckRemittanceCounterpartAccountSucceeded ? 'Contrepartie validée' : 'Valider'}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</ActionWrapper>
		</SettingsDialogPage>
	);
};

export default CheckRemittanceCounterpartAccount;
