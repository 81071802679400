import { Url } from 'url';

import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { PublicRuntimeConfigType } from '../types/publicRuntimeConfig';

const isPartialUrl = (value: unknown): value is Partial<Url> =>
	typeof value === 'object' && value !== null && ('pathname' in value || 'query' in value);

const useCustomRouter = () => {
	const { publicRuntimeConfig } = getConfig() as PublicRuntimeConfigType;
	const { standaloneModule } = publicRuntimeConfig;
	const { replace, push, ...restRouter } = useRouter();
	const { query, pathname } = restRouter;

	const transformUrlForStandaloneModule = useCallback(
		(url: string) => (standaloneModule ? url?.replace(`/${standaloneModule}`, '') : url),
		[standaloneModule],
	);

	const handleRouterPush = useCallback(
		(url: Partial<Url> | string, as?: string) => {
			const transformedUrlAlias = standaloneModule ? `${transformUrlForStandaloneModule(pathname)}${as || ''}` : as;
			const { urlPathname, urlQuery } = isPartialUrl(url)
				? { urlPathname: url.pathname, urlQuery: url.query }
				: { urlPathname: url, urlQuery: query };

			return push(
				{
					...(urlPathname && {
						pathname: transformUrlForStandaloneModule(urlPathname || pathname),
					}),
					query: urlQuery,
				},
				as ? transformedUrlAlias : undefined,
			);
		},
		[pathname, push, query, standaloneModule, transformUrlForStandaloneModule],
	);

	const handleRouterReplace = useCallback(
		(url: Partial<Url> | string, as?: string) => {
			const transformedUrlAlias = standaloneModule ? `${transformUrlForStandaloneModule(pathname)}${as || ''}` : as;
			const { urlPathname, urlQuery } = isPartialUrl(url)
				? { urlPathname: url.pathname, urlQuery: url.query }
				: { urlPathname: url, urlQuery: query };

			return replace(
				{
					pathname: transformUrlForStandaloneModule(urlPathname || pathname),
					query: urlQuery,
				},
				as ? transformedUrlAlias : undefined,
			);
		},
		[pathname, query, replace, standaloneModule, transformUrlForStandaloneModule],
	);

	return { push: handleRouterPush, replace: handleRouterReplace, ...restRouter };
};

export default useCustomRouter;
