import { useLazyQuery } from '@apollo/client';
import { Icon, TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { mdiCheck, mdiClose } from '@mdi/js';
import { useCallback } from 'react';

import LinkIcon from '../../../../../../components/LinkIcon';
import SettingsTable from '../../../../../../components/SettingsTable';
import { DomainType } from '../../../../../../types/domain';
import {
	FETCH_SETTING_CHECK_ACCOUNT_TYPES_BY_DOMAIN,
	FetchSettingCheckAccountTypeByDomainType,
} from '../../../../api/settingCheckAccountType';
import { CheckTypeEnum } from '../../../../types/check';
import { SettingDomainCheckAccountType } from '../../../../types/settingCheckAccountType';

const columns: TableColumnType<SettingDomainCheckAccountType>[] = [
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Description',
		width: 200,
	},
	{
		field: 'accountPrefix',
		key: 'accountPrefix',
		sortable: true,
		title: 'Préfixe de compte',
		width: 200,
	},
	{
		align: 'center',
		field: 'isEnabled',
		flexGrow: 0,
		key: 'isEnabled',
		render: ({ isEnabled }) => <Icon color={isEnabled ? 'success' : 'error'} path={isEnabled ? mdiCheck : mdiClose} />,
		sortable: true,
		title: 'Actif',
		width: 80,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

type CheckAccountTypesDomainTableProps = {
	domainId?: DomainType['id'];
	onCheckAccountTypeSelection: (checkAccountType?: SettingDomainCheckAccountType) => void;
	checkType: CheckTypeEnum;
};

const CheckAccountTypesDomainTable = ({
	domainId,
	onCheckAccountTypeSelection,
	checkType,
}: CheckAccountTypesDomainTableProps) => {
	const [fetchSettingCheckAccountTypesByDomain] = useLazyQuery<FetchSettingCheckAccountTypeByDomainType>(
		FETCH_SETTING_CHECK_ACCOUNT_TYPES_BY_DOMAIN,
	);

	const settingCheckAccountTypesDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<SettingDomainCheckAccountType>,
		): Promise<{
			count: number;
			items: SettingDomainCheckAccountType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchSettingCheckAccountTypesByDomain({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					domainId,
					search,
					checkType,
				},
			});

			if (error) {
				throw error;
			}

			const {
				quickentry_settingCheckAccountTypesByDomain: { count = 0, items = [] },
			} = data ?? {
				quickentry_settingCheckAccountTypesByDomain: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchSettingCheckAccountTypesByDomain, domainId, checkType],
	);

	const handleAddButtonClick = useCallback(() => onCheckAccountTypeSelection(), [onCheckAccountTypeSelection]);

	const handleRowClick = useCallback(
		(checkAccountType: SettingDomainCheckAccountType) => onCheckAccountTypeSelection(checkAccountType),
		[onCheckAccountTypeSelection],
	);

	return (
		<SettingsTable<SettingDomainCheckAccountType>
			dataSource={settingCheckAccountTypesDataSource}
			onAddButtonClick={handleAddButtonClick}
			onRowClick={handleRowClick}
			tableColumns={columns}
			title="Types de compte"
		/>
	);
};

export default CheckAccountTypesDomainTable;
