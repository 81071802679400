import { useMutation, useQuery } from '@apollo/client';
import { Button, SettingsGroup, SettingsItemTextField } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { DomainType } from '../../../../../../types/domain';
import { generateErrorInformations } from '../../../../../../utils/errorHandler';
import {
	CREATE_DOMAIN_BILL_EXCHANGE_SETTING_JOURNAL,
	CREATE_DOMAIN_CHECK_SETTING_JOURNAL,
	CreateDomainBillExchangeSettingJournalType,
	CreateDomainCheckSettingJournalType,
	FETCH_DOMAIN_BILL_EXCHANGE_SETTING_JOURNAL,
	FETCH_DOMAIN_CHECK_SETTING_JOURNAL,
	FetchDomainBillExchangeSettingJournalType,
	FetchDomainCheckSettingJournalType,
} from '../../../../api/settingJournal';
import { CheckTypeEnum } from '../../../../types/check';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormFieldType = {
	billExchangeJournalCode?: string;
	checkIssuanceJournalCode?: string;
	checkRemittanceJournalCode?: string;
};

type JournalCodesProps = {
	domainId?: DomainType['id'];
};

const JournalCodes = ({ domainId }: JournalCodesProps) => {
	const {
		control,
		formState: { dirtyFields, isDirty, isValid },
		handleSubmit,
		setValue,
		reset,
	} = useForm<FormFieldType>();

	const isFormValid = useMemo(() => isDirty && isValid, [isDirty, isValid]);

	const [error, setError] = useState<string>();
	const [isCreateDomainBillExchangeJournalSucceeded, setIsCreateDomainBillExchangeJournalSucceeded] = useState(false);

	useQuery<FetchDomainBillExchangeSettingJournalType>(FETCH_DOMAIN_BILL_EXCHANGE_SETTING_JOURNAL, {
		onCompleted: ({ quickentry_settingDomainBillExchangeJournal: { currentCodeValue } }) =>
			setValue('billExchangeJournalCode', currentCodeValue),
		onError: (queryError) =>
			setError(
				generateErrorInformations({ error: queryError, resource: 'quickentry_settingDomainBillExchangeJournal' })
					.message,
			),
		skip: !domainId,
		variables: {
			id: domainId,
		},
	});

	const [createDomainBillExchangeJournal] = useMutation<CreateDomainBillExchangeSettingJournalType>(
		CREATE_DOMAIN_BILL_EXCHANGE_SETTING_JOURNAL,
		{
			onCompleted: () => {
				setIsCreateDomainBillExchangeJournalSucceeded(true);
				setTimeout(() => setIsCreateDomainBillExchangeJournalSucceeded(false), 3000);
			},
		},
	);

	useQuery<FetchDomainCheckSettingJournalType>(FETCH_DOMAIN_CHECK_SETTING_JOURNAL, {
		onCompleted: ({ quickentry_settingDomainCheckJournal: { currentCodeValue } }) =>
			setValue('checkIssuanceJournalCode', currentCodeValue),
		onError: (queryError) =>
			setError(
				generateErrorInformations({ error: queryError, resource: 'quickentry_settingDomainCheckJournal' }).message,
			),
		skip: !domainId,
		variables: {
			id: domainId,
			checkType: CheckTypeEnum.CHECK_ISSUANCE,
		},
	});

	useQuery<FetchDomainCheckSettingJournalType>(FETCH_DOMAIN_CHECK_SETTING_JOURNAL, {
		onCompleted: ({ quickentry_settingDomainCheckJournal: { currentCodeValue } }) =>
			setValue('checkRemittanceJournalCode', currentCodeValue),
		onError: (queryError) =>
			setError(
				generateErrorInformations({ error: queryError, resource: 'quickentry_settingDomainCheckJournal' }).message,
			),
		skip: !domainId,
		variables: {
			id: domainId,
			checkType: CheckTypeEnum.CHECK_REMITTANCE,
		},
	});

	const [createDomainCheckJournal] = useMutation<CreateDomainCheckSettingJournalType>(
		CREATE_DOMAIN_CHECK_SETTING_JOURNAL,
	);

	const onSubmit = useCallback(
		(values: FormFieldType) => {
			const {
				billExchangeJournalCode: billExchangeJournalCodeValue,
				checkIssuanceJournalCode: checkIssuanceJournalCodeValue,
				checkRemittanceJournalCode: checkRemittanceJournalCodeValue,
			} = values;

			const {
				billExchangeJournalCode: isBillExchangeJournalCodeDirty,
				checkIssuanceJournalCode: isCheckIssuanceJournalCodeDirty,
				checkRemittanceJournalCode: isCheckRemittanceJournalCodeDirty,
			} = dirtyFields;

			const submitActions = [];

			isBillExchangeJournalCodeDirty &&
				submitActions.push(
					createDomainBillExchangeJournal({
						variables: {
							createSettingDomainBillExchangeJournalInput: {
								domainId,
								journalCode: billExchangeJournalCodeValue,
							},
						},
					}),
				);

			isCheckIssuanceJournalCodeDirty &&
				submitActions.push(
					createDomainCheckJournal({
						variables: {
							createSettingDomainCheckJournalInput: {
								domainId,
								journalCode: checkIssuanceJournalCodeValue,
								checkType: CheckTypeEnum.CHECK_ISSUANCE,
							},
						},
					}),
				);

			isCheckRemittanceJournalCodeDirty &&
				submitActions.push(
					createDomainCheckJournal({
						variables: {
							createSettingDomainCheckJournalInput: {
								domainId,
								journalCode: checkRemittanceJournalCodeValue,
								checkType: CheckTypeEnum.CHECK_REMITTANCE,
							},
						},
					}),
				);

			(async () => {
				await Promise.all(submitActions);

				reset({
					billExchangeJournalCode: billExchangeJournalCodeValue,
					checkIssuanceJournalCode: checkIssuanceJournalCodeValue,
					checkRemittanceJournalCode: checkRemittanceJournalCodeValue,
				});
			})().catch((e) => {
				throw e;
			});
		},
		[createDomainBillExchangeJournal, createDomainCheckJournal, reset, dirtyFields, domainId],
	);

	return (
		<SettingsDialogPage title="Journaux">
			<SettingsGroup>
				<Controller
					control={control}
					name="checkIssuanceJournalCode"
					render={({ field }) => <SettingsItemTextField {...field} label='Code journal "Chèques émis"' />}
				/>
				<Controller
					control={control}
					name="checkRemittanceJournalCode"
					render={({ field }) => <SettingsItemTextField {...field} label='Code journal "Remise de chèques"' />}
				/>
				<Controller
					control={control}
					name="billExchangeJournalCode"
					render={({ field }) => <SettingsItemTextField {...field} label='Code journal "LCR émises"' />}
				/>
			</SettingsGroup>

			<ActionWrapper>
				<Button disabled={!isFormValid} onClick={handleSubmit(onSubmit)} variant="contained">
					{isCreateDomainBillExchangeJournalSucceeded ? 'LCR validée' : 'Valider'}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</ActionWrapper>
		</SettingsDialogPage>
	);
};

export default JournalCodes;
