import { gql } from '@apollo/client';

import { CustomerFileType } from '../../types/customerFile';
import { SynchronizationJournalExclusionSetting } from '../types/synchronizationJournalExclusionSetting';

const SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING = `
	id
	code
	label
`;

export type FetchSynchronizationJournalSettingsType = {
	synchronizationJournalExclusionSettings: {
		items: SynchronizationJournalExclusionSetting[];
		count: number;
		limit: number;
		offset: number;
	};
};
export const FETCH_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTINGS = gql`
	query SynchronizationJournalExclusionSettings($customerFileId: ID!, $search: String, $page: Page, $orderBy: OrderBy) {
		synchronizationJournalExclusionSettings(
			customerFileId: $customerFileId
			page: $page
			search: $search
			orderBy: $orderBy
		) {
			count
			items {
				${SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING}
			}
			limit
			offset
		}
	}
`;

// MUTATIONS

export type CreateSynchronizationJournalExclusionSettingType = {
	createSynchronizationJournalExclusionSetting: SynchronizationJournalExclusionSetting;
};

export type CreateSynchronizationJournalExclusionSettingVariablesType = {
	createSynchronizationJournalExclusionSettingInput: {
		customerFileId: CustomerFileType['id'];
		code: SynchronizationJournalExclusionSetting['code'];
		label: SynchronizationJournalExclusionSetting['label'];
	};
};

export const CREATE_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING = gql`
	mutation CreateSynchronizationJournalExclusionSetting(
		$createSynchronizationJournalExclusionSettingInput: CreateSynchronizationJournalExclusionSettingInput!
	) {
		createSynchronizationJournalExclusionSetting(
			createSynchronizationJournalExclusionSettingInput: $createSynchronizationJournalExclusionSettingInput
		) {
			id
		}
	}
`;

export type UpdateSynchronizationJournalExclusionSettingType = {
	updateSynchronizationJournalExclusionSetting: SynchronizationJournalExclusionSetting;
};

export type UpdateSynchronizationJournalExclusionSettingVariablesType = {
	updateSynchronizationJournalExclusionSettingInput: {
		id: SynchronizationJournalExclusionSetting['id'];
		label: SynchronizationJournalExclusionSetting['label'];
	};
};

export const UPDATE_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING = gql`
	mutation UpdateSynchronizationJournalExclusionSetting(
		$updateSynchronizationJournalExclusionSettingInput: UpdateSynchronizationJournalExclusionSettingInput!
	) {
		updateSynchronizationJournalExclusionSetting(
			updateSynchronizationJournalExclusionSettingInput: $updateSynchronizationJournalExclusionSettingInput
		) {
			id
		}
	}
`;

export type RemoveSynchronizationJournalExclusionSetting = {
	removeSynchronizationJournalExclusionSetting: {
		id: SynchronizationJournalExclusionSetting['id'];
	};
};
export type RemoveSynchronizationJournalExclusionSettingVariablesType = {
	id: SynchronizationJournalExclusionSetting['id'];
};
export const REMOVE_SYNCHRONIZATION_JOURNAL_EXCLUSION_SETTING = gql`
	mutation RemoveSynchronizationJournalExclusionSetting($id: ID!) {
		removeSynchronizationJournalExclusionSetting(id: $id) {
			id
		}
	}
`;
