import { useLazyQuery } from '@apollo/client';
import { Chip, TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { useCallback } from 'react';

import CustomerFileSynchronize from './CustomerFileSynchronize';
import LinkIcon from '../../../components/LinkIcon';
import SettingsDialogPage from '../../../components/SettingsDialogPage';
import SettingsTable from '../../../components/SettingsTable';
import { useSettingsDialog } from '../../../hooks/useSettingsDialog';
import { CustomerFileWithDomainType } from '../../../types/customerFile';
import { FETCH_CUSTOMER_FILES_WITH_DOMAIN, FetchCustomerFilesWithDomainType } from '../../api/customerFile';

const columns: TableColumnType<CustomerFileWithDomainType>[] = [
	{
		field: 'code',
		flexGrow: 0,
		key: 'code',
		sortable: true,
		title: 'Code',
		width: 100,
	},
	{
		field: 'name',
		key: 'name',
		sortable: true,
		title: 'Nom du dossier',
		width: 400,
	},
	{
		key: 'chip',
		render: ({ domain: { isDefault, name } }) => <Chip label={name} color={isDefault ? 'info' : 'default'} />,
		title: 'Domaine',
		width: 150,
	},
	{
		key: 'actions',
		flexGrow: 0,
		render: () => <LinkIcon />,
		width: 40,
	},
];

const Synchronizations = () => {
	const { push } = useSettingsDialog();

	const [fetchCustomerFilesWithDomain] = useLazyQuery<FetchCustomerFilesWithDomainType>(
		FETCH_CUSTOMER_FILES_WITH_DOMAIN,
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<CustomerFileWithDomainType>,
		): Promise<{
			count: number;
			items: CustomerFileWithDomainType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchCustomerFilesWithDomain({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				customerFiles: { count = 0, items = [] },
			} = data ?? {
				customerFiles: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchCustomerFilesWithDomain],
	);

	const navigateToCustomerFileSynchronization = (customerFile: CustomerFileWithDomainType) => {
		push(<CustomerFileSynchronize customerFile={customerFile} />);
	};

	return (
		<SettingsDialogPage title="Synchronisations">
			<SettingsTable<CustomerFileWithDomainType>
				onRowClick={navigateToCustomerFileSynchronization}
				dataSource={dataSource}
				tableColumns={columns}
				title="Dossiers clients"
				height="100%"
			/>
		</SettingsDialogPage>
	);
};

export default Synchronizations;
