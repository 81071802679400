import {
	AutocompleteFilterDefinitionType,
	CurrencyFilterDefinitionType,
	PeriodFilterDefinitionType,
} from '@elipssolution/harfang';

import { AccountTypeWithMasterAccount } from './account';
import { BankType } from './bank';
import { CheckAccountTypeType } from './checkAccountType';

export type CheckIssuanceType = {
	id: string;
	account: AccountTypeWithMasterAccount;
	amount: string;
	bank: BankType;
	checkAccountType: CheckAccountTypeType;
	documentDate: Date;
	documentNumber: string;
	name: string;
};
export type CheckRemittanceType = {
	id: string;
	amount: string;
	bank: BankType;
	date: Date;
	number: string;
	checks: CheckRemittanceTransactionType[];
};

export type CheckRemittanceFormType = CheckRemittanceType & {
	bankName: BankType['name'];
	checks: CheckRemittanceTransactionType[];
};

export type CheckRemittanceTableType = Omit<CheckRemittanceType, 'number' | 'date'> & {
	documentDate: Date;
	documentNumber: string;
};

export type CheckRemittanceTransactionType = {
	amount: CheckIssuanceType['amount'];
	label: CheckIssuanceType['name'];
	account: CheckIssuanceType['account'] | null;
	checkAccountType: CheckAccountTypeType | null;
	__typename?: string;
};

export type CheckFilterType = {
	amount: CurrencyFilterDefinitionType;
	bank: AutocompleteFilterDefinitionType<BankType>;
	documentDate: PeriodFilterDefinitionType;
};

export enum CheckTypeEnum {
	CHECK_ISSUANCE = 'CHECK_ISSUANCE',
	CHECK_REMITTANCE = 'CHECK_REMITTANCE',
}
