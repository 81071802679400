import { Icon, IconButton, TableColumnType, Tooltip } from '@elipssolution/harfang';
import { mdiDelete } from '@mdi/js';
import { useCallback, useMemo, MouseEvent } from 'react';

import LinkIcon from '../../../../components/LinkIcon';
import SettingsTable from '../../../../components/SettingsTable';
import { SynchronizationJournalExclusionSetting } from '../../../types/synchronizationJournalExclusionSetting';

type CurrentSynchronizationJournalExclusionSettingSectionProps = {
	synchronizationJournalExclusions: SynchronizationJournalExclusionSetting[];
	onRemoveSynchronizationJournalExclusion: (exclusion: SynchronizationJournalExclusionSetting['id']) => Promise<void>;
	onSynchronizationJournalExclusionToUpdateChange: (
		onSynchronizationJournalExclusionToUpdateChange?: SynchronizationJournalExclusionSetting,
	) => void;
};

const CurrentSynchronizationJournalExclusionSettingSection = ({
	synchronizationJournalExclusions,
	onRemoveSynchronizationJournalExclusion,
	onSynchronizationJournalExclusionToUpdateChange,
}: CurrentSynchronizationJournalExclusionSettingSectionProps) => {
	const removeSynchronizationJournalExclusion = useCallback(
		async (
			event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
			exclusionId: SynchronizationJournalExclusionSetting['id'],
		) => {
			event.stopPropagation();
			await onRemoveSynchronizationJournalExclusion(exclusionId);
		},
		[onRemoveSynchronizationJournalExclusion],
	);

	const columns: TableColumnType<SynchronizationJournalExclusionSetting>[] = useMemo(
		() => [
			{
				field: 'code',
				key: 'code',
				title: 'Code',
				width: 200,
				flexGrow: 0,
			},
			{
				field: 'label',
				key: 'label',
				title: 'Libellé',
				width: 300,
			},

			{
				key: 'actions',
				flexGrow: 0,
				render: ({ id }) => (
					<Tooltip content="Supprimer l'exclusion">
						<IconButton onClick={(event) => removeSynchronizationJournalExclusion(event, id)}>
							<Icon path={mdiDelete} />
						</IconButton>
					</Tooltip>
				),
				width: 40,
			},
			{
				key: 'navigate',
				flexGrow: 0,
				render: () => <LinkIcon />,
				width: 40,
			},
		],
		[removeSynchronizationJournalExclusion],
	);

	const handleRowClick = useCallback(
		(exclusion: SynchronizationJournalExclusionSetting) => {
			onSynchronizationJournalExclusionToUpdateChange(exclusion);
		},
		[onSynchronizationJournalExclusionToUpdateChange],
	);

	const dataSource = useCallback(
		async (): Promise<{
			count: number;
			items: SynchronizationJournalExclusionSetting[];
		}> =>
			Promise.resolve({
				items: synchronizationJournalExclusions,
				count: synchronizationJournalExclusions.length,
			}),
		[synchronizationJournalExclusions],
	);

	return (
		<SettingsTable<SynchronizationJournalExclusionSetting>
			dataSource={dataSource}
			enableSearch={false}
			tableColumns={columns}
			title="Journaux exclus"
			onRowClick={handleRowClick}
		/>
	);
};

export default CurrentSynchronizationJournalExclusionSettingSection;
