import { useQuery } from '@apollo/client';

import { FETCH_CONNECTOR, FetchConnectorType, FetchConnectorVariablesType } from '../api/connector';

type UseDocumentConnectorProps = {
	variables: FetchConnectorVariablesType;
};

const useDocumentConnector = ({ variables }: UseDocumentConnectorProps) => {
	const { data, error, loading } = useQuery<FetchConnectorType, FetchConnectorVariablesType>(FETCH_CONNECTOR, {
		variables,
	});

	const { document_connector: connector } = data ?? {};

	return { connector, error, loading };
};

export default useDocumentConnector;
