import { useLazyQuery } from '@apollo/client';
import { TableColumnType, TableInstance } from '@elipssolution/harfang';
import { Checkbox } from '@mui/material';
import { ChangeEvent, useCallback, useMemo } from 'react';

import SettingsTable from '../../../../components/SettingsTable';
import { CustomerFileType } from '../../../../types/customerFile';
import { FETCH_CUSTOMER_FILES, FetchCustomerFilesType } from '../../../api/customerFile';

type AddCustomerFilesTableProps = {
	selectedCustomerFiles: CustomerFileType[];
	onSelectedCustomerFilesChange: (newSelectedCustomerFiles: CustomerFileType[]) => void;
	tableInstance: TableInstance;
};

const AddCustomerFilesTable = ({
	selectedCustomerFiles,
	onSelectedCustomerFilesChange,
	tableInstance,
}: AddCustomerFilesTableProps) => {
	const [fetchCustomerFiles] = useLazyQuery<FetchCustomerFilesType>(FETCH_CUSTOMER_FILES);

	const customerFileDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			items: CustomerFileType[];
			count: number;
		}> => {
			const { data: { customerFiles } = {}, error } = await fetchCustomerFiles({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const { count = 0, items = [] } = customerFiles ?? {};

			return {
				count,
				items,
			};
		},
		[fetchCustomerFiles],
	);

	const handleCustomerFileSelection = useCallback(
		({ customerFile, checked }: { customerFile: CustomerFileType; checked: boolean }) => {
			// If the entry already exists in the temporary table state
			if (!checked) {
				onSelectedCustomerFilesChange(selectedCustomerFiles.filter(({ id }) => id !== customerFile.id));
			}

			// If the entry does not exist in the temporary table state and the associated group hasn't been changed
			if (checked && !selectedCustomerFiles.some(({ id }) => id === customerFile.id)) {
				onSelectedCustomerFilesChange([...selectedCustomerFiles, customerFile]);
			}

			// If the entry does not exist in the temporary table state but the associated group has already been changed
			if (checked && selectedCustomerFiles.some(({ id }) => id === customerFile.id)) {
				onSelectedCustomerFilesChange([
					...selectedCustomerFiles.filter(({ id }) => id !== customerFile.id),
					customerFile,
				]);
			}
		},
		[onSelectedCustomerFilesChange, selectedCustomerFiles],
	);

	const getIsCheckboxEnabled = useCallback(
		({ customerFileId }: { customerFileId: CustomerFileType['id'] }) =>
			selectedCustomerFiles.some(({ id }) => id === customerFileId),
		[selectedCustomerFiles],
	);

	const columns: TableColumnType<CustomerFileType>[] = useMemo(
		() => [
			{
				align: 'left',
				flexGrow: 0,
				key: 'check',
				render: (customerFile) => (
					<Checkbox
						checked={getIsCheckboxEnabled({ customerFileId: customerFile.id })}
						onChange={({ target: { checked } }: ChangeEvent<HTMLInputElement>) =>
							handleCustomerFileSelection({ customerFile, checked })
						}
					/>
				),
				width: 80,
			},
			{
				field: 'code',
				flexGrow: 0,
				key: 'code',
				title: 'Code',
				render: ({ code }) => code || 'TOUS',
				width: 100,
			},
			{
				field: 'name',
				key: 'name',
				title: 'Nom',
				render: ({ name }) => name || 'TOUS',
				width: 200,
			},
		],
		[getIsCheckboxEnabled, handleCustomerFileSelection],
	);

	return (
		<SettingsTable<CustomerFileType>
			table={tableInstance}
			tableColumns={columns}
			dataSource={customerFileDataSource}
			title="Dossiers clients"
			enableSearch
		/>
	);
};

export default AddCustomerFilesTable;
