import { GroupType } from './group';
import { PermissionModuleType, UserPermissionType } from './permission';
import { CustomerFileType } from '../../types/customerFile';

export enum UserTypeEnum {
	INTERNAL = 'INTERNAL',
	EXTERNAL = 'EXTERNAL',
}

export type UserType = {
	id: string;
	email: string;
	lastName: string;
	firstName?: string;
	type: UserTypeEnum;
	customerFiles?: CustomerFileType[];
};

type InternalUserBaseType = UserType & {
	isBillingManager?: boolean;
	group: {
		isAdministrator: boolean;
		isDefault: boolean;
		id: string;
		name: string;
	};
	hasUserPermissions: boolean;
};

export type InternalUserType = InternalUserBaseType & {
	permissionModules?: PermissionModuleType<UserPermissionType>[];
};

export type AddInternalUserType = Omit<InternalUserType, 'type' | 'hasUserPermissions'>;

export type AddInternalUserRelationWithCustomerFilesType = {
	user: AddInternalUserType;
	customerFiles: CustomerFileType[];
};

export type ExternalUserType = UserType;

export type AddExternalUserRelationType = {
	user: Omit<ExternalUserType, 'type'>;
	group?: GroupType;
};

export type AddExternalUserRelationWithCustomerFilesType = {
	user: Omit<ExternalUserType, 'id' | 'type'> & { id?: ExternalUserType['id']; type?: ExternalUserType['type'] };
	customerFiles: {
		customerFile: CustomerFileType;
		group?: GroupType;
	}[];
};

export type InternalUserFormType = Pick<
	InternalUserType,
	'lastName' | 'firstName' | 'email' | 'group' | 'isBillingManager'
>;

export type CreateInternalUserFormType = InternalUserFormType & {
	isUserCreatedWithBrochurePermissionsFromInternalContactRoles?: boolean;
};
