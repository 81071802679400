import { Icon } from '@elipssolution/harfang';
import { mdiCheckbookArrowLeft, mdiCheckbookArrowRight, mdiMail, mdiReceiptText } from '@mdi/js';

import { NavigationItemType } from '../../../types/module';
import { PermissionEnum } from '../../../types/permission';

const pages: NavigationItemType[] = [
	{
		icon: <Icon path={mdiReceiptText} />,
		name: 'Pièces',
		route: '/voucher',
		permissionCodes: [PermissionEnum.QUICKENTRY_VOUCHER_READ, PermissionEnum.QUICKENTRY_VOUCHER_WRITE],
	},
	{
		icon: <Icon path={mdiCheckbookArrowRight} />,
		name: 'Chèques émis',
		route: '/checkIssuance',
		permissionCodes: [PermissionEnum.QUICKENTRY_CHECK_ISSUANCE_READ, PermissionEnum.QUICKENTRY_CHECK_ISSUANCE_WRITE],
	},

	{
		icon: <Icon path={mdiCheckbookArrowLeft} />,
		name: 'Remise de chèques',
		route: '/checkRemittance',
		permissionCodes: [
			PermissionEnum.QUICKENTRY_CHECK_REMITTANCE_READ,
			PermissionEnum.QUICKENTRY_CHECK_REMITTANCE_WRITE,
		],
	},
	{
		icon: <Icon path={mdiMail} />,
		name: 'LCR émises',
		route: '/billExchange',
		permissionCodes: [PermissionEnum.QUICKENTRY_BILL_EXCHANGE_READ, PermissionEnum.QUICKENTRY_BILL_EXCHANGE_WRITE],
	},
];

export default pages;
