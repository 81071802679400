export type SimulatorType = {
	type?: string;
	name: string;
};

export const simulators: SimulatorType[] = [
	{
		type: 'vat-ttc-ht',
		name: 'TVA/TTC/HT',
	},
	{
		type: 'margin-coefficient',
		name: 'Coefficient de marge',
	},
	{
		type: 'gross-net-salary',
		name: 'Salaire Brut/Net',
	},
	{
		type: 'loan-simulation',
		name: 'Simulation Emprunt',
	},
	{
		type: 'intracommunity-vat',
		name: 'TVA Intracommunautaire',
	},
	{
		type: 'Kilometric-expenses',
		name: 'Frais kilométriques',
	},
];
