import { SVGProps } from 'react';

const VatTtcHtSVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 24 24" height={60} width={60} {...props}>
		<path d="M7 2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2m0 2v4h10V4H7m0 6v2h2v-2H7m4 0v2h2v-2h-2m4 0v2h2v-2h-2m-8 4v2h2v-2H7m4 0v2h2v-2h-2m4 0v2h2v-2h-2m-8 4v2h2v-2H7m4 0v2h2v-2h-2m4 0v2h2v-2h-2Z" />
		<path
			d="M9.502 7.115c-.416 0-.78-.237-.96-.584h.96l.167-.333H8.432a1.105 1.105 0 0 1 0-.333h1.07l.167-.334H8.542a1.082 1.082 0 0 1 1.665-.321l.295-.295a1.493 1.493 0 0 0-1-.384c-.653 0-1.206.417-1.416 1h-.584l-.166.334h.676c-.01.055-.01.11-.01.166 0 .057 0 .112.01.167h-.51l-.166.333h.75c.21.584.763 1 1.416 1a1.49 1.49 0 0 0 1-.383l-.296-.295a1.068 1.068 0 0 1-.704.262Z"
			style={{
				strokeWidth: 0.166666,
			}}
		/>
		<circle
			cx={17.363}
			cy={17.978}
			r={4}
			style={{
				fill: '#fff',
				strokeWidth: 1.73212,
			}}
		/>
		<path
			d="M19.363 19.266a.71.71 0 1 1-1.42.004.71.71 0 0 1 1.42-.004m-3.268-1.848a.707.707 0 0 0 .708-.708.708.708 0 0 0-.708-.712.708.708 0 0 0-.712.712c0 .392.316.708.712.708m-.732 1.972.6.6 3.412-3.412-.6-.6-3.412 3.412m6-1.412c0 2.2-1.8 4-4 4s-4-1.8-4-4 1.8-4 4-4 4 1.8 4 4m-.8 0c0-1.764-1.436-3.2-3.2-3.2a3.204 3.204 0 0 0-3.2 3.2c0 1.764 1.436 3.2 3.2 3.2 1.764 0 3.2-1.436 3.2-3.2z"
			style={{
				strokeWidth: 0.4,
			}}
		/>
	</svg>
);
export default VatTtcHtSVG;
