import { useMutation, useQuery } from '@apollo/client';
import {
	Button,
	Chip,
	ConfirmationDialog,
	Dialog,
	DialogProps,
	Icon,
	List,
	Table,
	TableColumnType,
	TableInstance,
} from '@elipssolution/harfang';
import { mdiAlertCircle, mdiEarth, mdiEmailArrowRight, mdiLock } from '@mdi/js';
import { CircularProgress, ListItemText, Stack, styled, Typography } from '@mui/material';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { useCallback, useMemo, useState } from 'react';

import SignersAvatarGroup from './SignersAvatarGroup';
import { useSession } from '../../../src/components/SessionProvider';
import { DIALOG_CLOSE_DELAY } from '../../../src/utils/dialogCloseDelay';
import { getFileBlob, openBlobInNewTab } from '../../../src/utils/file';
import { generateErrorInformations } from '../../../utils/errorHandler';
import {
	CANCEL_PROCEDURE,
	CancelProcedureType,
	FETCH_PROCEDURE,
	FetchProcedureType,
	REMIND_PROCEDURE_AWAITED_SIGNERS,
	RemindProcedureAwaitedSignersType,
} from '../api/procedure';
import { ProcedureDocumentType } from '../types/document';
import { ProcedureStatusEnum, ProcedureType } from '../types/procedure';
import { SignerType } from '../types/signer';

const StyledListItemText = styled(ListItemText)({
	textAlign: 'left',
});
enum ConfirmationDialogTypeEnum {
	CANCEL = 'cancel',
	REMIND_AWAITED_SIGNERS = 'remindAwaitedSigners',
}

const dialogStatusMapper: Map<ProcedureStatusEnum, { label: string; color: string }> = new Map([
	[ProcedureStatusEnum.CANCELED, { label: 'Annulée', color: 'warning' }],
	[ProcedureStatusEnum.DECLINED, { label: 'Rejetée', color: 'error' }],
	[ProcedureStatusEnum.DONE, { label: 'Terminée', color: 'success' }],
	[ProcedureStatusEnum.DRAFT, { label: 'Brouillon', color: 'default' }],
	[ProcedureStatusEnum.ONGOING, { label: 'En cours', color: 'primary' }],
]);

const documentColumns: TableColumnType<ProcedureDocumentType>[] = [
	{
		field: 'filename',
		key: 'filename',
		title: 'Nom',
		width: 200,
	},
	{
		field: 'signers',
		key: 'signers',
		title: 'Signataires',
		render: ({ signers }) => <SignersAvatarGroup signers={signers} />,
		width: 100,
	},
];

type ProcedureActionConfirmationDialogProps = {
	confirmationDialogType?: ConfirmationDialogTypeEnum;
	errorMessage?: string;
	open: boolean;
	title: string;
	isDisabled: boolean;
	isLoading: boolean;
	isSuccess: boolean;
	onValidate: () => void;
	onClose: () => void;
};

const ProcedureActionConfirmationDialog = ({
	confirmationDialogType,
	errorMessage,
	open,
	title,
	isDisabled,
	isLoading,
	isSuccess,
	onValidate,
	onClose,
}: ProcedureActionConfirmationDialogProps) => {
	const confirmationDialogButtonLabel = useMemo(() => {
		if (confirmationDialogType === ConfirmationDialogTypeEnum.CANCEL)
			return isSuccess ? 'Procédure annulée' : 'Annuler la procédure';
		return isSuccess ? 'Signataires relancés' : 'Relancer les signataires';
	}, [confirmationDialogType, isSuccess]);
	const actionsConfirmationDialog = useMemo(
		() =>
			[
				{
					disabled: isLoading,
					label: 'Annuler',
					onClick: onClose,
				},
				{
					disabled: isDisabled,
					error: !!errorMessage,
					persistantErrorMessage: errorMessage,
					label: confirmationDialogButtonLabel,
					...(confirmationDialogType === 'remindAwaitedSigners' && { startIcon: <Icon path={mdiEmailArrowRight} /> }),
					loading: isLoading,
					variant: 'outlined',
					color: confirmationDialogType === ConfirmationDialogTypeEnum.CANCEL ? 'warning' : 'inherit',
					success: isSuccess,
					onClick: onValidate,
				},
			] as DialogProps['actionsDialog'],
		[
			confirmationDialogButtonLabel,
			confirmationDialogType,
			errorMessage,
			isDisabled,
			isLoading,
			isSuccess,
			onClose,
			onValidate,
		],
	);

	return <ConfirmationDialog actionsDialog={actionsConfirmationDialog} open={open} onClose={onClose} title={title} />;
};

const signersRowRenderer = ({ firstName, lastName, email }: SignerType) => (
	<Stack flexDirection="row" gap={1} alignItems="center" paddingRight={1} paddingLeft={1}>
		<StyledListItemText
			primary={`${firstName}
			${lastName}`}
			secondary={email}
		/>
	</Stack>
);

const ProcedureStatusChip = ({ status }: { status: ProcedureStatusEnum }) => {
	const { color, label } = dialogStatusMapper.get(status) ?? {};

	return <Chip color={color} label={label} />;
};

const LoadingWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	gap: spacing(2),
}));

const ErrorWrapper = styled('div')(({ theme: { spacing, palette, shape } }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: spacing(2),
	gap: spacing(2),
	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	border: `1px solid ${palette.error.main}`,
	borderRadius: shape.borderRadius * 2,
	width: 'fit-content',
	margin: 'auto',
}));

const SignersAndDocumentsWrapper = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	gap: spacing(3),

	height: 400,
}));

type ProcedureConsultationDialogProps = {
	procedureId?: ProcedureType['id'];
	tableInstance?: TableInstance;
	onClose: () => void;
};

const ProcedureConsultationDialog = ({ procedureId, tableInstance, onClose }: ProcedureConsultationDialogProps) => {
	const { customerFile: sessionCustomerFile } = useSession();
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};
	const [isCancelProcedureSuccess, setIsCancelProcedureSuccess] = useState(false);
	const [cancelProcedureError, setCancelProcedureError] = useState<string>();
	const [isRemindProcedureAwaitedSignersSuccess, setIsRemindProcedureAwaitedSignersSuccess] = useState(false);
	const [remindProcedureAwaitedSignersError, setRemindProcedureAwaitedSignersError] = useState<string>();
	const [fetchProcedureError, setFetchProcedureError] = useState<string>();
	const [confirmationDialogType, setConfirmationDialogType] = useState<ConfirmationDialogTypeEnum>();

	const {
		data: procedureData,
		loading: isFetchProcedureLoading,
		refetch: refetchProcedure,
	} = useQuery<FetchProcedureType>(FETCH_PROCEDURE, {
		onError: (mutationError) =>
			setFetchProcedureError(generateErrorInformations({ error: mutationError, resource: 'sign_procedure' }).message),
		variables: {
			signProcedureId: procedureId,
		},
		skip: !procedureId,
	});

	const { name, isPrivate, status, comment, signers = [], documents = [] } = procedureData?.sign_procedure || {};

	const proceduresSignersDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: SignerType[];
		}> => {
			const displayedSigners = signers.filter((contact) => {
				const fullName = `${contact.firstName} ${contact.lastName}`
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '');
				return search
					? fullName.includes(
							search
								.toLowerCase()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, ''),
					  )
					: signers;
			});
			return Promise.resolve({
				items: displayedSigners.slice(offset, offset + limit),
				count: displayedSigners.length,
			});
		},
		[signers],
	);

	const proceduresDocumentsDataSource = useCallback(
		async (): Promise<{
			items: ProcedureDocumentType[];
			count: number;
		}> =>
			Promise.resolve({
				items: documents,
				count: documents.length,
			}),
		[documents],
	);

	const dialogTitle = useMemo(
		() =>
			!isFetchProcedureLoading &&
			!fetchProcedureError && (
				<Stack flexDirection="row" gap={2} marginRight={2} alignItems="center">
					<Icon path={isPrivate ? mdiLock : mdiEarth} />
					{name}
					{status && <ProcedureStatusChip status={status} />}
				</Stack>
			),
		[isFetchProcedureLoading, fetchProcedureError, isPrivate, name, status],
	);

	const [cancelProcedure, { loading: isCancelProcedureLoading }] = useMutation<CancelProcedureType>(CANCEL_PROCEDURE, {
		onCompleted: () => {
			setIsCancelProcedureSuccess(true);
			setTimeout(() => setIsCancelProcedureSuccess(false), 3000);
			if (procedureId)
				refetchProcedure().catch(() => {
					throw new Error('An error occurred while canceling the procedure.');
				});
			tableInstance?.reload();
		},
		onError: (mutationError) =>
			setCancelProcedureError(
				generateErrorInformations({
					error: mutationError,
					resource: 'sign_cancelProcedure',
				}).message,
			),
	});

	const handleCancelProcedure = useCallback(
		async () =>
			cancelProcedure({
				variables: {
					signCancelProcedureId: procedureId,
				},
			}),
		[cancelProcedure, procedureId],
	);

	const [remindProcedureAwaitedSigners, { loading: isRemindProcedureAwaitedSignersLoading }] =
		useMutation<RemindProcedureAwaitedSignersType>(REMIND_PROCEDURE_AWAITED_SIGNERS, {
			onCompleted: () => {
				setIsRemindProcedureAwaitedSignersSuccess(true);
				setTimeout(() => setIsRemindProcedureAwaitedSignersSuccess(false), 3000);
			},
			onError: (mutationError) =>
				setRemindProcedureAwaitedSignersError(
					generateErrorInformations({
						error: mutationError,
						resource: 'sign_remindProcedureAwaitedSigners',
					}).message,
				),
		});

	const handleRemindProcedureAwaitedSigners = useCallback(
		async () =>
			remindProcedureAwaitedSigners({
				variables: {
					signRemindProcedureAwaitedSignersId: procedureId,
				},
			}),
		[procedureId, remindProcedureAwaitedSigners],
	);

	const handleDialogClose = useCallback(() => {
		onClose();

		setTimeout(() => setFetchProcedureError(undefined), 300);
	}, [onClose]);

	const closeRemoveProcedureConfirmationDialog = useCallback(() => {
		setConfirmationDialogType(undefined);

		setTimeout(() => {
			setIsCancelProcedureSuccess(false);
			setCancelProcedureError(undefined);
			setIsRemindProcedureAwaitedSignersSuccess(false);
			setRemindProcedureAwaitedSignersError(undefined);
		}, 300);
	}, []);

	const extraInfos = useMemo(
		(): DialogProps['extraInfos'] => [
			<Button key="cancel-button" onClick={handleDialogClose}>
				Annuler
			</Button>,
		],
		[handleDialogClose],
	);

	const actionsDialog = useMemo(
		() =>
			[
				...(status === ProcedureStatusEnum.ONGOING
					? [
							{
								disabled: isFetchProcedureLoading || fetchProcedureError,
								label: 'Annuler la procédure',
								variant: 'outlined',
								color: 'warning',
								onClick: () => setConfirmationDialogType(ConfirmationDialogTypeEnum.CANCEL),
							},
							{
								disabled: isFetchProcedureLoading || fetchProcedureError,
								startIcon: <Icon path={mdiEmailArrowRight} />,
								label: 'Relancer les signataires',
								variant: 'outlined',
								color: 'inherit',
								onClick: () => setConfirmationDialogType(ConfirmationDialogTypeEnum.REMIND_AWAITED_SIGNERS),
							},
					  ]
					: []),
			] as DialogProps['actionsDialog'],
		[fetchProcedureError, isFetchProcedureLoading, status],
	);

	const handleProcedureActionConfirmationDialogValidation = useCallback(async () => {
		const res =
			confirmationDialogType === ConfirmationDialogTypeEnum.CANCEL
				? await handleCancelProcedure()
				: await handleRemindProcedureAwaitedSigners();

		!res.errors && setTimeout(closeRemoveProcedureConfirmationDialog, DIALOG_CLOSE_DELAY);
	}, [
		closeRemoveProcedureConfirmationDialog,
		confirmationDialogType,
		handleCancelProcedure,
		handleRemindProcedureAwaitedSigners,
	]);

	const fetchDocumentFile = useCallback(
		async ({
			documentId: id,
			procedureId: currentProcedureId,
			fileName,
		}: {
			documentId: string;
			procedureId?: ProcedureType['id'];
			fileName?: string;
		}) => {
			if (!currentProcedureId) throw new Error('ProcedureId is missing.');
			if (!fileName) throw new Error('FileName is missing.');
			const documentBlob = await getFileBlob({
				url: `/sign/documents?id=${id}&procedureId=${currentProcedureId}`,
				accessToken: access_token,
				selectedCustomerFileId: sessionCustomerFile?.id,
			});

			return new File([documentBlob], `${fileName}`, { type: 'application/pdf' });
		},
		[access_token, sessionCustomerFile?.id],
	);

	const handleVisualizeDocument = useCallback(
		async ({ id, filename }: ProcedureDocumentType) => {
			const documentToVisualize = await fetchDocumentFile({
				documentId: id,
				procedureId,
				fileName: filename,
			});
			openBlobInNewTab(documentToVisualize);
		},
		[fetchDocumentFile, procedureId],
	);

	const procedureActionConfirmationDialogInfos = useMemo(
		() => ({
			confirmationDialogType,
			errorMessage: cancelProcedureError || remindProcedureAwaitedSignersError,
			open: !!confirmationDialogType,
			title: `Êtes-vous sûr de vouloir ${
				confirmationDialogType === ConfirmationDialogTypeEnum.CANCEL ? 'annuler' : 'relancer les signataires de'
			} la procédure ${name || ''} ?`,
			isDisabled:
				!!remindProcedureAwaitedSignersError ||
				!!cancelProcedureError ||
				isRemindProcedureAwaitedSignersSuccess ||
				isRemindProcedureAwaitedSignersLoading ||
				isFetchProcedureLoading ||
				!!fetchProcedureError ||
				isCancelProcedureLoading,
			error: !!cancelProcedureError || !!remindProcedureAwaitedSignersError,
			isLoading: isCancelProcedureLoading || isRemindProcedureAwaitedSignersLoading,
			isSuccess: isCancelProcedureSuccess || isRemindProcedureAwaitedSignersSuccess,
			onValidate: handleProcedureActionConfirmationDialogValidation,
			onClose: closeRemoveProcedureConfirmationDialog,
		}),
		[
			cancelProcedureError,
			closeRemoveProcedureConfirmationDialog,
			confirmationDialogType,
			fetchProcedureError,
			handleProcedureActionConfirmationDialogValidation,
			isCancelProcedureLoading,
			isCancelProcedureSuccess,
			isFetchProcedureLoading,
			isRemindProcedureAwaitedSignersLoading,
			isRemindProcedureAwaitedSignersSuccess,
			name,
			remindProcedureAwaitedSignersError,
		],
	);

	return (
		<Dialog
			title={dialogTitle}
			actionsDialog={actionsDialog}
			extraInfos={[extraInfos]}
			open={!!procedureId}
			onClose={handleDialogClose}
			fullWidth
		>
			{isFetchProcedureLoading && (
				<LoadingWrapper>
					<CircularProgress size={36} color="inherit" />
					<Typography>Chargement en cours...</Typography>
				</LoadingWrapper>
			)}

			{fetchProcedureError && (
				<ErrorWrapper>
					<Icon size={36} path={mdiAlertCircle} color="error" />
					<Typography>{fetchProcedureError}</Typography>
				</ErrorWrapper>
			)}

			{!isFetchProcedureLoading && !fetchProcedureError && (
				<Stack gap={3}>
					{comment && <Typography>{comment}</Typography>}

					<SignersAndDocumentsWrapper>
						<List<SignerType>
							searchPlaceholder="Signataires"
							style={{ flex: 1, marginTop: 24, marginBottom: 19 }}
							dataSource={proceduresSignersDataSource}
							rowRenderer={signersRowRenderer}
							isSearchEnabled
						/>

						<Table<ProcedureDocumentType>
							columns={documentColumns}
							onRowClick={(documentType) => {
								handleVisualizeDocument(documentType).catch(() => {
									throw new Error('An error occurred while visualizing the document.');
								});
							}}
							dataSource={proceduresDocumentsDataSource}
						/>
					</SignersAndDocumentsWrapper>
				</Stack>
			)}

			<ProcedureActionConfirmationDialog {...procedureActionConfirmationDialogInfos} />
		</Dialog>
	);
};

export default ProcedureConsultationDialog;
