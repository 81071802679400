import { SettingsGroup, SettingsItemNavigation } from '@elipssolution/harfang';
import { useCallback } from 'react';

import StorageDownload from './storageDownload/StorageDownload';
import StorageUpload from './storageUpload/StorageUpload';
import PermissionWall from '../../../components/PermissionWall';
import SettingsDialogPage from '../../../components/SettingsDialogPage';
import { useSettingsDialog } from '../../../hooks/useSettingsDialog';
import { PermissionEnum } from '../../../types/permission';

const DocumentSettings = () => {
	const { push } = useSettingsDialog();

	const navigateToStorageUploadSection = useCallback(() => push(<StorageUpload />), [push]);
	const navigateToStorageDownloadSection = useCallback(() => push(<StorageDownload />), [push]);

	return (
		<SettingsDialogPage title="Document">
			<SettingsGroup label="Paramétrage cabinet">
				<SettingsItemNavigation label="Dépôt" onClick={navigateToStorageUploadSection} />

				<PermissionWall permissionCodes={[PermissionEnum.DOCUMENT_SETTING_STORAGE]}>
					<SettingsItemNavigation label="Consultation GED" onClick={navigateToStorageDownloadSection} />
				</PermissionWall>
			</SettingsGroup>
		</SettingsDialogPage>
	);
};

export default DocumentSettings;
