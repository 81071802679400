import { Carousel } from '@elipssolution/harfang';
import { SxProps, Theme } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';

import { logoSize } from './SimulatorCard';
import SimulatorInstance from './SimulatorInstance';
import GrossNetSalarySVG from './simulators/GrossNetSalarySVG';
import IntracommunityVatSVG from './simulators/IntracommunityVatSVG';
import KilometricExpensesSVG from './simulators/KilometricExpensesSVG';
import LoanSimulationSVG from './simulators/LoanSimulationSVG';
import MarginCoefficientSVG from './simulators/MarginCoefficientSVG';
import VatTtcHtSVG from './simulators/VatTtcHtSVG';
import {
	WidgetComponentProps,
	WidgetExternalApplicationType,
	WidgetSimulatorsConfigurationType,
} from '../../../types/widget';
import { useSession } from '../../components/SessionProvider';
import { UserTypeEnum } from '../../types/user';
import GenericApplication from '../WidgetExternalApplications/applications/GenericApplication';

const arrowStyles: SxProps<Theme> = {
	'.alice-carousel__prev-btn, .alice-carousel__next-btn': {
		position: 'absolute',
		width: 'auto',
		top: logoSize / 2 + 8,
	},
	'.alice-carousel__prev-btn': {
		left: 0,
	},
	'.alice-carousel__next-btn': {
		right: 0,
	},
};

type WidgetExternalApplicationsProps = Omit<WidgetExternalApplicationType, 'isExternal' | 'isExternal'>;

const simulatorsMapper = new Map<string, { url: string; svgIcon: ReactNode; backgroundColor: string }>([
	[
		'vat-ttc-ht',
		{
			url: 'https://entreprendre.service-public.fr/simulateur/calcul/convertisseurPrixHTouTTC',
			svgIcon: <VatTtcHtSVG />,
			backgroundColor: '#002B66',
		},
	],
	[
		'margin-coefficient',
		{
			url: 'https://bgepro.bge.asso.fr/simulateur/CoefEtMarge.aspx',
			svgIcon: <MarginCoefficientSVG />,
			backgroundColor: '#FFA500',
		},
	],
	[
		'gross-net-salary',
		{
			url: 'https://code.travail.gouv.fr/outils/simulateur-embauche',
			svgIcon: <GrossNetSalarySVG />,
			backgroundColor: '#B873AB',
		},
	],
	[
		'loan-simulation',
		{
			url: 'https://www.lafinancepourtous.com/outils/calculateurs/calculateur-de-credit-immobilier/',
			svgIcon: <LoanSimulationSVG />,
			backgroundColor: '#228B22',
		},
	],
	[
		'intracommunity-vat',
		{
			url: 'https://annuaire-entreprises.data.gouv.fr/',
			svgIcon: <IntracommunityVatSVG />,
			backgroundColor: '#039BE5',
		},
	],
	[
		'Kilometric-expenses',
		{
			url: 'https://www.impots.gouv.fr/simulateur-bareme-kilometrique',
			svgIcon: <KilometricExpensesSVG />,
			backgroundColor: '#FF7F50',
		},
	],
]);

const getWidgetComponent = ({ key, name, type }: WidgetExternalApplicationsProps, readOnly: boolean) => {
	if (type) {
		const simulator = simulatorsMapper.get(type);
		if (simulator)
			return (
				<SimulatorInstance
					key={key}
					name={name}
					url={simulator.url}
					readOnly={readOnly}
					svgIcon={simulator.svgIcon}
					backgroundColor={simulator.backgroundColor}
				/>
			);
	}

	return <GenericApplication key={key} name={name} readOnly={readOnly} />;
};

const WidgetSimulator = ({ readOnly, configuration }: WidgetComponentProps<WidgetSimulatorsConfigurationType>) => {
	const {
		user: { type: userType },
	} = useSession();

	const carouselItems = useMemo(
		() =>
			(configuration?.simulators ?? [])
				.sort((a, b) => a.name.localeCompare(b.name))
				.reduce(
					(acc, simulator) =>
						(userType === UserTypeEnum.INTERNAL && !simulator.isInternal) ||
						(userType === UserTypeEnum.EXTERNAL && !simulator.isExternal)
							? acc
							: [...acc, getWidgetComponent(simulator, readOnly)],
					[] as ReactNode[],
				),
		[configuration, readOnly, userType],
	);

	const dataSource = useCallback(
		() =>
			Promise.resolve({
				items: carouselItems,
				count: carouselItems.length,
			}),
		[carouselItems],
	);

	const renderItem = useCallback((node?: ReactNode) => node ?? <div />, []);

	return (
		<Carousel<ReactNode>
			sx={arrowStyles}
			dataSource={dataSource}
			itemWidth={132}
			itemHeight={132}
			renderItem={renderItem}
		/>
	);
};

export default WidgetSimulator;
