import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';

import { FETCH_CONNECTORS, FetchConnectorsType, FetchConnectorsVariablesType } from '../api/connector';
import { ConnectorType } from '../types/connector';

type UseDocumentConnectorProps = {
	variables?: Pick<FetchConnectorsVariablesType, 'hasRead' | 'hasWrite'>;
};

const useDocumentConnectors = ({ variables }: UseDocumentConnectorProps = {}) => {
	const [fetchDocumentConnectors] = useLazyQuery<FetchConnectorsType, FetchConnectorsVariablesType>(FETCH_CONNECTORS);

	const documentConnectorsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: ConnectorType[];
		}> => {
			const { data, error: queryError } = await fetchDocumentConnectors({
				variables: {
					...(variables && variables),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (queryError) {
				throw queryError;
			}

			const {
				document_connectors: { count = 0, items = [] },
			} = data ?? { document_connectors: {} };

			return {
				count,
				items,
			};
		},
		[fetchDocumentConnectors, variables],
	);

	return { dataSource: documentConnectorsDataSource };
};

export default useDocumentConnectors;
