import { useLazyQuery } from '@apollo/client';
import { SettingsItemTextField } from '@elipssolution/harfang';
import { useCallback } from 'react';
import { Control, Controller, Path } from 'react-hook-form';

import { emailPattern } from '../../utils/emailPattern';
import { CHECK_USER_EMAIL_AVAILABILITY, CheckUserEmailAvailabilityType } from '../api/user';

let timeoutId: ReturnType<typeof setTimeout>;

type EmailFieldProps<FormType extends { email?: string }> = {
	control: Control<FormType>;
	disabled?: boolean;
	initialValue?: string;
};

const SettingsItemEmailField = <FormType extends { email?: string }>({
	control,
	disabled = false,
	initialValue,
}: EmailFieldProps<FormType>) => {
	const [checkUserEmailAvailability] = useLazyQuery<CheckUserEmailAvailabilityType>(CHECK_USER_EMAIL_AVAILABILITY);

	const validateAvailability = useCallback(
		(email?: string): Promise<boolean | string> =>
			new Promise((resolve, reject) => {
				clearTimeout(timeoutId);

				timeoutId = setTimeout(() => {
					if (initialValue === email || !email) {
						resolve(true);
						return;
					}

					checkUserEmailAvailability({ variables: { email } })
						.then(({ data }) => {
							const isEmailAvailable = data?.checkUserEmailAvailability;
							resolve(isEmailAvailable || 'Email déjà utilisé');
						})
						.catch(reject);
				}, 200);
			}),
		[checkUserEmailAvailability, initialValue],
	);

	return (
		<Controller
			name={'email' as Path<FormType>}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<SettingsItemTextField
					{...field}
					description="Email de l'utilisateur."
					disabled={disabled}
					invalid={!!error?.message}
					helperText={error?.message ?? ''}
					label="Email"
					required
				/>
			)}
			rules={{
				required: true,
				pattern: {
					value: emailPattern,
					message: 'Email invalide',
				},
				validate: validateAvailability,
			}}
		/>
	);
};

export default SettingsItemEmailField;
