import { BaseSettingsItem, Icon, IconButton, SettingsGroup, TextField, Tooltip } from '@elipssolution/harfang';
import { mdiMinusCircle, mdiPlusCircle } from '@mdi/js';
import { styled } from '@mui/material';
import { Control, useFieldArray } from 'react-hook-form';

import { ExclusionType, StorageDownloadFormType } from '../../types/storageDownloadFormType';

const ExclusionList = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(1),

	width: 250,
}));

const ExclusionWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',

	gap: theme.spacing(1),
}));

type ExclusionItemProps = {
	exclusion: ExclusionType;
	isLast: boolean;
	onAdd: () => void;
	onChange: (exclusion: ExclusionType) => void;
	onRemove: () => void;
};

const ExclusionItem = ({ exclusion: { value }, isLast, onAdd, onChange, onRemove }: ExclusionItemProps) => (
	<ExclusionWrapper>
		<TextField onChange={(newValue) => onChange({ value: newValue })} value={value} />
		{isLast ? (
			<Tooltip content={value ? 'Ajouter une exclusion' : ''}>
				<div>
					<IconButton disabled={!value} onClick={onAdd} size="small">
						<Icon path={mdiPlusCircle} />
					</IconButton>
				</div>
			</Tooltip>
		) : (
			<Tooltip content="Supprimer l'exclusion">
				<div>
					<IconButton onClick={onRemove} size="small">
						<Icon path={mdiMinusCircle} />
					</IconButton>
				</div>
			</Tooltip>
		)}
	</ExclusionWrapper>
);

type ExclusionsFormProps = {
	control: Control<StorageDownloadFormType>;
};

const ExclusionsForm = ({ control }: ExclusionsFormProps) => {
	const {
		fields: exclusions,
		append: addExclusion,
		update: updateExclusion,
		remove: removeExclusion,
	} = useFieldArray({
		control,
		name: 'exclusions',
	});

	const handleScroll = () => {
		const settingsContentDiv = document.querySelector('#settings-page');

		settingsContentDiv?.scrollTo({
			behavior: 'smooth',
			top: settingsContentDiv.scrollHeight,
		});
	};

	const handleAddExclusion = () => {
		addExclusion({ value: '' });
		handleScroll();
	};

	return (
		<SettingsGroup label="Exclusions">
			<BaseSettingsItem
				description="Astuce : le caractère * permet de définir des niveaux variables"
				label="Exclure les chemins suivants"
			>
				<ExclusionList>
					{exclusions.map((exclusion, index) => {
						const { id } = exclusion;

						const handleUpdateExclusion = (newExclusion: ExclusionType) => updateExclusion(index, newExclusion);
						const handleRemoveExclusion = () => removeExclusion(index);

						return (
							<ExclusionItem
								exclusion={exclusion}
								key={id}
								isLast={index === exclusions.length - 1}
								onAdd={handleAddExclusion}
								onChange={handleUpdateExclusion}
								onRemove={handleRemoveExclusion}
							/>
						);
					})}
				</ExclusionList>
			</BaseSettingsItem>
		</SettingsGroup>
	);
};

export default ExclusionsForm;
