import { SVGProps } from 'react';

const LoanSimulationSVG = (props: SVGProps<SVGSVGElement>) => (
	<svg xmlns="http://www.w3.org/2000/svg" height={60} width={60} viewBox="0 0 512 512" {...props}>
		<path d="M297.6 13.1c-9.7 2.3-16.5 7.3-35.1 25.9l-18 18h97.4l2 2.6c4.4 5.6 4.5 5.4-31.3 41.4-18.4 18.4-34.1 34.9-35 36.7-2.4 4.8-2.1 13.3.7 17.4 3.6 5.4 8.2 7.9 14.4 7.9 3 0 6.6-.6 8.1-1.4 1.5-.8 14.6-13.5 29.2-28.1 25.1-25.2 29.9-29.5 33.3-29.5.8 0 4.1 2.1 7.3 4.6 12.8 10 24.5 13.9 39.4 13.1 11.1-.5 18-2.8 27.3-9l6.2-4.2.3-48.3.2-48.2-71.2.1c-39.2.1-73 .5-75.2 1zM460 57.5V103h42V12h-42v45.5z" />
		<path d="M101.6 73.6c-1.4 1.4-1.6 9.8-1.6 77.4v75.9l2.2 1.5c2 1.4 18.1 1.6 152.3 1.6 118.9 0 150.5-.3 152.5-1.3l2.5-1.3.3-44.6.2-44.6-7.7-.7c-13.3-1.2-23.9-4.8-34.6-11.7l-4.8-3.1-26.2 26c-18.8 18.7-27.2 26.4-29.7 27.2-2.4.8-4.4 2.5-6.3 5.5-3.9 6.2-13.8 15.1-20.6 18.6-9.4 4.7-17.2 6.3-28.1 5.7-11.6-.6-19.1-3.1-28.3-9.4-15.3-10.3-23.7-26.4-23.7-45.2 0-15.7 5.1-28.2 16-39.1 10.6-10.6 23.5-16 38.5-16 9.5 0 17.2 1.8 25.7 6.1l6.1 3.1 16.6-16.6L319.5 72H211.3c-97 0-108.3.2-109.7 1.6zm58.7 48c6.1 2.1 14.1 9.3 17.5 15.8 2.1 4.2 2.6 6.5 2.6 13.1.1 10.4-2.6 16.6-10 23.4-6.4 5.8-12.4 8.1-21.3 8.1-12 0-23.3-7.5-28.4-18.9-3-6.7-2.9-17.8.2-24.9 2.9-6.3 11.3-14.4 17.3-16.5 5.7-2 16.3-2.1 22.1-.1z" />
		<path d="M144 136.1c-8.3 3.3-12.5 14.1-8.5 21.8 5.2 10.1 17.5 11.8 25.5 3.6 3.3-3.4 3.5-4 3.5-10.3 0-7.9-1.8-11.1-8.1-14.2-4.4-2.2-8.4-2.5-12.4-.9zM245.3 112.5c-12.4 3.5-20.5 10.1-26 21.3-3.6 7.2-3.8 8.1-3.8 17.1 0 8.7.3 10.2 3.2 16.3 5.7 12 16.3 20.3 29.6 22.9 7.2 1.5 16.7 0 24.2-3.7 4.7-2.4 10.5-7 10.5-8.4 0-.4-2.3-1.7-5.1-3-13.5-6.4-20.7-22.8-16.4-37.6 2-6.9 4.5-11.3 9.8-17.1l3.8-4.1-5.8-2.2c-7.5-2.8-17.3-3.4-24-1.5zM143.4 266c-1.1.4-3.3 2.2-4.7 4l-2.7 3.2v71.5l10.1 3.6c11 3.9 21.3 9.4 30.4 16.3 6.1 4.6 13.6 8.9 19 10.7 1.7.6 17.7 1.3 36.5 1.6l33.5.6 6.3 2.9c12.5 5.8 20.2 17.8 20.2 31.6 0 3.9.1 7 .3 7 .1 0 11.9-4.3 26.2-9.4l26-9.4v-63.3c0-60.6-.1-63.5-1.9-66.2-3.7-5.5-4.9-5.7-36.5-5.7h-29l-.3 23.1c-.3 22.9-.3 23.1-2.7 25-1.3 1-3.4 1.9-4.7 1.9-1.3 0-8.2-2.9-15.2-6.4l-12.8-6.4-13.9 6.4c-7.7 3.5-15.1 6.4-16.5 6.4-1.5 0-3.6-1-4.8-2.3-2.1-2.3-2.2-3.1-2.2-25V265l-29.2.1c-16.1 0-30.2.4-31.4.9z" />
		<path d="M219 280c0 8.2.2 15 .5 15s4.9-2 10.2-4.5c5.3-2.5 10.7-4.5 12-4.5 1.3 0 5.9 1.8 10.3 4s8.2 4 8.5 4c.3 0 .5-6.5.5-14.5V265h-42v15zM10.8 418.7c-.2 38.4-.2 69.9-.2 70.1.1.1 7.6.5 16.8.9l16.6.6V349H11.1l-.3 69.7zM105 358.6c-11.4 2-14.7 2.7-20.2 4.5-3.5 1.1-10.5 4.1-15.5 6.6l-9.3 4.6v94.5l8.8.7c20.4 1.6 41.8 6.8 68.2 16.4 29.5 10.6 47.6 14.1 74 14.1 19.6 0 31.3-1.6 55.4-7.7 40.7-10.2 80.4-25.3 112.7-42.8 21.4-11.7 24.6-14.1 27.4-20.8 3.4-8.2.4-17.8-7.3-23.2-8.1-5.6-9-5.4-66.7 15.4-28 10.1-54.9 19.9-59.7 21.7L264 446h-37.9c-37.8 0-38 0-40.1-2.2-2.9-3.2-2.7-8.1.6-10.7 2.6-2 3.8-2.1 37.9-2.1 23.7 0 36.3-.4 38.6-1.1 17.1-5.7 18.6-27.6 2.4-35-3.6-1.7-6.9-1.9-32.7-1.9-32.4 0-38.6-.8-50.3-6.5-3.8-1.9-10.8-6.2-15.5-9.6-10.2-7.4-22.1-13.3-32.7-15.9-7.4-2-24.2-3.3-29.3-2.4z" />
	</svg>
);
export default LoanSimulationSVG;
