import { useMutation, useQuery } from '@apollo/client';
import { Button, SettingsGroup, SettingsItemTextField } from '@elipssolution/harfang';
import { styled } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import SettingsDialogPage from '../../../../../../components/SettingsDialogPage';
import { DomainType } from '../../../../../../types/domain';
import { generateErrorInformations } from '../../../../../../utils/errorHandler';
import {
	CREATE_DOMAIN_BILL_EXCHANGE_SETTING_PAYMENT_MODE,
	CREATE_DOMAIN_CHECK_SETTING_PAYMENT_MODE,
	CreateDomainBillExchangeSettingPaymentModeType,
	CreateDomainCheckSettingPaymentModeType,
	FETCH_DOMAIN_BILL_EXCHANGE_SETTING_PAYMENT_MODE,
	FETCH_DOMAIN_CHECK_SETTING_PAYMENT_MODE,
	FetchDomainBillExchangeSettingPaymentModeType,
	FetchDomainCheckSettingPaymentModeType,
} from '../../../../api/settingPaymentMode';
import { CheckTypeEnum } from '../../../../types/check';

const ActionWrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row-reverse',
	justifyContent: 'space-between',
});

const ErrorWrapper = styled('div')(({ theme: { palette, shape } }) => ({
	height: 36,
	width: '50%',

	display: 'grid',
	placeItems: 'center',

	color: palette.error.main,
	backgroundColor: `${palette.error.main}1A`,
	borderRadius: shape.borderRadius * 2,
}));

type FormFieldType = {
	billExchangePaymentModeCode: string;
	checkIssuancePaymentModeCode: string;
	checkRemittancePaymentModeCode: string;
};

type PaymentModesProps = {
	domainId?: DomainType['id'];
};

const PaymentModes = ({ domainId }: PaymentModesProps) => {
	const {
		control,
		formState: { dirtyFields, isDirty, isValid },
		handleSubmit,
		setValue,
		reset,
	} = useForm<FormFieldType>();

	const isFormValid = useMemo(() => isDirty && isValid, [isDirty, isValid]);

	const [error, setError] = useState('');
	const [isCreateDomainBillExchangePaymentModeSucceeded, setIsCreateDomainBillExchangePaymentModeSucceeded] =
		useState(false);

	useQuery<FetchDomainBillExchangeSettingPaymentModeType>(FETCH_DOMAIN_BILL_EXCHANGE_SETTING_PAYMENT_MODE, {
		onCompleted: ({ quickentry_settingDomainBillExchangePaymentMode: { currentCodeValue } }) =>
			setValue('billExchangePaymentModeCode', currentCodeValue),
		onError: (queryError) =>
			setError(
				generateErrorInformations({ error: queryError, resource: 'quickentry_settingDomainBillExchangePaymentMode' })
					.message,
			),
		skip: !domainId,
		variables: { id: domainId },
	});

	const [createDomainBillExchangePaymentMode] = useMutation<CreateDomainBillExchangeSettingPaymentModeType>(
		CREATE_DOMAIN_BILL_EXCHANGE_SETTING_PAYMENT_MODE,
		{
			onCompleted: () => {
				setIsCreateDomainBillExchangePaymentModeSucceeded(true);
				setTimeout(() => setIsCreateDomainBillExchangePaymentModeSucceeded(false), 3000);
			},
		},
	);

	useQuery<FetchDomainCheckSettingPaymentModeType>(FETCH_DOMAIN_CHECK_SETTING_PAYMENT_MODE, {
		onCompleted: ({ quickentry_settingDomainCheckPaymentMode: { currentCodeValue } }) =>
			setValue('checkIssuancePaymentModeCode', currentCodeValue),
		onError: (queryError) =>
			setError(
				generateErrorInformations({ error: queryError, resource: 'quickentry_settingDomainCheckPaymentMode' }).message,
			),
		skip: !domainId,
		variables: { id: domainId, checkType: CheckTypeEnum.CHECK_ISSUANCE },
	});

	useQuery<FetchDomainCheckSettingPaymentModeType>(FETCH_DOMAIN_CHECK_SETTING_PAYMENT_MODE, {
		onCompleted: ({ quickentry_settingDomainCheckPaymentMode: { currentCodeValue } }) =>
			setValue('checkRemittancePaymentModeCode', currentCodeValue),
		onError: (queryError) =>
			setError(
				generateErrorInformations({ error: queryError, resource: 'quickentry_settingDomainCheckPaymentMode' }).message,
			),
		skip: !domainId,
		variables: { id: domainId, checkType: CheckTypeEnum.CHECK_REMITTANCE },
	});

	const [createDomainCheckPaymentMode] = useMutation<CreateDomainCheckSettingPaymentModeType>(
		CREATE_DOMAIN_CHECK_SETTING_PAYMENT_MODE,
	);

	const onSubmit = useCallback(
		(values: FormFieldType) => {
			const { billExchangePaymentModeCode, checkIssuancePaymentModeCode, checkRemittancePaymentModeCode } = values;

			const {
				billExchangePaymentModeCode: isBillExchangePaymentModeCodeDirty,
				checkIssuancePaymentModeCode: isCheckIssuancePaymentModeCodeDirty,
				checkRemittancePaymentModeCode: isCheckRemittancePaymentModeCodeDirty,
			} = dirtyFields;

			const submitActions = [];

			isBillExchangePaymentModeCodeDirty &&
				submitActions.push(
					createDomainBillExchangePaymentMode({
						variables: {
							createSettingDomainBillExchangePaymentModeInput: {
								domainId,
								paymentModeCode: billExchangePaymentModeCode ?? '',
							},
						},
					}),
				);

			isCheckIssuancePaymentModeCodeDirty &&
				submitActions.push(
					createDomainCheckPaymentMode({
						variables: {
							createSettingDomainCheckPaymentModeInput: {
								domainId,
								paymentModeCode: checkIssuancePaymentModeCode ?? '',
								checkType: CheckTypeEnum.CHECK_ISSUANCE,
							},
						},
					}),
				);

			isCheckRemittancePaymentModeCodeDirty &&
				submitActions.push(
					createDomainCheckPaymentMode({
						variables: {
							createSettingDomainCheckPaymentModeInput: {
								domainId,
								paymentModeCode: checkRemittancePaymentModeCode ?? '',
								checkType: CheckTypeEnum.CHECK_REMITTANCE,
							},
						},
					}),
				);

			(async () => {
				await Promise.all(submitActions);

				reset({
					billExchangePaymentModeCode,
					checkIssuancePaymentModeCode,
					checkRemittancePaymentModeCode,
				});
			})().catch((e) => {
				throw e;
			});
		},
		[createDomainBillExchangePaymentMode, createDomainCheckPaymentMode, dirtyFields, domainId, reset],
	);

	return (
		<SettingsDialogPage title="Modes de règlement">
			<SettingsGroup>
				<Controller
					control={control}
					name="checkIssuancePaymentModeCode"
					render={({ field }) => <SettingsItemTextField {...field} label='Code mode de règlement "Chèques émis"' />}
				/>

				<Controller
					control={control}
					name="checkRemittancePaymentModeCode"
					render={({ field }) => (
						<SettingsItemTextField {...field} label='Code mode de règlement "Remise de chèques"' />
					)}
				/>
				<Controller
					control={control}
					name="billExchangePaymentModeCode"
					render={({ field }) => <SettingsItemTextField {...field} label='Code mode de règlement "LCR émises"' />}
				/>
			</SettingsGroup>

			<ActionWrapper>
				<Button disabled={!isFormValid} onClick={handleSubmit(onSubmit)} variant="contained">
					{isCreateDomainBillExchangePaymentModeSucceeded ? 'LCR validée' : 'Valider'}
				</Button>

				{error && <ErrorWrapper>{error}</ErrorWrapper>}
			</ActionWrapper>
		</SettingsDialogPage>
	);
};

export default PaymentModes;
