import { Button, Icon, SettingsGroup, SettingsItemCheckbox, SettingsItemTextField } from '@elipssolution/harfang';
import { mdiPencil } from '@mdi/js';
import { Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import SimulatorForm from './SimulatorForm';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import {
	WidgetConfigurationComponentProps,
	WidgetSimulatorsConfigurationType,
	WidgetSimulatorType,
} from '../../../../types/widget';
import { simulators as SIMULATORS } from '../../../utils/simulators';
import SimulatorsTable from '../SimulatorsTable';

enum ViewStep {
	TABLE,
	ADD,
	EDIT,
}

const WidgetSimulatorsConfiguration = ({
	name: initialName = 'Simulateurs',
	isAvailableOnDesktopFormat: initialIsAvailableOnDesktopFormat,
	isAvailableOnMobileFormat: initialIsAvailableOnMobileFormat,
	configuration,
	onSave,
	onBack,
}: WidgetConfigurationComponentProps) => {
	const { simulators: initialSimulators = [] } = (configuration as WidgetSimulatorsConfigurationType | undefined) ?? {};

	const [name, setName] = useState(initialName);
	const [isAvailableOnDesktopFormat, setIsAvailableOnDesktopFormat] = useState(initialIsAvailableOnDesktopFormat);
	const [isAvailableOnMobileFormat, setIsAvailableOnMobileFormat] = useState(initialIsAvailableOnMobileFormat);

	const [simulators, setSimulators] = useState(initialSimulators);
	const [formState, setViewStep] = useState<ViewStep>(ViewStep.TABLE);
	const [selectedSimulator, setSelectedSimulator] = useState<WidgetSimulatorType>();

	const setSimulatorsSorted = (unsortedSimulators: WidgetSimulatorType[]) => {
		const sortedSimulators = unsortedSimulators.sort((a, b) => a.name.localeCompare(b.name));

		setSimulators(sortedSimulators);
	};

	const navigateBack = () => {
		setSelectedSimulator(undefined);
		setViewStep(ViewStep.TABLE);
	};

	const handleAddSimulator = (simulator: WidgetSimulatorType) => {
		navigateBack();
		setSimulatorsSorted([...simulators, simulator]);
	};

	const handleEditSimulator = (simulator: WidgetSimulatorType) => {
		navigateBack();
		setSimulatorsSorted([...simulators.filter((previousValue) => previousValue.key !== simulator.key), simulator]);
	};

	const handleRemoveSimulator = ({ key }: WidgetSimulatorType) => {
		navigateBack();
		setSimulators((previousValues) => previousValues.filter((previousValue) => previousValue.key !== key));
	};

	const handleSave = async () => {
		navigateBack();

		return onSave(name, isAvailableOnDesktopFormat, isAvailableOnMobileFormat, { simulators });
	};

	const navigateToAddSimulator = () => setViewStep(ViewStep.ADD);

	const navigateToEditSimulator = (simulator: WidgetSimulatorType) => {
		setSelectedSimulator(simulator);
		setViewStep(ViewStep.EDIT);
	};

	const availableSimulators = useMemo(
		() =>
			SIMULATORS.filter(
				(simulator) =>
					!simulators.map(({ type }) => type).includes(simulator.type) || selectedSimulator?.type === simulator.type,
			),
		[selectedSimulator?.type, simulators],
	);

	useEffect(() => setName(name), [name]);

	return (
		<>
			{formState === ViewStep.TABLE && (
				<SettingsDialogPage title={name} onBack={onBack}>
					<SettingsItemTextField label="Nom du widget" value={name} onChange={(value) => setName(value ?? '')} />
					<SettingsGroup label="Configuration">
						<SettingsItemCheckbox
							checked={isAvailableOnDesktopFormat}
							description="Si coché, le widget sera visible sur ordinateur."
							label="Visible version ordinateur"
							onChange={(_, checked: boolean) => setIsAvailableOnDesktopFormat(checked)}
						/>
						<SettingsItemCheckbox
							checked={isAvailableOnMobileFormat}
							description="Si coché, le widget sera visible sur mobile."
							label="Visible version mobile"
							onChange={(_, checked: boolean) => setIsAvailableOnMobileFormat(checked)}
						/>
					</SettingsGroup>

					<SimulatorsTable simulators={simulators} onSelectSimulator={navigateToEditSimulator} />

					<Stack direction="row" justifyContent="flex-end" gap={1}>
						{availableSimulators.length > 0 && <Button onClick={navigateToAddSimulator}>Ajouter un simulateur</Button>}
						<Button startIcon={<Icon path={mdiPencil} size="small" />} variant="contained" onClick={handleSave}>
							Appliquer
						</Button>
					</Stack>
				</SettingsDialogPage>
			)}

			{formState === ViewStep.ADD && (
				<SimulatorForm
					onSubmit={handleAddSimulator}
					onRemove={handleRemoveSimulator}
					onBack={navigateBack}
					availableSimulators={availableSimulators}
				/>
			)}

			{formState === ViewStep.EDIT && (
				<SimulatorForm
					simulator={selectedSimulator}
					onRemove={handleRemoveSimulator}
					onSubmit={handleEditSimulator}
					onBack={navigateBack}
					availableSimulators={availableSimulators}
				/>
			)}
		</>
	);
};

export default WidgetSimulatorsConfiguration;
