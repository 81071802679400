import { useLazyQuery, useMutation } from '@apollo/client';
import {
	AutocompleteFilterDefinitionType,
	Chip,
	ConfirmationDialog,
	DialogProps,
	FilterMenuOutputType,
	Icon,
	IconButton,
	SelectFilterDefinitionType,
	Table,
	TableColumnType,
	TableInstance,
	TableOrderByType,
	Tooltip,
} from '@elipssolution/harfang';
import { mdiClose, mdiDelete, mdiEmailArrowRight } from '@mdi/js';
import { Stack } from '@mui/material';
import { useCallback, useMemo, MouseEvent, useState } from 'react';

import { useSession } from '../../../../../src/components/SessionProvider';
import { DIALOG_CLOSE_DELAY } from '../../../../../src/utils/dialogCloseDelay';
import { PermissionEnum } from '../../../../../types/permission';
import {
	FETCH_ONGOING_AND_DRAFT_PROCEDURES,
	FetchOngoingAndDraftProceduresType,
	REMIND_PROCEDURE_AWAITED_SIGNERS,
	RemindProcedureAwaitedSignersType,
	CANCEL_PROCEDURE,
	CancelProcedureType,
	REMOVE_PROCEDURE,
	RemoveProcedureType,
} from '../../../api/procedure';
import {
	FETCH_SIGNERS_IN_ONGOING_OR_DRAFT_PROCEDURES,
	FetchSignersInOngoingOrDraftProceduresType,
} from '../../../api/signer';
import ProcedureConsultationDialog from '../../../components/ProcedureConsultationDialog';
import {
	SignMutationErrorEnum,
	ProcedureStatusEnum,
	ProcedureType,
	SignMutationErrorMessageEnum,
} from '../../../types/procedure';
import { SignerType } from '../../../types/signer';
import { baseProcedureColumns } from '../../../utils/procedureBaseColumns';
import { RenderSigner, renderSignerFullName } from '../../../utils/renderSigner';

type ProcedureFilterType = {
	isPrivate: SelectFilterDefinitionType<boolean>;
	signer: AutocompleteFilterDefinitionType<SignerType>;
	status: SelectFilterDefinitionType<ProcedureStatusEnum>;
};

const baseColumns: TableColumnType<ProcedureType>[] = [
	...baseProcedureColumns.slice(0, 2),
	{
		field: 'status',
		key: 'status',
		sortable: true,
		title: 'Statut',
		render: ({ status }) => (
			<Chip
				label={status === ProcedureStatusEnum.ONGOING ? 'En cours' : 'Brouillon'}
				color={status === ProcedureStatusEnum.ONGOING ? 'info' : 'default'}
			/>
		),
		width: 100,
		flexGrow: 0,
	},
	...baseProcedureColumns.slice(2),
];
type OngoingProceduresTableProps = {
	onDraftProcedureClick: (procedureId: ProcedureType['id']) => void;
	tableInstance: TableInstance;
};

const OngoingProceduresTable = ({ onDraftProcedureClick, tableInstance }: OngoingProceduresTableProps) => {
	const { checkPermission } = useSession();

	const [removeProcedureConfirmationDialogError, setRemoveProcedureConfirmationDialogError] = useState<string>();
	const [procedureToDeleteInfos, setProcedureToDeleteInfos] = useState<Pick<ProcedureType, 'id' | 'name'>>();
	const [procedureConsultationId, setProcedureConsultationId] = useState<ProcedureType['id']>();
	const [hasDeleteSucceeded, setHasDeleteSucceeded] = useState(false);

	const { id: procedureToDeleteId, name: procedureToDeleteName = '' } = procedureToDeleteInfos || {};

	const [fetchOngoingAndDraftProcedures] = useLazyQuery<FetchOngoingAndDraftProceduresType>(
		FETCH_ONGOING_AND_DRAFT_PROCEDURES,
	);

	const proceduresDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<ProcedureType>,
			filters?: FilterMenuOutputType<ProcedureFilterType>,
		): Promise<{
			count: number;
			items: ProcedureType[];
		}> => {
			const { field, order } = orderBy || {};
			const { signer: signerFilter, ...restFilters } = filters ?? {};

			const { data, error } = await fetchOngoingAndDraftProcedures({
				variables: {
					...(!!filters && {
						filter: {
							...restFilters,
							...(signerFilter && {
								signerFirstName: { eq: signerFilter.eq?.firstName },
								signerLastName: { eq: signerFilter.eq?.lastName },
							}),
						},
					}),
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				sign_ongoingAndDraftProcedures: { count = 0, items = [] },
			} = data ?? {
				sign_ongoingAndDraftProcedures: {},
			};

			return {
				count,
				items: items.map(({ updatedAt, ...rest }) => ({
					...rest,
					updatedAt: new Date(updatedAt),
				})),
			};
		},
		[fetchOngoingAndDraftProcedures],
	);

	const [fetchSignersInOngoingOrDraftProcedures] = useLazyQuery<FetchSignersInOngoingOrDraftProceduresType>(
		FETCH_SIGNERS_IN_ONGOING_OR_DRAFT_PROCEDURES,
	);

	const signersInOngoingOrDraftProceduresDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
		): Promise<{
			count: number;
			items: SignerType[];
		}> => {
			const { data, error } = await fetchSignersInOngoingOrDraftProcedures({
				variables: {
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				sign_signersInOngoingOrDraftProcedures: { count = 0, items = [] },
			} = data ?? {
				sign_signersInOngoingOrDraftProcedures: {},
			};

			return { count, items };
		},
		[fetchSignersInOngoingOrDraftProcedures],
	);

	const [remindProcedureAwaitedSigners] = useMutation<RemindProcedureAwaitedSignersType>(
		REMIND_PROCEDURE_AWAITED_SIGNERS,
	);

	const handleRemindProcedureAwaitedSigners = useCallback(
		async ({ event, id }: { event: MouseEvent<HTMLButtonElement>; id: ProcedureType['id'] }) => {
			event.stopPropagation();

			await remindProcedureAwaitedSigners({
				variables: {
					signRemindProcedureAwaitedSignersId: id,
				},
			});
		},
		[remindProcedureAwaitedSigners],
	);

	const [cancelProcedure] = useMutation<CancelProcedureType>(CANCEL_PROCEDURE, {
		onCompleted: () => setTimeout(() => tableInstance.reload(), DIALOG_CLOSE_DELAY),
	});

	const handleCancelProcedure = useCallback(
		async ({ event, id }: { event: MouseEvent<HTMLButtonElement>; id: ProcedureType['id'] }) => {
			event.stopPropagation();

			await cancelProcedure({
				variables: {
					signCancelProcedureId: id,
				},
			});
		},

		[cancelProcedure],
	);

	const openRemoveProcedureConfirmationDialog = useCallback(
		({ event, id, name }: Pick<ProcedureType, 'id' | 'name'> & { event: MouseEvent<HTMLButtonElement> }) => {
			event.stopPropagation();

			setProcedureToDeleteInfos({ id, name });
		},
		[],
	);

	const closeRemoveProcedureConfirmationDialog = useCallback(() => {
		setHasDeleteSucceeded(false);
		setProcedureToDeleteInfos(undefined);
		setRemoveProcedureConfirmationDialogError(undefined);
	}, []);

	const [removeProcedure, { loading: isRemoveProcedureLoading }] = useMutation<RemoveProcedureType>(REMOVE_PROCEDURE, {
		onCompleted: () => {
			setHasDeleteSucceeded(true);

			setTimeout(() => {
				closeRemoveProcedureConfirmationDialog();
				tableInstance.reload();
			}, DIALOG_CLOSE_DELAY);
		},
		onError: () =>
			setRemoveProcedureConfirmationDialogError(
				SignMutationErrorMessageEnum[SignMutationErrorEnum.YOUSIGN_UNAVAILABLE],
			),
	});

	const handleRemoveProcedure = useCallback(
		async () =>
			removeProcedure({
				variables: {
					signRemoveProcedureId: procedureToDeleteId,
				},
			}),
		[procedureToDeleteId, removeProcedure],
	);

	const columns: TableColumnType<ProcedureType>[] = useMemo(
		() => [
			...baseColumns,
			{
				align: 'center',
				key: 'actions',
				flexGrow: 0,
				render: ({ id, name, status }) =>
					status === ProcedureStatusEnum.ONGOING ? (
						<Stack gap={1} flexDirection="row-reverse" width="100%">
							<Tooltip placement="left" content="Annuler la procédure">
								<IconButton onClick={(event) => handleCancelProcedure({ event, id })}>
									<Stack justifyContent="center">
										<Icon path={mdiClose} />
									</Stack>
								</IconButton>
							</Tooltip>
							<Tooltip placement="left" content="Relancer les signataires">
								<IconButton onClick={(event) => handleRemindProcedureAwaitedSigners({ event, id })}>
									<Stack justifyContent="center">
										<Icon path={mdiEmailArrowRight} />
									</Stack>
								</IconButton>
							</Tooltip>
						</Stack>
					) : (
						<Stack gap={1} flexDirection="row-reverse" width="100%">
							<Tooltip placement="left" content="Supprimer la procédure">
								<IconButton onClick={(event) => openRemoveProcedureConfirmationDialog({ event, id, name })}>
									<Stack justifyContent="center">
										<Icon path={mdiDelete} />
									</Stack>
								</IconButton>
							</Tooltip>
						</Stack>
					),
				width: 80,
			},
		],
		[handleCancelProcedure, handleRemindProcedureAwaitedSigners, openRemoveProcedureConfirmationDialog],
	);

	const filters: ProcedureFilterType = useMemo(
		() => ({
			isPrivate: {
				label: 'Visibilité',
				type: 'select',
				options: [true, false],
				renderOption: (value) => (value ? 'Privée' : 'Publique'),
				renderValue: (value) => (value ? 'Privée' : 'Publique'),
			},
			signer: {
				key: 'email',
				label: 'Signataire',
				type: 'autocomplete',
				dataSource: signersInOngoingOrDraftProceduresDataSource,
				getOptionLabel: renderSignerFullName,
				renderOptions: RenderSigner,
			},
			status: {
				label: 'Statut',
				type: 'select',
				options: [ProcedureStatusEnum.ONGOING, ProcedureStatusEnum.DRAFT],
				renderOption: (value) => (value === ProcedureStatusEnum.ONGOING ? 'En cours' : 'Brouillon'),
				renderValue: (value) => (value === ProcedureStatusEnum.ONGOING ? 'En cours' : 'Brouillon'),
			},
		}),
		[signersInOngoingOrDraftProceduresDataSource],
	);

	const actionsConfirmationDialog = useMemo(
		(): DialogProps['actionsDialog'] => [
			{
				disabled: isRemoveProcedureLoading,
				label: 'Annuler',
				onClick: closeRemoveProcedureConfirmationDialog,
			},
			{
				loading: isRemoveProcedureLoading,
				error: !!removeProcedureConfirmationDialogError,
				persistantErrorMessage: removeProcedureConfirmationDialogError,
				color: 'error',
				label: 'Supprimer',
				onClick: handleRemoveProcedure,
				variant: 'contained',
				success: hasDeleteSucceeded,
			},
		],
		[
			closeRemoveProcedureConfirmationDialog,
			removeProcedureConfirmationDialogError,
			handleRemoveProcedure,
			isRemoveProcedureLoading,
			hasDeleteSucceeded,
		],
	);

	const handleRowClick = useCallback(
		({ id, status }: ProcedureType) => {
			if (status === ProcedureStatusEnum.ONGOING || !checkPermission(PermissionEnum.SIGN_PROCEDURE_WRITE)) {
				setProcedureConsultationId(id);
			} else {
				onDraftProcedureClick(id);
			}
		},
		[checkPermission, onDraftProcedureClick],
	);

	const handleProcedureViewDialogClose = useCallback(() => setProcedureConsultationId(undefined), []);

	return (
		<Stack height="100%" width="100%" flex={3}>
			<Table<ProcedureType, ProcedureFilterType>
				columns={checkPermission(PermissionEnum.SIGN_PROCEDURE_WRITE) ? columns : baseColumns}
				dataSource={proceduresDataSource}
				filters={filters}
				onRowClick={handleRowClick}
				table={tableInstance}
				title="Procédures en cours"
				enableSearch
			/>

			<ConfirmationDialog
				actionsDialog={actionsConfirmationDialog}
				open={!!procedureToDeleteId}
				onClose={closeRemoveProcedureConfirmationDialog}
				title={`Êtes-vous sûr de vouloir supprimer la procédure ${procedureToDeleteName} ?`}
			/>

			<ProcedureConsultationDialog
				procedureId={procedureConsultationId}
				tableInstance={tableInstance}
				onClose={handleProcedureViewDialogClose}
			/>
		</Stack>
	);
};

export default OngoingProceduresTable;
