import { Icon, IconButton, Tooltip } from '@elipssolution/harfang';
import { mdiAlertCircle, mdiDelete, mdiDragVertical, mdiDraw } from '@mdi/js';
import { alpha, styled } from '@mui/material';
import clsx from 'clsx';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { useCallback, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import SignersAvatarGroup from './SignersAvatarGroup';
import { useSession } from '../../../../../src/components/SessionProvider';
import { getFileBlob, openBlobInNewTab } from '../../../../../src/utils/file';
import { DocumentFieldTypeEnum, SignDocumentType } from '../../../types/document';
import { DocumentWithErrorType, ProcedureType } from '../../../types/procedure';
import { SignerType } from '../../../types/signer';

const StyledRowContainer = styled('div')(({ theme: { palette, spacing, transitions } }) => ({
	padding: spacing(0.5, 0),
	borderBottom: `1px solid ${palette.divider}`,
	'&:last-of-type': {
		borderBottom: `none`,
	},
	transition: transitions.create(['background-color'], {
		duration: transitions.duration.short,
	}),
	'&:hover': {
		backgroundColor: alpha(palette.text.primary, palette.action.hoverOpacity),

		'@media (hover: none)': {
			backgroundColor: 'transparent',
		},
	},
	cursor: 'pointer',
	'&.hasError': { backgroundColor: `${alpha(palette.error.main, 0.05)}` },
}));
const StyledRow = styled('div')({
	display: 'flex',
	alignItems: 'center',
});

const StyledColumn = styled('div')({
	width: 300,
	flexGrow: 1,
	flexShrink: 1,
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflowX: 'hidden',
});

const StyledIconColumn = styled('div')({
	display: 'flex',
	alignItems: 'center',
	textAlign: 'center',
	width: 40,
	'&.isDragIcon': {
		cursor: 'move',
	},
});

type Item = {
	id: string;
	originalIndex: number;
};

type DocumentRowProps = {
	document: DocumentWithErrorType;
	moveDocument: (dragId: SignDocumentType['id'], hoverIndex: number) => void;
	findDocumentIndex: (id: SignDocumentType['id']) => number;
	onDeleteDocument: (id: SignDocumentType['id']) => void;
	signers?: SignerType[];
	onSign: (doc: SignDocumentType) => void;
	procedureId?: ProcedureType['id'];
};

const DocumentRow = ({
	document: { document, id: docId, fields, filename, hasError, message },
	findDocumentIndex,
	moveDocument,
	onDeleteDocument,
	signers,
	procedureId,
	onSign,
}: DocumentRowProps) => {
	const { customerFile: sessionCustomerFile } = useSession();
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};

	const fetchDocumentFile = useCallback(
		async ({
			documentId: id,
			procedureId: currentProcedureId,
			fileName,
		}: {
			documentId: string;
			procedureId?: ProcedureType['id'];
			fileName?: string;
		}) => {
			if (!currentProcedureId) throw new Error('ProcedureId is missing.');
			if (!fileName) throw new Error('FileName is missing.');
			const documentBlob = await getFileBlob({
				url: `/sign/documents?id=${id}&procedureId=${currentProcedureId}`,
				accessToken: access_token,
				selectedCustomerFileId: sessionCustomerFile?.id,
			});

			return new File([documentBlob], `${fileName}`, { type: 'application/pdf' });
		},
		[access_token, sessionCustomerFile?.id],
	);

	const handleSignDocument = useCallback(async () => {
		if (document) {
			onSign({ document, id: docId, fields, filename });
		} else if (procedureId && filename) {
			const currentDoc = await fetchDocumentFile({
				documentId: docId,
				procedureId,
				fileName: filename,
			});

			onSign({ document: currentDoc, id: docId, fields, filename });
		}
	}, [docId, document, fetchDocumentFile, fields, filename, onSign, procedureId]);

	const originalIndex = findDocumentIndex(docId);
	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: 'DOCUMENT',
			item: { id: docId, originalIndex },
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
			end: (item, monitor) => {
				const { id: droppedId, originalIndex: itemIndex } = item;

				if (!monitor.didDrop()) {
					moveDocument(droppedId, itemIndex);
				}
			},
		}),
		[docId, originalIndex, moveDocument],
	);

	const [, drop] = useDrop(
		() => ({
			accept: 'DOCUMENT',
			hover({ id: draggedId }: Item) {
				if (draggedId !== docId) {
					const overIndex = findDocumentIndex(docId);
					moveDocument(draggedId, overIndex);
				}
			},
		}),
		[findDocumentIndex, moveDocument],
	);

	const handleVisualizeDocument = useCallback(async () => {
		const documentToVisualize =
			document ??
			(await fetchDocumentFile({
				documentId: docId,
				procedureId,
				fileName: filename,
			}));
		openBlobInNewTab(documentToVisualize);
	}, [docId, document, fetchDocumentFile, filename, procedureId]);

	// Ensures TypeScript knows the filtered items cannot be undefined.
	const uniqueSigners = useMemo(
		() =>
			signers && signers.length > 0
				? Array.from(
						new Set(
							fields?.flatMap(({ type, signerId }) =>
								type === DocumentFieldTypeEnum.SIGNATURE || type === DocumentFieldTypeEnum.MENTION ? [signerId] : [],
							),
						),
						(signerId) => {
							const signer = signers.find(({ id }) => id === signerId);
							return signer ? { signer } : undefined;
						},
				  ).filter((item): item is { signer: SignerType } => item !== undefined)
				: [],
		[fields, signers],
	);

	return (
		<StyledRowContainer
			ref={(node) => drag(drop(node))}
			style={{ opacity: isDragging ? 0 : 1 }}
			onClick={() => {
				handleVisualizeDocument().catch(() => {
					throw new Error('Error occurred while visualizing the document');
				});
			}}
			className={clsx({
				hasError: hasError || fields?.some(({ hasError: fieldError }) => fieldError),
			})}
		>
			<StyledRow>
				<StyledIconColumn ref={drag} className="isDragIcon">
					<Icon path={mdiDragVertical} size="small" />
				</StyledIconColumn>
				<StyledColumn>{document?.name ?? filename}</StyledColumn>
				<StyledColumn>{uniqueSigners && <SignersAvatarGroup signers={uniqueSigners} />}</StyledColumn>
				<Tooltip content={message}>
					<StyledIconColumn>
						{hasError ||
							(fields?.some(({ hasError: fieldError }) => fieldError) && (
								<Icon path={mdiAlertCircle} color="error" size="small" />
							))}
					</StyledIconColumn>
				</Tooltip>
				<StyledIconColumn>
					<Tooltip content="Placer des signatures">
						<IconButton
							onClick={(event) => {
								event.stopPropagation();
								handleSignDocument().catch(() => {
									throw new Error('An error occurred while signing the document.');
								});
							}}
						>
							<Icon path={mdiDraw} size="small" />
						</IconButton>
					</Tooltip>
				</StyledIconColumn>
				<StyledIconColumn>
					<Tooltip content="Supprimer le document">
						<IconButton
							onClick={(event) => {
								event.stopPropagation();
								onDeleteDocument(docId);
							}}
						>
							<Icon path={mdiDelete} size="small" />
						</IconButton>
					</Tooltip>
				</StyledIconColumn>
			</StyledRow>
		</StyledRowContainer>
	);
};

export default DocumentRow;
