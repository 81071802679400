import {
	SettingsGroup,
	SettingsItemAutocomplete,
	SettingsItemCheckbox,
	SettingsItemTextField,
} from '@elipssolution/harfang';
import Image from 'next/image';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { WidgetExternalApplicationType } from '../../../../types/widget';
import ApplicationCard from '../ApplicationCard';
import { WidgetExternalActionButtons } from '../WidgetExternalActionButtons';

const regions = [
	{
		code: 'eu1',
		name: 'Europe',
	},
	{
		code: 'us1',
		name: 'USA',
	},
];

type RegionType = {
	code: string;
	name: string;
};

type YoozSpecificType = {
	region: string;
	kcIdpHint?: string;
};

type FormType = {
	name: string;
	isExternal: boolean;
	isInternal: boolean;
	isAvailableOnDesktopFormat?: boolean;
	isAvailableOnMobileFormat?: boolean;
	region: RegionType;
	kcIdpHint?: string;
};

type YoozFormProps = {
	application: WidgetExternalApplicationType;
	isNew: boolean;
	onSubmit: (application: WidgetExternalApplicationType) => void;
	onRemove: (application: WidgetExternalApplicationType) => void;
};

export const YoozForm = ({ application, isNew, onSubmit, onRemove }: YoozFormProps) => {
	const {
		key,
		type,
		name: initialName,
		specific,
		isInternal: isInternalInitial,
		isExternal: isExternalInitial,
		isAvailableOnDesktopFormat: isAvailableOnDesktopFormatInitial,
		isAvailableOnMobileFormat: isAvailableOnMobileFormatInitial,
	} = application;
	const jsonSpecific = specific ? (JSON.parse(specific) as YoozSpecificType) : undefined;
	const { region: initialRegion, kcIdpHint: initialKcIdpHint } = jsonSpecific ?? {};

	const {
		control,
		formState: { isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>();

	const handleRemove = () => onRemove(application);

	const handleSubmitInternal = ({
		name,
		region,
		isInternal,
		isExternal,
		isAvailableOnDesktopFormat,
		isAvailableOnMobileFormat,
		kcIdpHint,
	}: FormType) => {
		const stringSpecific = JSON.stringify({ region: region.code, kcIdpHint });

		onSubmit({
			key: key ?? uuid(),
			type,
			name,
			isInternal,
			isExternal,
			isAvailableOnDesktopFormat,
			isAvailableOnMobileFormat,
			specific: stringSpecific,
		});
	};

	useEffect(() => {
		const defaultKcIdpHint = isNew ? 'fidorg_rising_idp' : undefined;
		const defaultRegion = isNew ? regions.find(({ code }) => code === 'eu1') : undefined;

		reset({
			name: initialName,
			isInternal: isInternalInitial,
			isExternal: isExternalInitial,
			isAvailableOnDesktopFormat: isAvailableOnDesktopFormatInitial,
			isAvailableOnMobileFormat: isAvailableOnMobileFormatInitial,
			region: regions.find(({ code }) => code === initialRegion) || defaultRegion,
			kcIdpHint: initialKcIdpHint || defaultKcIdpHint,
		});
	}, [
		initialKcIdpHint,
		initialName,
		initialRegion,
		isAvailableOnDesktopFormatInitial,
		isAvailableOnMobileFormatInitial,
		isExternalInitial,
		isInternalInitial,
		isNew,
		reset,
	]);

	return (
		<>
			<SettingsGroup label="Configuration">
				<Controller
					name="name"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField {...field} description="Nom de l'application." label="Nom" required />
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="region"
					control={control}
					render={({ field }) => (
						<SettingsItemAutocomplete<{ name: string; code: string }>
							{...field}
							description="Région de l'application."
							dataKey="code"
							label="Région"
							getOptionLabel={({ name: optionName }) => optionName}
							dataSource={() =>
								Promise.resolve({
									items: regions,
									count: regions.length,
								})
							}
							required
							disableClearable
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="kcIdpHint"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField {...field} label="Indice SSO" description="Configuration SSO fournie par Yooz." />
					)}
				/>
				<Controller
					name="isInternal"
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs cabinet auront accès à cette application."
							label="Visible cabinet"
						/>
					)}
				/>
				<Controller
					name="isExternal"
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs entreprise auront accès à cette application."
							label="Visible entreprise"
						/>
					)}
				/>

				<Controller
					defaultValue={false}
					name="isAvailableOnDesktopFormat"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, l'application externe sera visible sur ordinateur."
							label="Visible version ordinateur"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
				<Controller
					defaultValue={false}
					name="isAvailableOnMobileFormat"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, l'application externe sera visible sur mobile."
							label="Visible version mobile"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<WidgetExternalActionButtons
				isValid={isValid}
				isNew={isNew}
				onRemove={handleRemove}
				onSubmit={handleSubmit(handleSubmitInternal)}
			/>
		</>
	);
};

type YoozApplicationProps = {
	name: string;
	specific?: string;
	readOnly: boolean;
};

const YoozApplication = ({ name, specific, readOnly }: YoozApplicationProps) => {
	const jsonSpecific = specific ? (JSON.parse(specific) as YoozSpecificType) : undefined;
	const { region, kcIdpHint } = jsonSpecific ?? {};

	const handleClick = () => {
		if (!region) {
			throw new Error('Silae instance is not configured');
		}

		const queryString = kcIdpHint ? `?kc_idp_hint=${kcIdpHint}` : '';

		window.open(`https://${region}.getyooz.com/${queryString}`, '_blank');
	};

	return (
		<ApplicationCard
			name={name}
			image={<Image src="/widget/images/yooz-logo.png" alt="Logo Yooz" width={96} height={96} objectFit="contain" />}
			onClick={handleClick}
			readOnly={readOnly}
		/>
	);
};

export default YoozApplication;
