import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { DomainType } from '../../../../types/domain';
import { WidgetWithKeyType } from '../../../../types/widget';
import {
	CREATE_DOMAIN_DASHBOARD,
	CreateDomainDashboardType,
	FETCH_DOMAIN_DASHBOARD,
	FetchDomainDashboardType,
	UPDATE_DOMAIN_DASHBOARD,
	UpdateDomainDashboardType,
} from '../../../api/dashboard';
import WidgetImagesProvider from '../provider/WidgetImagesProvider';
import SettingsDashboard from '../SettingsDashboard';
import SettingsWidgetConfiguration from '../SettingsWidgetConfiguration';
import SettingsWidgetSelector from '../SettingsWidgetSelector';

type WidgetDashboardDomainProps = {
	domain: DomainType;
};

enum ViewStep {
	DASHBOARD,
	ADD_WIDGET,
	EDIT_WIDGET,
}

const WidgetDashboardDomainWithImages = ({
	domain: { id: domainId, name: domainName },
}: WidgetDashboardDomainProps) => {
	const [widgetsWithKey, setWidgetWithKeys] = useState<WidgetWithKeyType[]>([]);
	const [viewStep, setViewStep] = useState(ViewStep.DASHBOARD);
	const [editedWidgetWithKey, setEditedWidgetWithKey] = useState<WidgetWithKeyType>();
	const [previousViewStep, setPreviousViewStep] = useState(ViewStep.DASHBOARD);

	const {
		data: domainDashboardData,
		loading,
		refetch: refetchDomainDashboard,
	} = useQuery<FetchDomainDashboardType>(FETCH_DOMAIN_DASHBOARD, {
		notifyOnNetworkStatusChange: true,
		variables: {
			domainId,
		},
	});

	const { domainDashboardByDomain: dashboard } = domainDashboardData ?? {};

	const [createDashboard] = useMutation<CreateDomainDashboardType>(CREATE_DOMAIN_DASHBOARD, {
		onCompleted: () => {
			refetchDomainDashboard().catch((e) => {
				throw e;
			});
		},
	});

	const [updateDashboard] = useMutation<UpdateDomainDashboardType>(UPDATE_DOMAIN_DASHBOARD);

	const navigateToAdd = () => {
		setPreviousViewStep(viewStep);
		setViewStep(ViewStep.ADD_WIDGET);
	};

	const navigateToEdit = (widgetWithKey: WidgetWithKeyType) => {
		setPreviousViewStep(viewStep);
		setEditedWidgetWithKey(widgetWithKey);
		setViewStep(ViewStep.EDIT_WIDGET);
	};

	const navigateBack = () => {
		setViewStep(previousViewStep);
		setPreviousViewStep(ViewStep.DASHBOARD);
	};

	const handleSave = async (widgets: WidgetWithKeyType[]) => {
		const saveWidgetInput = widgets.map(
			({
				widget: {
					id,
					name,
					type,
					x,
					y,
					width,
					height,
					configuration,
					moduleCode,
					isAvailableOnDesktopFormat,
					isAvailableOnMobileFormat,
				},
			}) => ({
				id,
				name,
				type,
				x,
				y,
				width,
				height,
				configuration,
				moduleCode,
				isAvailableOnDesktopFormat,
				isAvailableOnMobileFormat,
			}),
		);

		setWidgetWithKeys(widgets);

		if (dashboard?.id) {
			await updateDashboard({
				variables: {
					updateDomainDashboardInput: {
						id: dashboard.id,
						widgets: saveWidgetInput,
					},
				},
			}).then(({ data }) => {
				const { updateDomainDashboard } = data ?? {};
				const { widgets: updatedWidgets = [] } = updateDomainDashboard ?? {};

				setWidgetWithKeys(
					updatedWidgets.map((widget) => ({
						key: widget.id as string,
						widget,
					})),
				);
			});
		} else {
			await createDashboard({
				variables: {
					createDomainDashboardInput: {
						domainId,
						widgets: saveWidgetInput,
					},
				},
			}).then(({ data }) => {
				const { createDomainDashboard } = data ?? {};
				const { widgets: createdWidgets = [] } = createDomainDashboard ?? {};

				setWidgetWithKeys(
					createdWidgets.map((widget) => ({
						key: widget.id as string,
						widget,
					})),
				);
			});
		}
	};

	const handleEditWidget = async (widgetWithKey: WidgetWithKeyType) => {
		setViewStep(ViewStep.DASHBOARD);
		setPreviousViewStep(ViewStep.DASHBOARD);
		return handleSave([
			...widgetsWithKey.filter((previousValue) => previousValue.key !== widgetWithKey.key),
			widgetWithKey,
		]);
	};

	useEffect(() => {
		const initialWidgetsWithKey = (dashboard?.widgets ?? []).map((widget) => ({ key: uuid(), widget }));
		setWidgetWithKeys(initialWidgetsWithKey);
	}, [dashboard]);

	return (
		<>
			{viewStep === ViewStep.DASHBOARD && (
				<SettingsDialogPage title={domainName}>
					<SettingsDashboard
						widgetsWithKey={widgetsWithKey}
						loading={loading}
						onAdd={navigateToAdd}
						onEdit={navigateToEdit}
						onSave={handleSave}
					/>
				</SettingsDialogPage>
			)}

			{viewStep === ViewStep.ADD_WIDGET && (
				<SettingsWidgetSelector
					domainId={domainId}
					onAddWidget={navigateToEdit}
					onBack={navigateBack}
					widgetsWithKey={widgetsWithKey}
				/>
			)}

			{viewStep === ViewStep.EDIT_WIDGET && editedWidgetWithKey && (
				<SettingsWidgetConfiguration
					widgetWithKey={editedWidgetWithKey}
					onSave={handleEditWidget}
					onBack={navigateBack}
				/>
			)}
		</>
	);
};

const WidgetDashboardDomain = (props: WidgetDashboardDomainProps) => (
	<WidgetImagesProvider>
		<WidgetDashboardDomainWithImages {...props} />
	</WidgetImagesProvider>
);

export default WidgetDashboardDomain;
