import { Tooltip } from '@elipssolution/harfang';
import { Avatar, AvatarGroup, Stack, styled, Typography } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';

import { SignerType } from '../types/signer';

const AVATAR_SIZE = 36;

const SignerAvatar = styled(Avatar)(({ theme: { palette } }) => ({
	height: AVATAR_SIZE,
	width: AVATAR_SIZE,

	'&.signed': {
		backgroundColor: palette.primary.main,
		color: palette.getContrastText(palette.primary.main),
	},
	'&.rejected': {
		backgroundColor: palette.error.main,
		color: palette.getContrastText(palette.error.main),
	},
}));

const renderAdditionalAvatar = ({ surplusSigners }: { surplusSigners: SignerType[] }) => {
	const tooltipContent = (
		<Stack gap={1}>
			{surplusSigners.map(({ id, lastName, firstName }) => (
				<Typography key={id} variant="inherit">
					{`${firstName} ${lastName}`}
				</Typography>
			))}
		</Stack>
	);

	return (
		<Tooltip content={tooltipContent}>
			<Avatar>{`+${surplusSigners.length}`}</Avatar>
		</Tooltip>
	);
};

const SignersAvatarGroup = ({ signers }: { signers: SignerType[] }) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const [maxVisible, setMaxVisible] = useState(5);

	const additionalAvatar = useMemo(
		() => ({
			additionalAvatar: {
				component: ({ children }: { children: [string, number] }) => {
					const [, restSigners] = children;

					return renderAdditionalAvatar({ surplusSigners: signers.slice(-restSigners) });
				},
			},
		}),
		[signers],
	);

	const resizeObserver = useMemo(
		() =>
			new ResizeObserver((entries) => {
				const { width } = entries[0].contentRect;

				setMaxVisible(Math.floor(width / 44));
			}),
		[],
	);

	useEffect(() => {
		if (!containerRef.current) return undefined;

		resizeObserver.observe(containerRef.current);

		return () => resizeObserver.disconnect();
	}, [resizeObserver, containerRef]);

	return (
		<Stack width="100%" flexDirection="row" ref={containerRef}>
			<AvatarGroup total={signers.length} max={maxVisible} slotProps={additionalAvatar}>
				{signers.map(({ id, lastName, firstName, signedAt, rejectedAt, email }) => {
					const tooltipContent = (
						<Stack gap={1}>
							<Stack gap={1}>
								<Typography variant="inherit">{`${firstName} ${lastName}`}</Typography>
								<Typography variant="inherit">{email}</Typography>
							</Stack>
							{signedAt || rejectedAt ? (
								<Typography variant="inherit">
									{signedAt
										? `Signé le: ${new Date(signedAt || '').toLocaleDateString('fr-FR')}`
										: `Rejeté le: ${new Date(rejectedAt || '').toLocaleDateString('fr-FR')}`}
								</Typography>
							) : (
								<Typography variant="inherit">En attente de signature</Typography>
							)}
						</Stack>
					);

					return (
						<Tooltip key={id} content={tooltipContent}>
							<SignerAvatar className={clsx({ signed: !!signedAt, rejected: !!rejectedAt })}>
								{`${firstName[0]}${lastName[0]}`}
							</SignerAvatar>
						</Tooltip>
					);
				})}
			</AvatarGroup>
		</Stack>
	);
};

export default SignersAvatarGroup;
