import { useQuery } from '@apollo/client';
import getConfig from 'next/config';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useApollo } from './ApolloProvider';
import SplashScreen from './SplashScreen';
import { CustomerFileType } from '../../types/customerFile';
import { PermissionEnum } from '../../types/permission';
import { ModuleCodeEnum } from '../../types/widget';
import { FETCH_CUSTOMER_FILE_BY_CODE, FetchCustomerFileByCodeType } from '../api/customerFile';
import { FETCH_MODULES, FetchModulesType } from '../api/module';
import { FETCH_SESSION, FetchSessionType } from '../api/session';
import useCustomRouter from '../hooks/useCustomRouter';
import { PublicRuntimeConfigType } from '../types/publicRuntimeConfig';
import { SessionPermissionType, SessionUserType } from '../types/session';
import { UserType } from '../types/user';
import useLocalStorage from '../utils/useLocalStorage';

export type ImpersonatedUserType = Pick<UserType, 'id' | 'email' | 'lastName' | 'firstName'>;

export type SessionContextType = {
	user: SessionUserType;
	customerFile?: CustomerFileType;
	permissions: SessionPermissionType[];
	impersonatedUser?: ImpersonatedUserType;
	checkPermission: (code: PermissionEnum) => boolean;
	changeCustomerFile: (customerFile?: CustomerFileType) => void;
	refetch: () => Promise<void>;
	impersonate: (user?: ImpersonatedUserType) => void;
};

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const SessionProvider = ({ children }: { children: JSX.Element }) => {
	const { publicRuntimeConfig } = getConfig() as PublicRuntimeConfigType;
	const { standaloneModule } = publicRuntimeConfig;
	const {
		customerFileId: apolloCustomerFileId,
		setCustomerFileId: setApolloCustomerFileId,
		setUserId: setApolloUserId,
		userId: apolloUserId,
	} = useApollo();

	const { replace, push, pathname, query } = useCustomRouter();
	const { dossier: queryCustomerFileCode, settings } = query || {};

	const [localStorageCustomerFileCode, setLocalStorageCustomerFileCode] = useLocalStorage<
		CustomerFileType['code'] | undefined
	>('sessionCustomerFileCode', undefined);
	const [localStorageImpersonatedUser, setLocalStorageImpersonatedUser] = useLocalStorage<
		ImpersonatedUserType | undefined
	>('impersonatedUser', undefined);

	const [isImpersonateLoading, setImpersonateLoading] = useState(false);
	const [sessionCustomerFile, setSessionCustomerFile] = useState<CustomerFileType>();

	const isOnErrorPage = pathname.startsWith('/error');
	const isOnBrochureModule = pathname.includes(ModuleCodeEnum.BROCHURE);

	const updateCustomerFileStorages = useCallback(
		(customerFile?: CustomerFileType) => {
			if (!customerFile) {
				setSessionCustomerFile(undefined);
				setLocalStorageCustomerFileCode(undefined);
				setApolloCustomerFileId(undefined);

				const updatedQuery = { ...query };
				delete updatedQuery.dossier;
				replace({
					query: updatedQuery,
				}).catch((error) => {
					throw error;
				});

				return;
			}

			const { id: customerFileId, code: customerFileCode } = customerFile ?? {};

			if (localStorageCustomerFileCode !== customerFileCode) {
				setLocalStorageCustomerFileCode(customerFileCode);
			}

			(async () => {
				if (
					queryCustomerFileCode !== customerFileCode &&
					(!!standaloneModule || (!standaloneModule && pathname === '/') || !isOnBrochureModule)
				) {
					await push({
						pathname: '/',
						query: {
							dossier: customerFileCode,
						},
					});
				}
			})().catch((error) => {
				throw error;
			});

			if (sessionCustomerFile?.code !== customerFileCode) {
				setSessionCustomerFile({
					...customerFile,
					...(customerFile?.minimumEntryDate && {
						minimumEntryDate: new Date(customerFile.minimumEntryDate),
					}),
				});
			}

			setApolloCustomerFileId(customerFileId);
		},
		[
			isOnBrochureModule,
			localStorageCustomerFileCode,
			pathname,
			push,
			query,
			queryCustomerFileCode,
			replace,
			sessionCustomerFile?.code,
			setApolloCustomerFileId,
			setLocalStorageCustomerFileCode,
			standaloneModule,
		],
	);

	const { data: sessionData, refetch } = useQuery<FetchSessionType>(FETCH_SESSION, {
		onCompleted: ({
			session: {
				customerFile,
				user: { hasSettingsAccess },
				permissions,
			},
		}) => {
			const brochureAccessibilityPermissions = [
				'brochure-general',
				'brochure-invoicing',
				'brochure-legal',
				'brochure-statistics',
			];

			if (
				!isOnErrorPage &&
				customerFile === null &&
				!hasSettingsAccess &&
				!brochureAccessibilityPermissions.some((permissionCode) =>
					permissions.some(({ code, value }) => code === permissionCode && !!value),
				)
			) {
				push('/error/emptyCustomerFiles').catch((error) => {
					throw error;
				});
			} else if (!queryCustomerFileCode && !localStorageCustomerFileCode) {
				customerFile && updateCustomerFileStorages(customerFile);
			}
		},
	});
	const { session } = sessionData ?? {};

	const { loading } = useQuery<FetchModulesType>(FETCH_MODULES, {
		onCompleted: ({ modules: fetchedModules }) => {
			if (pathname !== '/' && !isOnErrorPage && fetchedModules.length > 0 && !settings) {
				const isModuleAvailable = fetchedModules.some(({ code }) => pathname.includes(code));

				if (!isModuleAvailable) {
					updateCustomerFileStorages(undefined);
					push('/error/403').catch((error) => {
						throw error;
					});
				}
			}
		},
		skip: !apolloCustomerFileId || isOnErrorPage || !session?.user,
	});

	useQuery<FetchCustomerFileByCodeType>(FETCH_CUSTOMER_FILE_BY_CODE, {
		onCompleted: ({ customerFileByCode }) => updateCustomerFileStorages(customerFileByCode),
		onError: (error) => {
			updateCustomerFileStorages(undefined);

			if (error.message.includes("can't be accessed for current user.")) {
				push('/error/403').catch((pushError) => {
					throw pushError;
				});
			}
		},
		variables: { code: queryCustomerFileCode },
		skip: !!sessionCustomerFile || !queryCustomerFileCode || !localStorageCustomerFileCode || isOnErrorPage,
	});

	useQuery<FetchCustomerFileByCodeType>(FETCH_CUSTOMER_FILE_BY_CODE, {
		onCompleted: ({ customerFileByCode }) => updateCustomerFileStorages(customerFileByCode),
		onError: () => updateCustomerFileStorages(undefined),
		variables: { code: localStorageCustomerFileCode },
		skip: !!sessionCustomerFile || !!queryCustomerFileCode || !localStorageCustomerFileCode || isOnErrorPage,
	});

	const handleImpersonate = useCallback(
		async (user?: ImpersonatedUserType) => {
			try {
				setImpersonateLoading(true);

				updateCustomerFileStorages(undefined);
				if (user) await push({ pathname, query });
				else await push({ pathname: '/', query: null });
				const { id } = user ?? {};
				setApolloUserId(id);

				await refetch({ notifyOnNetworkStatusChange: true });
			} finally {
				setImpersonateLoading(false);
			}
		},
		[pathname, push, query, refetch, setApolloUserId, updateCustomerFileStorages],
	);

	const contextValue: SessionContextType | undefined = useMemo(
		() =>
			session && {
				user: session.user,
				customerFile: sessionCustomerFile,
				permissions: session.permissions,
				impersonatedUser: localStorageImpersonatedUser,
				checkPermission: (code: PermissionEnum) =>
					session.permissions.some((permission) => code === permission.code && permission.value),
				changeCustomerFile: (newCustomerFile?: CustomerFileType) => updateCustomerFileStorages(newCustomerFile),
				refetch: async () => {
					await refetch();
				},
				impersonate: async (user?: ImpersonatedUserType) => {
					setLocalStorageImpersonatedUser(user);
					await handleImpersonate();
				},
			},
		[
			session,
			sessionCustomerFile,
			localStorageImpersonatedUser,
			updateCustomerFileStorages,
			refetch,
			setLocalStorageImpersonatedUser,
			handleImpersonate,
		],
	);

	// Used to reset the impersonated user id after a refresh
	useEffect(() => {
		if (!localStorageImpersonatedUser || apolloUserId === localStorageImpersonatedUser.id || isImpersonateLoading) {
			return;
		}

		handleImpersonate(localStorageImpersonatedUser).catch((error) => {
			throw error;
		});
	}, [
		apolloUserId,
		handleImpersonate,
		isImpersonateLoading,
		localStorageImpersonatedUser,
		setLocalStorageImpersonatedUser,
		updateCustomerFileStorages,
	]);

	if (isImpersonateLoading) {
		return <SplashScreen description="Chargement de l'utilisateur..." />;
	}

	if (!contextValue || loading) {
		return <SplashScreen description="Chargement de la session..." />;
	}

	return <SessionContext.Provider value={contextValue}>{children}</SessionContext.Provider>;
};

export const useSession = (): SessionContextType => {
	const context = useContext(SessionContext);

	if (context === undefined) {
		throw new Error('SessionContext should be used within a Provider');
	}

	return context;
};
