import { QueryFunctionOptions, useQuery } from '@apollo/client';

import {
	FETCH_STORAGE_DOWNLOAD,
	FetchStorageDownloadType,
	FetchStorageDownloadVariablesType,
} from '../api/settingStorageDownload';

type UseSettingStorageDownloadProps = {
	options?: QueryFunctionOptions<FetchStorageDownloadType, FetchStorageDownloadVariablesType>;
	variables: FetchStorageDownloadVariablesType;
};

const useSettingStorageDownload = ({ options, variables }: UseSettingStorageDownloadProps) => {
	const { data, error, loading } = useQuery<FetchStorageDownloadType, FetchStorageDownloadVariablesType>(
		FETCH_STORAGE_DOWNLOAD,
		{
			...(options && options),
			variables,
		},
	);
	const { document_settingStorageDownload: settingStorageDownload } = data ?? {};

	return { error, loading, settingStorageDownload };
};

export default useSettingStorageDownload;
