import { gql } from '@apollo/client';
import { TableOrderByType } from '@elipssolution/harfang';

import { DOMAIN_FIELDS } from './domain';
import { SITE_FIELDS } from '../../modules/brochure/api/site';
import {
	CustomerFileType,
	CustomerFileWithDomainType,
	CustomerFileWithDomainTypeAndIsDashboardInherited,
	CustomerFileWithDomainTypeAndUserPermission,
	CustomerFileWithRiskAndSiteType,
} from '../../types/customerFile';
import DataSourceResultFormatType from '../../types/dataSource';
import { AccountingConnectorType } from '../types/accountingConnector';

export const CUSTOMER_FILE_FIELDS = `
	code
	id
	name
`;

export const CUSTOMER_FILE_WITH_DOMAIN_FIELDS = `
	${CUSTOMER_FILE_FIELDS}
	domain {
		${DOMAIN_FIELDS}
	}
`;

const CUSTOMER_FILE_WITH_DOMAIN_AND_IS_DASHBOARD_INHERITED_FIELDS = `
	${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
	isDashboardInherited
`;

// QUERIES

export type FetchCustomerFilesType = {
	customerFiles: {
		items: CustomerFileType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES = gql`
	query CustomerFiles($orderBy: OrderBy, $page: Page, $search: String) {
		customerFiles(orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${CUSTOMER_FILE_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchCustomerFilesByModuleCodeType = {
	customerFilesByModuleCode: {
		items: CustomerFileType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_BY_MODULE_CODE = gql`
	query CustomerFilesByModuleCode($orderBy: OrderBy, $page: Page, $search: String, $moduleCode: String!) {
		customerFilesByModuleCode(orderBy: $orderBy, page: $page, search: $search, moduleCode: $moduleCode) {
			count
			items {
				${CUSTOMER_FILE_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchAllCustomerFileIdsByDomainType = {
	customerFilesByDomainWithoutPagination: {
		id: CustomerFileType['id'];
	}[];
};

export const FETCH_ALL_CUSTOMER_FILE_IDS_BY_DOMAIN = gql`
	query CustomerFilesByDomainWithoutPagination($domainId: ID!) {
		customerFilesByDomainWithoutPagination(domainId: $domainId) {
			id
		}
	}
`;

export type FetchCustomerFilesWithDomainType = {
	customerFiles: {
		items: CustomerFileWithDomainType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_WITH_DOMAIN = gql`
	query CustomerFiles($orderBy: OrderBy, $page: Page, $search: String) {
		customerFiles(orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
				connectorCode
			}
			limit
			offset
		}
	}
`;

export type FetchArchivedCustomerFilesWithDomainType = {
	archivedCustomerFiles: {
		items: CustomerFileWithDomainType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_ARCHIVED_CUSTOMER_FILES_WITH_DOMAIN = gql`
	query ArchivedCustomerFiles($orderBy: OrderBy, $page: Page, $search: String) {
		archivedCustomerFiles(orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchCustomerFilesWithDomainAndIsDashboardInheritedType = {
	customerFilesWithIsDashboardInherited: {
		items: CustomerFileWithDomainTypeAndIsDashboardInherited[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_WITH_DOMAIN_AND_IS_DASHBOARD_INHERITED = gql`
	query customerFilesWithIsDashboardInherited($orderBy: OrderBy, $page: Page, $search: String,$filter:CustomerFileWithIsDashboardInheritedFilterInput) {
		customerFilesWithIsDashboardInherited(orderBy: $orderBy, page: $page, search: $search,filter:$filter) {
			count
			items {
				${CUSTOMER_FILE_WITH_DOMAIN_AND_IS_DASHBOARD_INHERITED_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchCustomerFilesWithoutRelationWithUser = {
	customerFilesWithoutRelationWithUser: {
		count: number;
		items: CustomerFileType[];
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_WITHOUT_RELATION_WITH_USER = gql`
	query CustomerFilesWithoutRelationWithUser($userId: ID!, $page: Page!, $search: String) {
		customerFilesWithoutRelationWithUser(userId: $userId, page: $page, search: $search) {
			count
			items {
				${CUSTOMER_FILE_FIELDS}
			}
			limit
			offset
		}
	}
`;

export type FetchCustomerFilesWithUserPermissionType = {
	customerFilesWithUserPermission: {
		count: number;
		items: CustomerFileWithDomainTypeAndUserPermission[];
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_WITH_USER_PERMISSION = gql`
	query CustomerFilesWithUserPermission($page: Page!, $orderBy: OrderBy, $search: String) {
		customerFilesWithUserPermission(page: $page, orderBy: $orderBy, search: $search) {
			items {
				${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
				hasUserPermissionToManage
			}
			count
			limit
			offset
		}
	}
`;

export type FetchCustomerFileType = {
	customerFile: CustomerFileType;
};

export const FETCH_CUSTOMER_FILE = gql`
	query CustomerFile($id: ID!) {
		customerFile(id: $id) {
			${CUSTOMER_FILE_FIELDS}
			connectorCode
			minimumEntryDate
		}
	}
`;

export type FetchCustomerFileByCodeType = {
	customerFileByCode: CustomerFileType;
};

export const FETCH_CUSTOMER_FILE_BY_CODE = gql`
	query CustomerFileByCode($code: String!) {
  		customerFileByCode(code: $code) {
			${CUSTOMER_FILE_FIELDS}
			connectorCode
			minimumEntryDate
			startDate
		}
	}
`;

export type FetchSettingsCustomerFilesResultType = {
	customerFiles: DataSourceResultFormatType<Pick<CustomerFileWithDomainType, 'id' | 'code' | 'name' | 'domain'>>;
};
export type FetchSettingsCustomerFilesVariablesType = {
	orderBy?: Pick<
		TableOrderByType<Pick<CustomerFileWithDomainType, 'id' | 'code' | 'name' | 'domain'>>,
		'field' | 'order'
	>;
	page: { limit: number; offset: number };
	search?: string;
};
export const FETCH_SETTINGS_CUSTOMER_FILES = gql`
	query CustomerFiles($orderBy: OrderBy, $page: Page!, $search: String) {
		customerFiles(orderBy: $orderBy, page: $page, search: $search) {
			count
			items {
				${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
			}
		}
	}
`;

export type FetchSettingsCustomerFileResultType = {
	customerFile: Pick<CustomerFileWithDomainType, 'id' | 'code' | 'name' | 'domain'> & {
		connector: AccountingConnectorType;
	};
};
export type FetchSettingsCustomerFileVariablesType = {
	id: CustomerFileType['id'];
};
export const FETCH_SETTINGS_CUSTOMER_FILE = gql`
	query CustomerFile($id: ID!) {
		customerFile(id: $id) {
			${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
			connector {
				code
				name
			}
		}
	}
`;

export type FetchCustomerFilesForBrochureType = {
	customerFilesForBrochure: {
		items: CustomerFileType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_FOR_BROCHURE = gql`
    query CustomerFilesForBrochure($orderBy: OrderBy, $page: Page, $search: String) {
        customerFilesForBrochure(orderBy: $orderBy, page: $page, search: $search) {
            count
            items {
                ${CUSTOMER_FILE_FIELDS}
            }
            limit
            offset
        }
    }
`;

export type FetchCustomerFilesForBrochureWithRiskAndSiteType = {
	customerFilesForBrochure: {
		items: CustomerFileWithRiskAndSiteType[];
		count: number;
		limit: number;
		offset: number;
	};
};

export const FETCH_CUSTOMER_FILES_FOR_BROCHURE_WITH_RISK_AND_SITE = gql`
    query CustomerFilesForBrochure($orderBy: OrderBy, $page: Page, $search: String) {
        customerFilesForBrochure(orderBy: $orderBy, page: $page, search: $search) {
            count
            items {
                ${CUSTOMER_FILE_FIELDS}
                risk
                site {
                    ${SITE_FIELDS}
                }
            }
            limit
            offset
        }
    }
`;

export type FetchCustomerFileInvoiceInformationsType = {
	customerFileForBrochure: CustomerFileType;
};
export type FetchCustomerFileInvoiceInformationsVariablesType = {
	id: CustomerFileType['id'];
};
export const FETCH_CUSTOMER_FILE_INVOICE_INFORMAIONS = gql`
	query CustomerFileForBrochure($id: ID!) {
		customerFileForBrochure(id: $id) {
			id
			dueCustomer
		}
	}
`;

export type FetchCustomerFileExternalContactsType = {
	customerFileForBrochure: CustomerFileType;
};
export type FetchCustomerFileExternalContactsVariablesType = {
	id: CustomerFileType['id'];
};
export const FETCH_CUSTOMER_FILE_EXTERNAL_CONTACTS = gql`
	query CustomerFileForBrochure($id: ID!) {
		customerFileForBrochure(id: $id) {
			id
			externalContacts {
				id
				email
				name
				address
				zipCode
				city
			}
		}
	}
`;

// MUTATIONS

export type CreateCustomerFileType = {
	createCustomerFile: CustomerFileWithDomainType;
};

export const CREATE_CUSTOMER_FILE = gql`
	mutation CreateCustomerFile($createCustomerFileInput: CreateCustomerFileInput!) {
		createCustomerFile(createCustomerFileInput: $createCustomerFileInput) {
			${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
		}
	}
`;

export type UpdateCustomerFileType = {
	updateCustomerFile: CustomerFileWithDomainType;
};

export const UPDATE_CUSTOMER_FILE = gql`
	mutation UpdateCustomerFile($updateCustomerFileInput: UpdateCustomerFileInput!) {
		updateCustomerFile(updateCustomerFileInput: $updateCustomerFileInput) {
			${CUSTOMER_FILE_WITH_DOMAIN_FIELDS}
		}
	}
`;
export type RemoveCustomerFileType = {
	removeCustomerFile: {
		id: CustomerFileType['id'];
	};
};

export const REMOVE_CUSTOMER_FILE = gql`
	mutation RemoveCustomerFile($removeCustomerFileId: ID!) {
		removeCustomerFile(id: $removeCustomerFileId) {
			id
		}
	}
`;

export type CheckCustomerFileCodeAvailabilityType = {
	checkCustomerFileCodeAvailability: boolean;
};

export const CHECK_CUSTOMER_FILE_CODE_AVAILABILITY = gql`
	query CheckCustomerFileCodeAvailability($code: String!) {
		checkCustomerFileCodeAvailability(code: $code)
	}
`;
