import { SettingsGroup, SettingsItemCheckbox, SettingsItemTextField } from '@elipssolution/harfang';
import Image from 'next/image';
import { useEffect } from 'react';
import { isEdgeChromium, isWindows } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { WidgetExternalApplicationType } from '../../../../types/widget';
import ApplicationCard from '../ApplicationCard';
import { WidgetExternalActionButtons } from '../WidgetExternalActionButtons';

type SilaeSpecificType = {
	instance: string;
};

type FormType = {
	name: string;
	isExternal: boolean;
	isInternal: boolean;
	isAvailableOnDesktopFormat?: boolean;
	isAvailableOnMobileFormat?: boolean;
	instance: string;
};

type SilaeFormProps = {
	application: WidgetExternalApplicationType;
	isNew: boolean;
	onSubmit: (application: WidgetExternalApplicationType) => void;
	onRemove: (application: WidgetExternalApplicationType) => void;
};

export const SilaeForm = ({ application, isNew, onSubmit, onRemove }: SilaeFormProps) => {
	const {
		key,
		type,
		name: initialName,
		specific,
		isInternal: isInternalInitial,
		isExternal: isExternalInitial,
		isAvailableOnDesktopFormat: isAvailableOnDesktopFormatInitial,
		isAvailableOnMobileFormat: isAvailableOnMobileFormatInitial,
	} = application;
	const jsonSpecific = specific ? (JSON.parse(specific) as SilaeSpecificType) : undefined;
	const { instance: initialInstance } = jsonSpecific ?? {};

	const {
		control,
		formState: { isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>();

	const handleRemove = () => onRemove(application);

	const handleSubmitInternal = ({
		name,
		instance,
		isInternal,
		isExternal,
		isAvailableOnDesktopFormat,
		isAvailableOnMobileFormat,
	}: FormType) => {
		const stringSpecific = JSON.stringify({ instance });

		onSubmit({
			key: key ?? uuid(),
			type,
			name,
			isInternal,
			isExternal,
			isAvailableOnDesktopFormat,
			isAvailableOnMobileFormat,
			specific: stringSpecific,
		});
	};

	useEffect(
		() =>
			reset({
				name: initialName,
				isInternal: isInternalInitial,
				isExternal: isExternalInitial,
				isAvailableOnDesktopFormat: isAvailableOnDesktopFormatInitial,
				isAvailableOnMobileFormat: isAvailableOnMobileFormatInitial,
				instance: initialInstance,
			}),
		[
			initialInstance,
			initialName,
			isAvailableOnDesktopFormatInitial,
			isAvailableOnMobileFormatInitial,
			isExternalInitial,
			isInternalInitial,
			reset,
		],
	);

	return (
		<>
			<SettingsGroup label="Configuration">
				<Controller
					name="name"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField {...field} description="Nom de l'application." label="Nom" required />
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="instance"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField
							{...field}
							description="Instance de l'application fournie par Silae, correspond au début de l'URL instance.silae.fr."
							label="Instance"
							required
						/>
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="isInternal"
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs cabinet auront accès à cette application."
							label="Visible cabinet"
						/>
					)}
				/>
				<Controller
					name="isExternal"
					control={control}
					defaultValue={false}
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs entreprise auront accès à cette application."
							label="Visible entreprise"
						/>
					)}
				/>

				<Controller
					defaultValue={false}
					name="isAvailableOnDesktopFormat"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, l'application externe sera visible sur ordinateur."
							label="Visible version ordinateur"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
				<Controller
					defaultValue={false}
					name="isAvailableOnMobileFormat"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, l'application externe sera visible sur mobile."
							label="Visible version mobile"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<WidgetExternalActionButtons
				isValid={isValid}
				isNew={isNew}
				onRemove={handleRemove}
				onSubmit={handleSubmit(handleSubmitInternal)}
			/>
		</>
	);
};

type SilaeApplicationProps = {
	name: string;
	specific?: string;
	readOnly: boolean;
};

const SilaeApplication = ({ name, specific, readOnly }: SilaeApplicationProps) => {
	const jsonSpecific = specific ? (JSON.parse(specific) as SilaeSpecificType) : undefined;
	const { instance = '' } = jsonSpecific ?? {};

	const isSalaeAvailable = isEdgeChromium && isWindows;

	const handleClick = () => window.open(`https://${instance}.silae.fr/debut.aspx`, '_blank');

	return (
		<ApplicationCard
			name={name}
			image={<Image src="/widget/images/silae-logo.png" alt="Logo Silae" width={96} height={96} objectFit="contain" />}
			disabled={!isSalaeAvailable}
			disabledMessage="Vous ne pouvez accéder à Silae qu'avec un ordinateur Windows et un navigateur Microsoft Edge Chromium."
			onClick={handleClick}
			readOnly={readOnly}
		/>
	);
};

export default SilaeApplication;
