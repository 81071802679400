import { SettingsGroup, SettingsItemCheckbox, SettingsItemImage, SettingsItemTextField } from '@elipssolution/harfang';
import { Typography, styled } from '@mui/material';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import { WidgetExternalApplicationType } from '../../../../types/widget';
import { useWidgetImages } from '../../../settings/widgets/provider/WidgetImagesProvider';
import ApplicationCard from '../ApplicationCard';
import { WidgetExternalActionButtons } from '../WidgetExternalActionButtons';

const StyledImagePlaceholder = styled(Typography)(({ theme }) => ({
	flex: 1,
	borderWidth: 2,
	borderStyle: 'solid',
	borderColor: theme.palette.primary.main,
	borderRadius: 16,
	color: theme.palette.primary.main,
}));

type GenericSpecificType = {
	name: string;
	url: string;
	image?: string;
};

type FormType = {
	name: string;
	url: string;
	isExternal: boolean;
	isInternal: boolean;
	isAvailableOnDesktopFormat?: boolean;
	isAvailableOnMobileFormat?: boolean;
};

type GenericFormProps = {
	application: WidgetExternalApplicationType;
	isNew: boolean;
	onSubmit: (application: WidgetExternalApplicationType) => void;
	onRemove: (application: WidgetExternalApplicationType) => void;
};

export const GenericForm = ({ application, isNew, onSubmit, onRemove }: GenericFormProps) => {
	const { addImages, getBlob, removeImages } = useWidgetImages();

	const {
		key,
		name: initialName,
		specific,
		isInternal: isInternalInitial,
		isExternal: isExternalInitial,
		isAvailableOnDesktopFormat: isAvailableOnDesktopFormatInitial,
		isAvailableOnMobileFormat: isAvailableOnMobileFormatInitial,
	} = application;
	const jsonSpecific = specific ? (JSON.parse(specific) as GenericSpecificType) : undefined;
	const { url: initialUrl, image: initialImageFileName } = jsonSpecific ?? {};

	const {
		control,
		formState: { isValid },
		handleSubmit,
		reset,
	} = useForm<FormType>({
		mode: 'onBlur',
	});

	const [imageFileName, setImageFileName] = useState<string>();
	const [blob, setBlob] = useState<Blob>();

	const handleImageChange = (file: File) => {
		setBlob(file);
		const [extension] = file.name.split('.').slice(-1);
		const fileName = `${application.key}.${extension}`;
		setImageFileName(fileName);
	};

	const handleRemove = () => {
		imageFileName && removeImages([imageFileName]);

		onRemove(application);
	};

	const handleSubmitInternal = ({
		name,
		url,
		isInternal,
		isExternal,
		isAvailableOnDesktopFormat,
		isAvailableOnMobileFormat,
	}: FormType) => {
		const stringSpecific = JSON.stringify({ url, image: imageFileName });

		if (imageFileName && blob) {
			addImages([{ fileName: imageFileName, blob }]);
		}

		onSubmit({
			key: key ?? uuid(),
			name,
			isInternal,
			isExternal,
			isAvailableOnDesktopFormat,
			isAvailableOnMobileFormat,
			specific: stringSpecific,
		});
	};

	useEffect(() => {
		if (!initialImageFileName) return;

		(async () => {
			setBlob(await getBlob(initialImageFileName));
			setImageFileName(initialImageFileName);
		})().catch((e) => {
			throw e;
		});
	}, [getBlob, initialImageFileName]);

	useEffect(
		() =>
			reset({
				name: initialName,
				url: initialUrl,
				isInternal: isInternalInitial,
				isExternal: isExternalInitial,
				isAvailableOnDesktopFormat: isAvailableOnDesktopFormatInitial,
				isAvailableOnMobileFormat: isAvailableOnMobileFormatInitial,
			}),
		[
			initialName,
			initialUrl,
			isAvailableOnDesktopFormatInitial,
			isAvailableOnMobileFormatInitial,
			isExternalInitial,
			isInternalInitial,
			reset,
		],
	);

	return (
		<>
			<SettingsGroup label="Configuration">
				<Controller
					name="name"
					control={control}
					render={({ field }) => (
						<SettingsItemTextField {...field} description="Nom de l'application." label="Nom" required />
					)}
					rules={{ required: true }}
				/>
				<Controller
					name="url"
					control={control}
					render={({ field, fieldState: { error } }) => (
						<SettingsItemTextField
							{...field}
							description="URL de l'application."
							helperText={error?.message ?? ' '}
							invalid={!!error}
							label="URL"
							required
						/>
					)}
					rules={{
						required: true,
						pattern: {
							// Regex explanation
							// ^ : start of string
							// (?:https?:\/\/)? : optional https:// or http://
							// (?:[\w-]+\.?)+ : website domain
							// (?::\d{1,5})? : optional port number simplified to a 1 to 5 digit check (port number should be between 0 and 65535 but the regex would be way longer)
							// (\/?([\w-]+) : optional ending / or subroutes
							// (\?(([\w]+)=(.+)&?)+)?)* : optional parameters
							// $ : end of string
							value: /^(?:https?:\/\/)?(?:[\w-]+\.?)+(?::\d{1,5})?(\/?([\w-]+)(\?(([\w]+)=(.+)&?)+)?)*$/,
							message: 'URL invalide',
						},
					}}
				/>
				<SettingsItemImage
					description="Logo de l'application. La résolution optimale est 86x86."
					image={blob && imageFileName ? new File([blob], imageFileName) : undefined}
					label="Logo"
					onChange={handleImageChange}
					previewHeight={86}
					previewWidth={86}
				/>
				<Controller
					name="isInternal"
					control={control}
					defaultValue
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs cabinet auront accès à cette application."
							label="Visible cabinet"
						/>
					)}
				/>
				<Controller
					name="isExternal"
					control={control}
					defaultValue
					render={({ field: { value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, les utilisateurs entreprise auront accès à cette application."
							label="Visible entreprise"
						/>
					)}
				/>

				<Controller
					defaultValue={false}
					name="isAvailableOnDesktopFormat"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, l'application externe sera visible sur ordinateur."
							label="Visible version ordinateur"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
				<Controller
					defaultValue={false}
					name="isAvailableOnMobileFormat"
					control={control}
					render={({ field: { onChange, value, ...field } }) => (
						<SettingsItemCheckbox
							{...field}
							checked={value}
							description="Si coché, l'application externe sera visible sur mobile."
							label="Visible version mobile"
							onChange={(_, checked: boolean) => onChange(checked)}
						/>
					)}
				/>
			</SettingsGroup>

			<WidgetExternalActionButtons
				isValid={isValid}
				isNew={isNew}
				onRemove={handleRemove}
				onSubmit={handleSubmit(handleSubmitInternal)}
			/>
		</>
	);
};

type GenericApplicationProps = {
	name: string;
	specific?: string;
	readOnly: boolean;
};

const GenericApplication = ({ name, specific, readOnly }: GenericApplicationProps) => {
	const { getBlob } = useWidgetImages();

	const jsonSpecific = specific ? (JSON.parse(specific) as GenericSpecificType) : undefined;
	const { url, image: imageFileName } = jsonSpecific || {};

	const [imageObjectUrl, setImageObjectUrl] = useState<string>();

	const handleClick = () => {
		if (!url) return;

		window.open(/^https?:\/\//.test(url) ? url : `http://${url}`, '_blank');
	};

	useEffect(() => {
		if (!imageFileName || imageObjectUrl) return undefined;

		(async () => {
			const blob = await getBlob(imageFileName);
			const objectUrl = URL.createObjectURL(blob);
			setImageObjectUrl(objectUrl);
		})().catch((e) => {
			throw e;
		});

		return () => {
			imageObjectUrl && URL.revokeObjectURL(imageObjectUrl);
		};
	}, [getBlob, imageFileName, imageObjectUrl]);

	return (
		<ApplicationCard
			name={name}
			image={
				imageObjectUrl ? (
					<Image src={imageObjectUrl} alt={`Logo ${name}`} width={96} height={96} objectFit="contain" />
				) : (
					<StyledImagePlaceholder variant="h3" display="flex" alignItems="center" justifyContent="center">
						{name.charAt(0)}
					</StyledImagePlaceholder>
				)
			}
			readOnly={readOnly}
			onClick={handleClick}
		/>
	);
};

export default GenericApplication;
