import { useLazyQuery } from '@apollo/client';
import {
	Autocomplete,
	Button,
	Dialog,
	DialogProps,
	IconButton,
	TableOrderByType,
	Tooltip,
	Icon as HarfangIcon,
} from '@elipssolution/harfang';
import { mdiCheckCircle, mdiClose, mdiInformation, mdiUpload } from '@mdi/js';
import Icon from '@mdi/react';
import { Checkbox, Stack, Typography, styled } from '@mui/material';
import clsx from 'clsx';
import { useSession as useNextAuthSession } from 'next-auth/react';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useSession } from '../../../src/components/SessionProvider';
import { formatFileSize, uploadFilesWithFormData } from '../../../src/utils/file';
import { FETCH_ENABLED_TAGS, FetchEnabledTagsType } from '../api/tag';
import { TagType } from '../types/tag';

// UPLOAD BUTTON

type DocumentUploadButtonProps = {
	disabled?: boolean;
	maxFiles?: number;
	maxFileSize?: number;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	uploadedFilesLength?: number;
};

const DocumentUploadButton = ({
	disabled,
	maxFiles,
	maxFileSize,
	onChange,
	uploadedFilesLength,
}: DocumentUploadButtonProps) => {
	const [error, setError] = useState<string>();

	const inputRef = useRef<HTMLInputElement>(null);

	const maxFilesUpload = (maxFiles ?? 0) - (uploadedFilesLength ?? 0);

	const triggerInput = useCallback(() => inputRef.current?.click(), []);

	const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { files },
		} = event;

		if (!files) return;

		if (maxFileSize && Array.from(files).some(({ size }) => size > maxFileSize * 2000000)) {
			setError('Fichier trop lourd (20 Mo max.)');
		} else if (maxFiles && (uploadedFilesLength ?? 0) + files.length > maxFiles) {
			setError(`Trop de fichiers déposés (${maxFilesUpload} max.)`);
		} else {
			!!error && setError(undefined);

			onChange?.(event);
		}
	};

	return (
		<Stack>
			<Button
				disabled={disabled || maxFilesUpload === 0}
				onClick={triggerInput}
				startIcon={<HarfangIcon path={mdiUpload} />}
			>
				Ajouter des documents
			</Button>
			<input onChange={handleUpload} ref={inputRef} type="file" hidden multiple />
			{error && (
				<Typography color="error" variant="caption">
					{error}
				</Typography>
			)}
		</Stack>
	);
};

// TAG SELECT
type TagAutocompleteProps = {
	dataSource: (
		limit: number,
		offset: number,
		search?: string,
		orderBy?: TableOrderByType<TagType>,
	) => Promise<{
		count: number;
		items: TagType[];
	}>;
	disabled?: boolean;
	onChange: (value?: TagType) => void;
	value?: TagType;
};

const TagAutocomplete = ({ dataSource, disabled, onChange, value }: TagAutocompleteProps) => {
	const optionsRenderer = (option: TagType, displayedValue: JSX.Element) => (
		<Stack flexDirection="row" justifyContent="space-between" alignItems="center" width="100%">
			{displayedValue}
			{option.info && (
				<Tooltip content={option.info}>
					<Stack alignItems="center">
						<HarfangIcon path={mdiInformation} />
					</Stack>
				</Tooltip>
			)}
		</Stack>
	);

	return (
		<Autocomplete<TagType>
			dataSource={dataSource}
			disabled={disabled}
			getOptionLabel={({ name }) => name}
			renderOptions={optionsRenderer}
			onChange={onChange}
			optionWidth={300}
			placeholder="Tag"
			value={value}
			disableClearable
		/>
	);
};

// DOCUMENT ITEM

type DocumentType = {
	file: File;
	tag?: TagType;
};

const DocumentItemWrapper = styled('div')(({ theme: { palette, spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: spacing(2),

	padding: spacing(0, 1.5),

	'&.error': {
		backgroundColor: `${palette.error.main}1A`,
	},

	'& > div': {
		'&:first-of-type': {
			display: 'flex',
			alignItems: 'center',
		},

		'&:last-of-type': {
			width: 200,

			display: 'flex',
			alignItems: 'center',
			gap: spacing(1),

			'& > div': {
				flex: 1,
			},
		},
	},
}));

type DocumentItemProps = {
	document: DocumentType;
	isSelected: boolean;
	onDelete: (file: File) => void;
	onSelection: (file: File, selected: boolean) => void;
	onTagChange: (document: DocumentType) => void;
	tagsDataSource: (
		limit: number,
		offset: number,
		search?: string,
		orderBy?: TableOrderByType<TagType>,
	) => Promise<{
		count: number;
		items: TagType[];
	}>;
	withSelection: boolean;
};

const DocumentItem = ({
	document: { file, tag },
	isSelected,
	onDelete,
	onSelection,
	onTagChange,
	tagsDataSource,
	withSelection,
}: DocumentItemProps) => {
	const { name, size } = file;

	const handleSelection = useCallback(() => onSelection(file, !isSelected), [onSelection, file, isSelected]);
	const handleTagChange = useCallback(
		(value?: TagType) =>
			onTagChange({
				file,
				tag: value,
			}),
		[onTagChange, file],
	);
	const handleDeleteButtonClick = useCallback(() => onDelete(file), [onDelete, file]);

	return (
		<DocumentItemWrapper>
			<div>
				{withSelection && <Checkbox onChange={handleSelection} checked={isSelected} />}
				<Stack>
					<Typography>{name}</Typography>
					<Typography variant="caption">{formatFileSize(size)}</Typography>
				</Stack>
			</div>
			<div>
				<TagAutocomplete dataSource={tagsDataSource} onChange={handleTagChange} value={tag} />
				<IconButton onClick={handleDeleteButtonClick} size="small">
					<Icon path={mdiClose} size="18px" />
				</IconButton>
			</div>
		</DocumentItemWrapper>
	);
};

// DOCUMENT DIALOG

const StyledDialog = styled(({ className, ...props }: DialogProps) => (
	<Dialog {...props} classes={{ paper: className }} />
))(({ theme: { spacing } }) => ({
	'& > div:first-of-type': {
		display: 'flex',
		justifyContent: 'center',
		padding: 0,

		'.error': {
			padding: spacing(1),
		},
	},
}));

const DialogContent = styled('div')(({ theme: { spacing } }) => ({
	flex: 1,

	display: 'flex',
	flexDirection: 'column',

	overflow: 'hidden',

	maxHeight: '100%',

	'&.hasList': {
		marginLeft: `-${spacing(1)} !important`,
	},
}));

const GlobalDocumentSelector = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	alignItems: 'center',

	padding: spacing(0, 1.5),
}));

const DocumentList = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: spacing(0.5),

	height: '100%',

	paddingTop: spacing(1),

	overflowY: 'auto',
}));

const GlobalDocumentTagSelector = styled('div')(({ theme: { spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: spacing(2),

	padding: spacing(0, 1.5),

	marginTop: spacing(2),
	marginLeft: spacing(1),

	'& > div': {
		width: 200,
	},
}));

const SuccessWrapper = styled('div')(({ theme: { palette, shape, spacing } }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: spacing(1),

	color: palette.success.main,

	backgroundColor: `${palette.success.main}1A`,
	backdropFilter: 'blur(30px)',
	borderRadius: shape.borderRadius * 2,

	position: 'absolute',
	inset: spacing(0, 1.5),

	pointerEvents: 'none',

	'& > div': {
		display: 'flex',
		alignItems: 'center',
		gap: spacing(1),
	},
}));

const convertFilesToDocuments = (files: File[]): DocumentType[] =>
	files.map((file) => ({
		file: new File([file], file.name.replace(/[\\/:*?"<>|]/g, '_'), { type: file.type }),
	}));

type DocumentUploadDialogProps = {
	maxFiles?: number;
	maxFileSize?: number;
	onClose: () => void;
	onUploadSuccess?: () => void;
	open: boolean;
	uploadedFiles: File[] | null;
};

const DocumentUploadDialog = ({
	maxFiles,
	maxFileSize,
	onClose,
	onUploadSuccess,
	open,
	uploadedFiles,
}: DocumentUploadDialogProps) => {
	const { data: sessionData } = useNextAuthSession();
	const { access_token } = sessionData ?? {};
	const { customerFile: sessionCustomerFile } = useSession();

	const [documents, setDocuments] = useState<DocumentType[]>([]);
	const [selectedDocuments, setSelectedDocuments] = useState<DocumentType[]>([]);
	const [globalTag, setGlobalTag] = useState<TagType>();
	const [error, setError] = useState<string>();
	const [isUploading, setIsUploading] = useState(false);
	const [hasSucceeded, setHasSucceeded] = useState(false);

	const isAllDocumentsSelected = documents?.length === selectedDocuments?.length;
	const isValid = documents.length > 0 && documents.every(({ tag }) => !!tag);

	const accessTokenRef = useRef(access_token);

	const hasWronglyTypedFile = useMemo(() => documents?.some(({ file: { type } }) => !type), [documents]);

	const [fetchEnabledTags] = useLazyQuery<FetchEnabledTagsType>(FETCH_ENABLED_TAGS);

	const tagsDataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<TagType>,
		): Promise<{
			count: number;
			items: TagType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error: tagsError } = await fetchEnabledTags({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (tagsError) {
				throw tagsError;
			}

			const {
				document_enabledTags: { count = 0, items = [] },
			} = data ?? {
				document_enabledTags: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchEnabledTags],
	);

	const handleClose = useCallback(() => {
		setDocuments([]);
		setSelectedDocuments([]);
		setGlobalTag(undefined);
		setError(undefined);
		setIsUploading(false);
		setHasSucceeded(false);

		onClose();
	}, [onClose]);

	const addFiles = useCallback(
		({ target: { files: targetFiles } }: ChangeEvent<HTMLInputElement>) =>
			setDocuments((prevFiles) => {
				if (!targetFiles) return prevFiles;

				const uploadedDocuments = convertFilesToDocuments(Array.from(targetFiles));

				if (!prevFiles) return uploadedDocuments;

				return [
					...prevFiles,
					...uploadedDocuments.filter(
						({ file: uploadedFile }) => !prevFiles?.some(({ file: prevFile }) => prevFile.name === uploadedFile.name),
					),
				];
			}),
		[],
	);

	const removeFile = (file: File) => {
		setDocuments((prevFiles) => prevFiles.filter(({ file: prevFile }) => prevFile !== file));
		setSelectedDocuments((prevSelectedDocuments) =>
			prevSelectedDocuments.filter(({ file: prevFile }) => prevFile !== file),
		);
	};

	const handleDocumentSelection = useCallback(
		(file: File, selected: boolean) =>
			setSelectedDocuments((prevSelectedDocuments) => {
				const document = documents?.find(({ file: prevFile }) => prevFile === file);

				if (!document) return prevSelectedDocuments;

				return document && selected
					? [...prevSelectedDocuments, document]
					: prevSelectedDocuments.filter(({ file: prevFile }) => prevFile !== file);
			}),
		[documents],
	);

	const handleDocumentTagChange = ({ file, tag }: DocumentType) =>
		setDocuments((prevDocuments) =>
			prevDocuments.map((document) => (document.file === file ? { ...document, tag } : document)),
		);

	const handleGlobalSelection = useCallback(
		() => (isAllDocumentsSelected ? setSelectedDocuments([]) : setSelectedDocuments(documents)),
		[isAllDocumentsSelected, documents],
	);

	const applyGlobalTag = useCallback(() => {
		// Apply the globalTag to the selectedDocuments
		setDocuments((prevDocuments) =>
			prevDocuments.map((document) =>
				selectedDocuments.find(({ file }) => file === document.file)
					? { ...document, tag: globalTag ?? undefined }
					: document,
			),
		);

		setSelectedDocuments([]);

		setGlobalTag(undefined);
	}, [globalTag, selectedDocuments]);

	const sendDocuments = useCallback(async () => {
		if (!accessTokenRef.current || !sessionCustomerFile?.id) return;

		setIsUploading(true);

		const documentsPerStorageUploadMapper = documents.reduce((acc, document) => {
			const { tag } = document;
			const { storageUpload } = tag ?? {};
			const { id: storageUploadId } = storageUpload ?? {};

			if (!storageUploadId) return acc;

			acc.set(storageUploadId, [...(acc.get(storageUploadId) ?? []), document]);

			return acc;
		}, new Map<string, DocumentType[]>());

		const documentsToUpload = Array.from(documentsPerStorageUploadMapper.values()).flat();

		const { filesToUpload, tagIds } = documentsToUpload.reduce<{
			filesToUpload: File[];
			tagIds: TagType['id'][];
		}>(
			(acc, { file, tag }) => {
				acc.filesToUpload.push(file);
				if (tag?.id) acc.tagIds.push(tag.id);
				return acc;
			},
			{ filesToUpload: [], tagIds: [] },
		);

		const uri = new URL(`/document-uploads`, window.location.href);
		uri.searchParams.append('tagIds', JSON.stringify(tagIds));

		await uploadFilesWithFormData({
			accessToken: accessTokenRef.current,
			selectedCustomerFileId: sessionCustomerFile.id,
			files: filesToUpload,
			uri,
		});

		setHasSucceeded(true);
		setIsUploading(false);

		onUploadSuccess?.();
	}, [documents, onUploadSuccess, sessionCustomerFile?.id]);

	const actionsDialog = useMemo(
		(): DialogProps['actionsDialog'] =>
			hasSucceeded
				? [
						{
							label: 'Fermer',
							onClick: handleClose,
						},
				  ]
				: [
						{
							label: 'Annuler',
							onClick: handleClose,
						},
						{
							disabled: !isValid,
							label: 'Déposer',
							onClick: sendDocuments,
							variant: 'contained',
						},
				  ],
		[sendDocuments, handleClose, hasSucceeded, isValid],
	);

	const extraInfos = useMemo(
		(): DialogProps['extraInfos'] =>
			!(hasSucceeded || error)
				? [
						<DocumentUploadButton
							key="document-upload-button"
							disabled={isUploading}
							maxFiles={maxFiles}
							maxFileSize={maxFileSize}
							onChange={addFiles}
							uploadedFilesLength={documents?.length}
						/>,
				  ]
				: [],
		[addFiles, documents, error, hasSucceeded, isUploading, maxFiles, maxFileSize],
	);

	useEffect(() => {
		uploadedFiles && setDocuments(convertFilesToDocuments(Array.from(uploadedFiles)));
	}, [uploadedFiles]);

	useEffect(() => {
		if (accessTokenRef.current !== access_token) {
			accessTokenRef.current = access_token;
		}
	}, [access_token]);

	return (
		<StyledDialog
			actionsDialog={actionsDialog}
			className={clsx({ error })}
			error={error}
			extraInfos={extraInfos}
			onClose={handleClose}
			open={open}
			title="Déposer des documents"
		>
			<DialogContent className={clsx({ hasList: documents.length > 1 })}>
				{documents && (
					<>
						{documents.length > 1 && (
							<GlobalDocumentSelector>
								<Checkbox
									checked={isAllDocumentsSelected}
									indeterminate={selectedDocuments.length > 0 && !isAllDocumentsSelected}
									onChange={handleGlobalSelection}
								/>
								<Typography fontWeight={500}>Tout sélectionner</Typography>
							</GlobalDocumentSelector>
						)}

						<DocumentList>
							{documents.map((document) => {
								const { file } = document;

								return (
									<DocumentItem
										document={document}
										isSelected={selectedDocuments?.includes(document)}
										key={file.name}
										onDelete={removeFile}
										onSelection={handleDocumentSelection}
										onTagChange={handleDocumentTagChange}
										tagsDataSource={tagsDataSource}
										withSelection={documents.length > 1}
									/>
								);
							})}
						</DocumentList>

						{documents.length > 1 && (
							<GlobalDocumentTagSelector>
								<TagAutocomplete onChange={setGlobalTag} dataSource={tagsDataSource} value={globalTag} />
								<Button
									disabled={selectedDocuments.length === 0 || hasWronglyTypedFile || !globalTag}
									onClick={applyGlobalTag}
									variant="contained"
								>
									Appliquer à la sélection
								</Button>
							</GlobalDocumentTagSelector>
						)}
					</>
				)}

				{hasSucceeded && (
					<SuccessWrapper>
						<Icon path={mdiCheckCircle} size="32px" />
						<Typography variant="h5">Déposé</Typography>
					</SuccessWrapper>
				)}
			</DialogContent>
		</StyledDialog>
	);
};

export default DocumentUploadDialog;
