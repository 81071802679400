import { SettingsItemNumericField, SettingsItemTextField } from '@elipssolution/harfang';
import { forwardRef, useCallback, useMemo } from 'react';

import SettingsItemDragTextField from './SettingsItemDragTextField';
import { ConnectorFolderVariablesType, ConnectorType } from '../types/connector';

type ConnectorConfigurationFormProps = {
	value?: Record<string, string | number | undefined> | null;
	fields?: ConnectorType['folders' | 'parameters'] | null;
	onChange: (configuration: Record<string, string | number | undefined> | null) => void;
};

const isTextFieldType = (type: string): type is 'text' | 'password' =>
	type ? ['text', 'password'].includes(type) : false;

const ConnectorFieldsForm = forwardRef<HTMLDivElement, ConnectorConfigurationFormProps>(
	({ value, fields, onChange }, ref) => {
		const parsedFields = useMemo(() => value ?? {}, [value]);

		const handleChange = useCallback(
			(code: string, fieldValue?: string | number) => {
				const newValues = {
					...parsedFields,
					[code]: fieldValue,
				};

				onChange(newValues);
			},
			[onChange, parsedFields],
		);

		type RenderFieldType = {
			code: string;
			isRequired: boolean;
			name: string;
			type?: string;
			variables?: ConnectorFolderVariablesType[];
		};

		const renderField = useCallback(
			({ code, isRequired, name, type, variables }: RenderFieldType) => {
				if (variables) {
					return (
						<SettingsItemDragTextField
							ref={ref}
							chips={variables.map(({ code: variableCode, name: variableName, color }) => ({
								id: variableCode,
								color: color !== null ? color : undefined,
								label: variableName,
							}))}
							key={code}
							value={(parsedFields[code] as string) ?? ''}
							label={name}
							onChange={(fieldValue) => handleChange(code, fieldValue)}
							required={isRequired}
						/>
					);
				}
				if (type) {
					const fieldType = type === 'string' ? 'text' : type;
					if (isTextFieldType(fieldType))
						return (
							<SettingsItemTextField
								ref={ref}
								key={code}
								label={name}
								required={isRequired}
								type={fieldType}
								value={(parsedFields[code] as string) ?? ''}
								onChange={(fieldValue) => handleChange(code, fieldValue)}
							/>
						);

					if (fieldType === 'number')
						return (
							<SettingsItemNumericField
								ref={ref}
								key={code}
								label={name}
								onChange={(fieldValue) => handleChange(code, fieldValue)}
								required={isRequired}
								value={+(parsedFields[code] as number)}
							/>
						);
				}
				return null;
			},
			[handleChange, parsedFields, ref],
		);

		return fields ? <>{fields.map((parameter) => renderField({ ...parameter }))}</> : null;
	},
);
ConnectorFieldsForm.displayName = 'ConnectorFieldsForm';

export default ConnectorFieldsForm;
