import { useLazyQuery } from '@apollo/client';
import { Chip, TableColumnType, TableOrderByType } from '@elipssolution/harfang';
import { mdiCheck, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';

import InheritChip from '../../../../../../components/InheritChip';
import LinkIcon from '../../../../../../components/LinkIcon';
import SettingsTable from '../../../../../../components/SettingsTable';
import { CustomerFileWithDomainType } from '../../../../../../types/customerFile';
import {
	FETCH_SETTING_TAGS_BY_CUSTOMER_FILE,
	FetchSettingTagsByCustomerFileType,
} from '../../../../api/settingCustomerFileTag';
import { SettingTagByCustomerFileType } from '../../../../types/settingTag';

type CustomerFileTagsTableProps = {
	customerFile?: CustomerFileWithDomainType;
	onTagSelection: (tagSelected?: SettingTagByCustomerFileType) => void;
};

const CustomerFileTagsTable = ({ customerFile, onTagSelection }: CustomerFileTagsTableProps) => {
	const { palette } = useTheme();

	const { id: customerFileId } = customerFile || {};

	const columns: TableColumnType<SettingTagByCustomerFileType>[] = useMemo(
		() => [
			{
				key: 'level',
				render: ({ isInherited, isOverload }) => (
					<InheritChip parentLabel="Domaine" childLabel="Dossier" inherited={isInherited} override={isOverload} />
				),
				title: 'Niveau',
				width: 160,
				flexGrow: 0,
			},
			{
				key: 'name',
				field: 'name',
				title: 'Nom',
				width: 150,
			},
			{
				key: 'color',
				render: ({ color }) => <Chip color={color} label={color} style={{ width: '100%' }} />,
				title: 'Couleur',
				width: 100,
				flexGrow: 0,
			},
			{
				key: 'storageUploadName',
				render: ({ storageUpload }) => storageUpload && <Chip color="default" label={storageUpload?.name} />,
				title: 'Emplacement',
				width: 150,
			},
			{
				align: 'center',
				key: 'isEnabled',
				render: ({ isEnabled }) => (
					<Icon
						color={isEnabled ? palette.success.main : palette.error.main}
						path={isEnabled ? mdiCheck : mdiClose}
						size="24px"
					/>
				),
				title: 'Actif',
				width: 55,
				flexGrow: 0,
			},
			{
				key: 'actions',
				flexGrow: 0,
				render: () => <LinkIcon />,
				width: 40,
			},
		],
		[palette],
	);

	const [fetchSettingTagsByCustomerFile] = useLazyQuery<FetchSettingTagsByCustomerFileType>(
		FETCH_SETTING_TAGS_BY_CUSTOMER_FILE,
		{
			variables: { customerFileId },
		},
	);

	const dataSource = useCallback(
		async (
			limit: number,
			offset: number,
			search?: string,
			orderBy?: TableOrderByType<SettingTagByCustomerFileType>,
		): Promise<{
			count: number;
			items: SettingTagByCustomerFileType[];
		}> => {
			const { field, order } = orderBy || {};

			const { data, error } = await fetchSettingTagsByCustomerFile({
				variables: {
					...(orderBy && { orderBy: { field, order } }),
					page: {
						limit,
						offset,
					},
					search,
				},
			});

			if (error) {
				throw error;
			}

			const {
				document_settingTagsByCustomerFile: { count = 0, items = [] },
			} = data ?? {
				document_settingTagsByCustomerFile: {},
			};

			return {
				count,
				items,
			};
		},
		[fetchSettingTagsByCustomerFile],
	);

	const handleRowClick = useCallback((tag: SettingTagByCustomerFileType) => onTagSelection(tag), [onTagSelection]);

	const handleAddButtonClick = useCallback(() => onTagSelection(), [onTagSelection]);

	return (
		<SettingsTable<SettingTagByCustomerFileType>
			addButtonLabel="Ajouter un tag"
			dataSource={dataSource}
			height="100%"
			onAddButtonClick={handleAddButtonClick}
			onRowClick={handleRowClick}
			tableColumns={columns}
			title="Tags"
		/>
	);
};

export default CustomerFileTagsTable;
