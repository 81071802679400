import StorageDownloadConnectorsTable from './ConnectorsTable';
import SettingsDialogPage from '../../../../components/SettingsDialogPage';

const StorageDownload = () => (
	<SettingsDialogPage title="Consultation GED">
		<StorageDownloadConnectorsTable />
	</SettingsDialogPage>
);

export default StorageDownload;
