import { SettingsItemAutocomplete } from '@elipssolution/harfang';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import SettingsDialogPage from '../../../../components/SettingsDialogPage';
import { WidgetExternalApplicationType } from '../../../../types/widget';
import { SimulatorType } from '../../../utils/simulators';
import SimulatorInstanceForm from '../simulator/SimulatorInstanceForm';

type SimulatorFormProps = {
	availableSimulators: SimulatorType[];
	simulator?: WidgetExternalApplicationType;
	onSubmit: (simulator: WidgetExternalApplicationType) => void;
	onRemove: (simulator: WidgetExternalApplicationType) => void;
	onBack: () => void;
};

const SimulatorForm = ({
	simulator: initialSimulator,
	availableSimulators,
	onSubmit,
	onRemove,
	onBack,
}: SimulatorFormProps) => {
	const defaultSimulatorForm = availableSimulators?.[0];
	const [simulator, setSimulator] = useState<WidgetExternalApplicationType>(
		initialSimulator ?? {
			key: uuid(),
			name: defaultSimulatorForm?.name,
			type: defaultSimulatorForm?.type,
		},
	);

	const selectedApplicationForm =
		availableSimulators.find((applicationForm) => applicationForm.type === simulator.type) ?? defaultSimulatorForm;

	const handleChangeType = (type?: { type?: string; name: string }) =>
		setSimulator({
			key: uuid(),
			name: type?.name ?? defaultSimulatorForm.name,
			type: type?.type ?? defaultSimulatorForm.type,
		});

	const dataSource = useCallback(
		async (): Promise<{
			items: { key?: string; name: string }[];
			count: number;
		}> =>
			Promise.resolve({
				items: availableSimulators,
				count: availableSimulators.length,
			}),
		[availableSimulators],
	);

	return (
		<SettingsDialogPage title={simulator.name} onBack={onBack}>
			<SettingsItemAutocomplete<{ type?: string; name: string }>
				dataSource={dataSource}
				dataKey="type"
				description="Liste des simulateurs disponibles."
				getOptionLabel={({ name }) => name}
				label="Type de simulateur"
				onChange={handleChangeType}
				value={selectedApplicationForm}
				disableClearable
			/>

			<SimulatorInstanceForm simulator={simulator} isNew={!initialSimulator} onSubmit={onSubmit} onRemove={onRemove} />
		</SettingsDialogPage>
	);
};

export default SimulatorForm;
